import * as tslib_1 from "tslib";
import { DynamicDatePickerModel } from "@ng-dynamic-forms/core";
var DatepickerCustomModel = /** @class */ (function (_super) {
    tslib_1.__extends(DatepickerCustomModel, _super);
    function DatepickerCustomModel(config, layout) {
        var _this = _super.call(this, config, layout) || this;
        _this.type = 'customDatePickerV1';
        _this.hiddenClearIco = false;
        _this.hiddenDatePicker = false;
        //config.min = new Date(2020, 5, 5);
        if (config.hiddenClearIco != undefined)
            _this.hiddenClearIco = config.hiddenClearIco;
        if (config.hiddenDatePicker != undefined)
            _this.hiddenDatePicker = config.hiddenDatePicker;
        return _this;
    }
    return DatepickerCustomModel;
}(DynamicDatePickerModel));
export { DatepickerCustomModel };
