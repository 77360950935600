import * as tslib_1 from "tslib";
import { DynamicFormControlComponent } from "@ng-dynamic-forms/core";
var DynamicMaterialFormInputControlComponent = /** @class */ (function (_super) {
    tslib_1.__extends(DynamicMaterialFormInputControlComponent, _super);
    function DynamicMaterialFormInputControlComponent(layoutService, validationService) {
        var _this = _super.call(this, layoutService, validationService) || this;
        _this.layoutService = layoutService;
        _this.validationService = validationService;
        return _this;
    }
    Object.defineProperty(DynamicMaterialFormInputControlComponent.prototype, "characterCount", {
        get: function () {
            return this.matInput ? this.matInput.value.length : null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DynamicMaterialFormInputControlComponent.prototype, "characterHint", {
        get: function () {
            return this.characterCount + " / " + this.model.maxLength;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DynamicMaterialFormInputControlComponent.prototype, "showCharacterHint", {
        get: function () {
            return !!(this.model.maxLength && this.characterCount);
        },
        enumerable: true,
        configurable: true
    });
    return DynamicMaterialFormInputControlComponent;
}(DynamicFormControlComponent));
export { DynamicMaterialFormInputControlComponent };
