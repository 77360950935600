import * as tslib_1 from "tslib";
import { EventEmitter } from '@angular/core';
import { FormValueBuilder } from '../../../../../partial/forms/form-tamplate';
import { DynamicFormService } from '@ng-dynamic-forms/core';
import { DATE_PICKER, RADIO } from '../../../../../../../@util/dynamic-form/dynamic-controlls';
import { MedicalService } from '../../../../../../@core/medical.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogService } from '../../../../../../../@util/dialog/dialog.service';
import { DynamicForm } from "../../../../../../../@util/dynamic-form/dynamic-form";
import { AuthenticationService } from "../../../../../../auth/_services/authentication.service";
import { DynamicFormGroupModel, DynamicSelectModel, DynamicTextAreaModel } from "../../../../../../../@util/ui-material/form-controls";
import { GlobalSaveService } from "../../../../../../@core/global-save.service";
import { UserInfoService } from "../../../../../../@core/user-info.service";
import { DateService } from "../../../../../../@core/functions/date.service";
import { PERMISSIONS } from 'src/app/@config/permissions';
var HospitalsVisitComponent = /** @class */ (function (_super) {
    tslib_1.__extends(HospitalsVisitComponent, _super);
    function HospitalsVisitComponent(formService, medicalService, router, dialog, route, globalSaveService, userInfoService, dateService, auth) {
        var _this = _super.call(this) || this;
        _this.formService = formService;
        _this.medicalService = medicalService;
        _this.router = router;
        _this.dialog = dialog;
        _this.route = route;
        _this.globalSaveService = globalSaveService;
        _this.userInfoService = userInfoService;
        _this.dateService = dateService;
        _this.auth = auth;
        _this.backForm = new EventEmitter();
        _this.nextForm = new EventEmitter();
        _this.closeForm = new EventEmitter();
        _this.saveForm = new EventEmitter();
        _this.isAnotherVisit = false;
        _this.PERMISSIONS = PERMISSIONS;
        _this.formLayout = {
            eRVisit: {
                element: {
                    label: 'form-label',
                }
            },
            erVisitG: {
                element: {
                    control: 'form-row',
                    children: 'form-element',
                    container: 'row-mb'
                }
            },
            col2Test: {
                element: {
                    control: 'form-row',
                    children: 'form-element',
                    container: 'row-mb'
                }
            },
            eRVisitDate: {
                element: {
                    host: 'form-element_hide-label'
                }
            },
            outPatient: {
                element: {
                    label: 'form-label',
                    host: 'form-element'
                }
            },
            inPatientG1: {
                element: {
                    control: 'form-row',
                    container: 'row-mb',
                    children: 'form-element form-element_1-2 form_mb'
                }
            },
            inPatient_hospital: {
                element: {
                    label: 'form-label',
                }
            },
            inPatientDischarge_hospital: {
                element: {
                    host: ''
                }
            },
            outPatientG: {
                element: {
                    control: 'form-row',
                    children: 'form-element',
                    container: 'row-mb'
                }
            },
            outPatientDate: {
                element: {
                    host: 'form-element_hide-label'
                }
            },
            inPatientG2: {
                element: {
                    control: 'form-row form-row_wrap',
                    container: 'row-mb',
                    children: 'form-element form-element_1-2 form_mb'
                }
            },
            visitDate: {
                grid: {
                    container: 'row-mb half-width'
                }
            },
            hospitalizationReason: {
                grid: {
                    container: 'row-mb',
                    control: 'column-items'
                }
            },
            conditions: {
                grid: {
                    container: 'row-mb',
                    control: 'column-items'
                }
            },
            treatmentDetails: {
                grid: {
                    container: 'row-mb',
                    control: 'column-items'
                }
            },
            treatmentDetails1: {
                grid: {
                    container: 'row-mb',
                    control: 'column-items'
                }
            },
            treatedDoctor: {
                grid: {
                    container: 'row-mb',
                    control: 'column-items'
                }
            },
        };
        _this.title = 'Medical Visits Summaries';
        _this.hospitalForm = {
            catalogItemId: DynamicSelectModel({
                id: 'catalogItemId',
                label: 'Name',
                required: true,
                validators: {
                    required: null
                },
                errorMessages: {
                    required: 'Required'
                },
                value: '', editableFor: PERMISSIONS.LOWER_EXEC,
            }),
            eRVisit: RADIO({ id: 'eRVisit', editableFor: PERMISSIONS.LOWER_EXEC, label: 'ER visit' }),
            eRVisitDate: DATE_PICKER({ id: 'eRVisitDate', editableFor: PERMISSIONS.LOWER_EXEC, label: '' }),
            outPatient: RADIO({ id: 'outPatient', editableFor: PERMISSIONS.LOWER_EXEC, value: false, label: 'Outpatient' }),
            outPatientDate: DATE_PICKER({ id: 'outPatientDate', editableFor: PERMISSIONS.LOWER_EXEC, disabled: true, label: '' }),
            inPatient: RADIO({ id: 'inPatient_hospital', editableFor: PERMISSIONS.LOWER_EXEC, value: false, label: 'Inpatient' }),
            inPatientAdmission: DATE_PICKER({
                id: 'inPatientAdmission_hospital',
                editableFor: PERMISSIONS.LOWER_EXEC,
                label: 'Admission',
                disabled: true,
                required: undefined
            }),
            inPatientDischarge: DATE_PICKER({
                id: 'inPatientDischarge_hospital',
                editableFor: PERMISSIONS.LOWER_EXEC,
                label: 'Discharge',
                disabled: true,
                required: undefined
            }),
            hospitalizationReason: DynamicTextAreaModel({
                id: 'hospitalizationReason', editableFor: PERMISSIONS.LOWER_EXEC,
                label: 'Reason for Hospitalization',
                maxLength: 512
            }),
            conditions: DynamicTextAreaModel({
                id: 'conditions', editableFor: PERMISSIONS.LOWER_EXEC,
                label: 'Medical Conditions or Diagnoses',
                maxLength: 512
            }),
            treatmentDetails: DynamicTextAreaModel({
                id: 'treatmentDetails', editableFor: PERMISSIONS.LOWER_EXEC,
                label: 'Details of Treatment',
                maxLength: 512
            }),
            treatedDoctor: DynamicTextAreaModel({
                id: 'treatedDoctor',
                editableFor: PERMISSIONS.LOWER_EXEC,
                label: 'Doctors Treated By',
                maxLength: 512
            }),
        };
        // form = {
        // 	// contactPerson: STRING_FIELD({id: 'contactPerson', label: 'Person to contact', maxLength: 32}),
        // 	//visitDate: DATE_PICKER({id: 'visitDate', label: 'Visit Date', editableFor: PERMISSIONS.LOWER_EXEC,}),
        // 	hospital: DynamicFormGroupModel({
        // 		id: 'hospital', editableFor: PERMISSIONS.LOWER_EXEC,
        // 		group: [
        // 			DynamicFormGroupModel({
        // 				id: 'col1',
        // 				editableFor: PERMISSIONS.LOWER_EXEC,
        // 				group: [
        // 					DynamicFormGroupModel({
        // 						id: 'col2Test',
        // 						group: [
        // 							this.hospitalForm.eRVisit,
        // 							this.hospitalForm.eRVisitDate
        // 						], editableFor: PERMISSIONS.LOWER_EXEC}),
        // 					DynamicFormGroupModel({
        // 						id: 'col1Test',
        // 						group: [
        // 							this.hospitalForm.inPatient,
        // 							this.hospitalForm.inPatientAdmission,
        // 						], editableFor: PERMISSIONS.LOWER_EXEC}),]
        // 			}),
        // 			DynamicFormGroupModel({
        // 				id: 'col2',
        // 				editableFor: PERMISSIONS.LOWER_EXEC,
        // 				group: [
        // 					DynamicFormGroupModel({
        // 						id: 'erVisitG', editableFor: PERMISSIONS.LOWER_EXEC,
        // 						group: [this.hospitalForm.outPatient, this.hospitalForm.outPatientDate]
        // 					}),
        // 					DynamicFormGroupModel({
        // 						id: 'inPatientG1', editableFor: PERMISSIONS.LOWER_EXEC,
        // 						group: [this.hospitalForm.inPatientDischarge]
        // 					})
        // 				]
        // 			}),
        // 		]
        // 	})
        // };
        _this.form = {
            // contactPerson: STRING_FIELD({id: 'contactPerson', label: 'Person to contact', maxLength: 32}),
            //visitDate: DATE_PICKER({id: 'visitDate', label: 'Visit Date', editableFor: PERMISSIONS.LOWER_EXEC,}),
            hospital: DynamicFormGroupModel({
                id: 'hospital', editableFor: PERMISSIONS.LOWER_EXEC,
                group: [
                    DynamicFormGroupModel({
                        id: 'col1',
                        editableFor: PERMISSIONS.LOWER_EXEC,
                        group: [
                            DynamicFormGroupModel({
                                id: 'col2Test',
                                group: [
                                    _this.hospitalForm.eRVisit,
                                    _this.hospitalForm.eRVisitDate
                                ], editableFor: PERMISSIONS.LOWER_EXEC
                            }),
                        ]
                    }),
                    DynamicFormGroupModel({
                        id: 'col2',
                        editableFor: PERMISSIONS.LOWER_EXEC,
                        group: [
                            DynamicFormGroupModel({
                                id: 'erVisitG', editableFor: PERMISSIONS.LOWER_EXEC,
                                group: [_this.hospitalForm.outPatient, _this.hospitalForm.outPatientDate]
                            }),
                        ]
                    }),
                    DynamicFormGroupModel({
                        id: 'col3',
                        editableFor: PERMISSIONS.LOWER_EXEC,
                        group: [
                            DynamicFormGroupModel({
                                id: 'inPatientG1', editableFor: PERMISSIONS.LOWER_EXEC,
                                group: [
                                    _this.hospitalForm.inPatient,
                                    _this.hospitalForm.inPatientAdmission,
                                    _this.hospitalForm.inPatientDischarge
                                ]
                            })
                        ]
                    }),
                ]
            })
        };
        _this.formModel = FormValueBuilder.buildFormModel(_this.form);
        _this.formGroup = formService.createFormGroup(_this.formModel);
        return _this;
    }
    HospitalsVisitComponent.prototype.showAddBtn = function (context, index) {
        return context.groups.length - 1 === index;
    };
    HospitalsVisitComponent.prototype.showRemoveBtn = function (context, index) {
        return context.groups.length > 1;
    };
    HospitalsVisitComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () { return tslib_1.__generator(this, function (_a) {
            return [2 /*return*/];
        }); });
    };
    HospitalsVisitComponent.prototype.onBlur = function ($event) {
    };
    HospitalsVisitComponent.prototype.onChange = function ($event) {
        this.initRelations($event.model.id, $event.control.value);
    };
    HospitalsVisitComponent.prototype.initRelations = function (controlId, controlValue) {
        switch (controlId) {
            case this.hospitalForm.eRVisit.id:
                this.hospitalForm.eRVisitDate.disabledUpdates.next(!controlValue);
                this.hospitalForm.eRVisitDate.valueUpdates.next(null);
                this.hospitalForm.outPatient.valueUpdates.next(false);
                this.hospitalForm.outPatientDate.disabledUpdates.next(true);
                this.hospitalForm.outPatientDate.valueUpdates.next(null);
                this.hospitalForm.inPatient.valueUpdates.next(false);
                this.hospitalForm.inPatientDischarge.disabledUpdates.next(true);
                this.hospitalForm.inPatientDischarge.valueUpdates.next(null);
                this.hospitalForm.inPatientAdmission.disabledUpdates.next(true);
                this.hospitalForm.inPatientAdmission.valueUpdates.next(null);
                break;
            case this.hospitalForm.outPatient.id:
                this.hospitalForm.outPatientDate.disabledUpdates.next(!controlValue);
                this.hospitalForm.outPatientDate.valueUpdates.next(null);
                this.hospitalForm.inPatient.valueUpdates.next(false);
                this.hospitalForm.inPatientDischarge.disabledUpdates.next(true);
                this.hospitalForm.inPatientDischarge.valueUpdates.next(null);
                this.hospitalForm.inPatientAdmission.disabledUpdates.next(true);
                this.hospitalForm.inPatientAdmission.valueUpdates.next(null);
                this.hospitalForm.eRVisit.valueUpdates.next(false);
                this.hospitalForm.eRVisitDate.disabledUpdates.next(true);
                this.hospitalForm.eRVisitDate.valueUpdates.next(null);
                break;
            case this.hospitalForm.inPatient.id:
                this.hospitalForm.inPatientDischarge.disabledUpdates.next(!controlValue);
                this.hospitalForm.inPatientDischarge.valueUpdates.next(null);
                this.hospitalForm.inPatientAdmission.disabledUpdates.next(!controlValue);
                this.hospitalForm.inPatientAdmission.valueUpdates.next(null);
                this.hospitalForm.eRVisit.valueUpdates.next(false);
                this.hospitalForm.eRVisitDate.disabledUpdates.next(true);
                this.hospitalForm.eRVisitDate.valueUpdates.next(null);
                this.hospitalForm.outPatient.valueUpdates.next(false);
                this.hospitalForm.outPatientDate.disabledUpdates.next(true);
                this.hospitalForm.outPatientDate.valueUpdates.next(null);
                break;
        }
    };
    HospitalsVisitComponent.prototype.onFocus = function ($event) {
        ////console.log($event);
    };
    HospitalsVisitComponent.prototype.closeVisitForm = function () {
        this.closeForm.emit();
    };
    HospitalsVisitComponent.prototype.saveVisitForm = function () {
        FormValueBuilder.setControlsToched(this.formGroup);
        if (!this.formGroup.valid) {
            return;
        }
        var rez;
        rez = tslib_1.__assign({}, FormValueBuilder.getData(this.hospitalForm));
        rez.visitDate = new Date();
        rez.catalogItemId = this.hospitalForm.catalogItemId.value;
        rez.inPatientAdmission = this.dateService.getDateWithoutHours(rez.inPatientAdmission);
        rez.inPatientDischarge = this.dateService.getDateWithoutHours(rez.inPatientDischarge);
        rez.outPatientDate = this.dateService.getDateWithoutHours(rez.outPatientDate);
        rez.eRVisitDate = this.dateService.getDateWithoutHours(rez.eRVisitDate);
        console.log('save', { data: rez });
        return rez;
        // TODO State before
        // this.reset();
    };
    HospitalsVisitComponent.prototype.ngOnDestroy = function () {
        this.formStateChanged.next({ value: false, key: this.formKey });
    };
    HospitalsVisitComponent.prototype.isDirty = function () {
        return FormValueBuilder.isDirty(this.hospitalForm) ||
            FormValueBuilder.isDirty(this.form);
    };
    HospitalsVisitComponent.prototype.reset = function () {
        FormValueBuilder.resetToDefault(this.hospitalForm);
        FormValueBuilder.resetToDefault(this.form);
    };
    HospitalsVisitComponent.prototype.isClaimant = function () {
        if (this.userInfoService.getCurrentUserRole() == 'CLIENT') {
            return true;
        }
        return false;
    };
    HospitalsVisitComponent.prototype.backHospitalsVisit = function () {
        this.backForm.emit();
    };
    HospitalsVisitComponent.prototype.nextHospitalsVisit = function () {
        this.nextForm.emit(this.saveVisitForm());
    };
    return HospitalsVisitComponent;
}(DynamicForm));
export { HospitalsVisitComponent };
