var NotificationConfig = /** @class */ (function () {
    function NotificationConfig() {
    }
    Object.defineProperty(NotificationConfig.prototype, "id", {
        get: function () {
            return this._id;
        },
        set: function (e) {
        },
        enumerable: true,
        configurable: true
    });
    NotificationConfig.prototype.genId = function () {
        this._id = btoa(Math.random() + '');
    };
    return NotificationConfig;
}());
export { NotificationConfig };
