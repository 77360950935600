import { HttpClient } from "@angular/common/http";
import { CLAIMANT_HEARING_OFFICE_CONFIG } from "../@config/api";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ClaimantHearingOfficeService = /** @class */ (function () {
    function ClaimantHearingOfficeService(http) {
        this.http = http;
    }
    ClaimantHearingOfficeService.prototype.getHearingOffice = function (sslgId) {
        return this.http.get(CLAIMANT_HEARING_OFFICE_CONFIG.get(sslgId));
    };
    ClaimantHearingOfficeService.prototype.updateHearingOffice = function (sslgId, data) {
        return this.http.post(CLAIMANT_HEARING_OFFICE_CONFIG.update(sslgId), data);
    };
    ClaimantHearingOfficeService.prototype.getHearingOfficeData = function (sslgId, zip) {
        return this.http.post(CLAIMANT_HEARING_OFFICE_CONFIG.getData(sslgId), { zip: zip });
    };
    ClaimantHearingOfficeService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ClaimantHearingOfficeService_Factory() { return new ClaimantHearingOfficeService(i0.ɵɵinject(i1.HttpClient)); }, token: ClaimantHearingOfficeService, providedIn: "root" });
    return ClaimantHearingOfficeService;
}());
export { ClaimantHearingOfficeService };
