import { HttpClient } from "@angular/common/http";
import { VERSION_CONFIG } from "../@config/api";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var VersionService = /** @class */ (function () {
    function VersionService(http) {
        this.http = http;
    }
    VersionService.prototype.getVersions = function () {
        return this.http.get(VERSION_CONFIG.getVersions);
    };
    VersionService.prototype.getLastVersion = function () {
        return this.http.get(VERSION_CONFIG.getLastVersion);
    };
    VersionService.prototype.getVersionById = function (versionId) {
        return this.http.get(VERSION_CONFIG.getVersionById(versionId));
    };
    VersionService.prototype.saveEditedVersion = function (versionId, data) {
        return this.http.post(VERSION_CONFIG.saveVersionById(versionId), data);
    };
    VersionService.prototype.createVersion = function (data) {
        return this.http.post(VERSION_CONFIG.createVersion, data);
    };
    VersionService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function VersionService_Factory() { return new VersionService(i0.ɵɵinject(i1.HttpClient)); }, token: VersionService, providedIn: "root" });
    return VersionService;
}());
export { VersionService };
