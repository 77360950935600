import { BehaviorSubject } from "rxjs";
import * as i0 from "@angular/core";
var PageLoaderService = /** @class */ (function () {
    function PageLoaderService() {
        this.loaderShow = new BehaviorSubject(null);
    }
    PageLoaderService.prototype.show = function () {
        this.loaderShow.next(true);
    };
    PageLoaderService.prototype.hide = function () {
        this.loaderShow.next(false);
    };
    PageLoaderService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PageLoaderService_Factory() { return new PageLoaderService(); }, token: PageLoaderService, providedIn: "root" });
    return PageLoaderService;
}());
export { PageLoaderService };
