import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { MEDICAL_TREATING_REPORT_TYPE } from "../../pages/claimants/medical-treating-sources/medical-treating-reports/medical-treating-reports.config";
var MedicalTreatingReportHeaderComponent = /** @class */ (function () {
    function MedicalTreatingReportHeaderComponent() {
    }
    MedicalTreatingReportHeaderComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/];
            });
        });
    };
    MedicalTreatingReportHeaderComponent.prototype.isMedicationReport = function () {
        return this.data.type === MEDICAL_TREATING_REPORT_TYPE.medications;
    };
    MedicalTreatingReportHeaderComponent.prototype.isTreatingSourceReport = function () {
        return this.data.type === MEDICAL_TREATING_REPORT_TYPE.mts;
    };
    return MedicalTreatingReportHeaderComponent;
}());
export { MedicalTreatingReportHeaderComponent };
