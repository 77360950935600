import * as tslib_1 from "tslib";
import { DynamicInputModel } from "@ng-dynamic-forms/core";
import { isObservable, of } from "rxjs";
import { EventEmitter } from "@angular/core";
import { tap } from "rxjs/operators";
var DynamicInputAutocompleteModel = /** @class */ (function (_super) {
    tslib_1.__extends(DynamicInputAutocompleteModel, _super);
    function DynamicInputAutocompleteModel() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.list$ = null;
        _this.autocompleteSelectedValue = null;
        _this.autocompleteSelectedValueChanged = new EventEmitter();
        return _this;
    }
    Object.defineProperty(DynamicInputAutocompleteModel.prototype, "list", {
        set: function (list) {
            var _this = this;
            if (Array.isArray(list)) {
                this._list = list;
                this.list$ = of(this._list);
            }
            else if (isObservable(list)) {
                this.list$ = list.pipe(tap(function (list) { return _this._list = list; }));
            }
            else {
                this._list = null;
                this.list$ = null;
            }
        },
        enumerable: true,
        configurable: true
    });
    return DynamicInputAutocompleteModel;
}(DynamicInputModel));
export { DynamicInputAutocompleteModel };
