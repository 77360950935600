import * as tslib_1 from "tslib";
import { HttpResponse } from '@angular/common/http';
import { map } from 'rxjs/operators';
var DateResponseInterceptor = /** @class */ (function () {
    function DateResponseInterceptor() {
    }
    DateResponseInterceptor.prototype.intercept = function (request, next) {
        var _this = this;
        if (request.body) {
            var req = request.clone({ body: this.convertDatesToUTC(request.body) });
            //console.log(req);
        }
        return next.handle(request).pipe(map(function (event) {
            // Pass through everything except for the final response.
            if (!(event instanceof HttpResponse)) {
                return event;
            }
            return _this.processJsonResponse(event);
        }));
    };
    DateResponseInterceptor.prototype.processJsonResponse = function (res) {
        var body = res.body;
        this.convertDates(body);
        return res.clone({ body: body });
    };
    DateResponseInterceptor.prototype.convertDatesToUTC = function (object) {
        var e_1, _a, e_2, _b, e_3, _c;
        if (!object || !(object instanceof Object)) {
            return;
        }
        if (object instanceof Array) {
            try {
                for (var object_1 = tslib_1.__values(object), object_1_1 = object_1.next(); !object_1_1.done; object_1_1 = object_1.next()) {
                    var item = object_1_1.value;
                    this.convertDatesToUTC(item);
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (object_1_1 && !object_1_1.done && (_a = object_1.return)) _a.call(object_1);
                }
                finally { if (e_1) throw e_1.error; }
            }
        }
        try {
            for (var _d = tslib_1.__values(Object.keys(object)), _e = _d.next(); !_e.done; _e = _d.next()) {
                var key = _e.value;
                var value = object[key];
                if (value instanceof Array) {
                    try {
                        for (var value_1 = (e_3 = void 0, tslib_1.__values(value)), value_1_1 = value_1.next(); !value_1_1.done; value_1_1 = value_1.next()) {
                            var item = value_1_1.value;
                            this.convertDatesToUTC(item);
                        }
                    }
                    catch (e_3_1) { e_3 = { error: e_3_1 }; }
                    finally {
                        try {
                            if (value_1_1 && !value_1_1.done && (_c = value_1.return)) _c.call(value_1);
                        }
                        finally { if (e_3) throw e_3.error; }
                    }
                }
                if (value instanceof Object) {
                    if (value.constructor.name === 'Date') {
                        //console.log(value);
                        //console.log((<Date>value).toISOString());
                        object[key] = value.toISOString();
                        //console.log(new Date(object[key]));
                        // console.log(object[key])
                    }
                    else {
                        this.convertDatesToUTC(value);
                    }
                }
                if (typeof value === 'string' && isNaN(+value) && value.match(/^\d{4}\-\d{2}\-\d{2}\T\d{2}\:\d{2}\:\d{2}\.\d{3}\+\d{4}$/)) {
                    object[key] = new Date(value);
                }
                else if (typeof value === 'string') {
                    object[key] = value.trimLeft().trimRight();
                }
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (_e && !_e.done && (_b = _d.return)) _b.call(_d);
            }
            finally { if (e_2) throw e_2.error; }
        }
    };
    DateResponseInterceptor.prototype.convertDates = function (object) {
        var e_4, _a, e_5, _b, e_6, _c;
        if (!object || !(object instanceof Object)) {
            return;
        }
        if (object instanceof Array) {
            try {
                for (var object_2 = tslib_1.__values(object), object_2_1 = object_2.next(); !object_2_1.done; object_2_1 = object_2.next()) {
                    var item = object_2_1.value;
                    this.convertDates(item);
                }
            }
            catch (e_4_1) { e_4 = { error: e_4_1 }; }
            finally {
                try {
                    if (object_2_1 && !object_2_1.done && (_a = object_2.return)) _a.call(object_2);
                }
                finally { if (e_4) throw e_4.error; }
            }
        }
        try {
            for (var _d = tslib_1.__values(Object.keys(object)), _e = _d.next(); !_e.done; _e = _d.next()) {
                var key = _e.value;
                var value = object[key];
                if (value instanceof Array) {
                    try {
                        for (var value_2 = (e_6 = void 0, tslib_1.__values(value)), value_2_1 = value_2.next(); !value_2_1.done; value_2_1 = value_2.next()) {
                            var item = value_2_1.value;
                            this.convertDates(item);
                        }
                    }
                    catch (e_6_1) { e_6 = { error: e_6_1 }; }
                    finally {
                        try {
                            if (value_2_1 && !value_2_1.done && (_c = value_2.return)) _c.call(value_2);
                        }
                        finally { if (e_6) throw e_6.error; }
                    }
                }
                if (value instanceof Object) {
                    this.convertDates(value);
                }
                if (typeof value === 'string' && isNaN(+value) && value.match(/^\d{4}\-\d{2}\-\d{2}\T\d{2}\:\d{2}\:\d{2}\.\d{3}\+\d{4}$/)) {
                    object[key] = new Date(value);
                }
            }
        }
        catch (e_5_1) { e_5 = { error: e_5_1 }; }
        finally {
            try {
                if (_e && !_e.done && (_b = _d.return)) _b.call(_d);
            }
            finally { if (e_5) throw e_5.error; }
        }
    };
    return DateResponseInterceptor;
}());
export { DateResponseInterceptor };
