import * as tslib_1 from "tslib";
import { EventEmitter } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { RippleGlobalOptions } from "@angular/material/core";
import { MatSlideToggle } from "@angular/material/slide-toggle";
import { DynamicFormControlComponent, DynamicFormLayoutService, DynamicFormValidationService, DynamicSwitchModel } from "@ng-dynamic-forms/core";
var DynamicMaterialSlideToggleComponent = /** @class */ (function (_super) {
    tslib_1.__extends(DynamicMaterialSlideToggleComponent, _super);
    function DynamicMaterialSlideToggleComponent(layoutService, validationService, RIPPLE_OPTIONS) {
        var _this = _super.call(this, layoutService, validationService) || this;
        _this.layoutService = layoutService;
        _this.validationService = validationService;
        _this.RIPPLE_OPTIONS = RIPPLE_OPTIONS;
        _this.blur = new EventEmitter();
        _this.change = new EventEmitter();
        _this.customEvent = new EventEmitter();
        _this.focus = new EventEmitter();
        return _this;
    }
    return DynamicMaterialSlideToggleComponent;
}(DynamicFormControlComponent));
export { DynamicMaterialSlideToggleComponent };
