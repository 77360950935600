import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
var MedicalTreatingSourcesComponent = /** @class */ (function () {
    function MedicalTreatingSourcesComponent(route) {
        this.route = route;
        this.hideHeader = false;
        this.board = ['Visits', 'Doctors'];
        this.left = ['Hospitals'];
        this.right = [];
    }
    MedicalTreatingSourcesComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.sslgId = this.route.snapshot.paramMap.get('sslgId');
                this.navLinks = [
                    {
                        path: '/claimants/medical-treating-sources/' + this.sslgId + '/doctors',
                        label: 'Doctors',
                    },
                    {
                        path: '/claimants/medical-treating-sources/' + this.sslgId + '/hospitals',
                        label: 'Hospitals',
                    },
                    {
                        path: '/claimants/medical-treating-sources/' + this.sslgId + '/others',
                        label: 'Others',
                    },
                    {
                        path: '/claimants/medical-treating-sources/' + this.sslgId + '/medications',
                        label: 'Medications',
                    }
                ];
                return [2 /*return*/];
            });
        });
    };
    return MedicalTreatingSourcesComponent;
}());
export { MedicalTreatingSourcesComponent };
