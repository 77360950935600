import { AUTH_CONFIG } from './@config/api';
import { Validator } from '@angular/forms';
import { emailValidator, ICD_10Validator } from '../@util/dynamic-form/validators';
import { ValidatorFactory } from '@ng-dynamic-forms/core';
var idleConfig = { idle: 900, timeout: 60, ping: 180, showIdleDialog: true };
export var authConfig = {
    authUserNameUrl: AUTH_CONFIG.sendUserName,
    authUrl: AUTH_CONFIG.login,
    refreshTokenUrl: AUTH_CONFIG.refresh,
    loginRoute: '/login',
    restoreRoute: '/restore',
    checkUser: AUTH_CONFIG.checkUser,
    restoreUrl: AUTH_CONFIG.restore,
};
export var loadingConfig = {
    showErrors: true,
    showSpinner: true,
    // showSpinner: false,
    ignoreUrls: ['/api/notifications', '/api/notifications/alerts']
};
var ɵ0 = emailValidator, ɵ1 = new Map([
    ['emailValidator', emailValidator],
    ['ICD_10Validator', ICD_10Validator],
]), ɵ2 = { useUtc: true };
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
export { ɵ0, ɵ1, ɵ2 };
