import { State } from "../test-new-stepper-form/test-form/on-changes";
import { MatDialog } from "@angular/material";
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/dialog";
var CanDeactivateService = /** @class */ (function () {
    function CanDeactivateService(dialog) {
        this.dialog = dialog;
    }
    CanDeactivateService.prototype.canDeactivate = function (component, currentRoute, currentState) {
        //console.log('canDeactivate');
        if (component.state == State.before) {
            if (component.allFormState == State.after) {
                // const dialogRef = this.dialog.open(AuditLogComponent, {
                // 	panelClass: ['mobile-popup', 'with-shadow'],
                // 	backdropClass: 'mobile-popup__overlay',
                // 	position: {top: '8%', left: '18%'},
                // 	disableClose: true
                // });
                //
                // if (currentRoute.data.changes) {
                // 	return true
                // } else {
                // 	return dialogRef.afterClosed().pipe(map(result => {
                // 		return true;
                // 	}));
                // }
                return true;
            }
            else {
                return true;
            }
        }
        else if (component.state == State.after) {
            /*const dialogRef = this.dialog.open(FormChangesAttentionComponent, {
                data: {
                    closeBtnText: 'Ignore changes',
                    infoText: 'You have unsaved data. Please confirm before proceeding.'
                },
                panelClass: 'attention-form-changes',
                disableClose: true
            });
            return dialogRef.afterClosed().pipe(map(result => {
                return result == 'leave';
            }));*/
            return true;
        }
    };
    CanDeactivateService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CanDeactivateService_Factory() { return new CanDeactivateService(i0.ɵɵinject(i1.MatDialog)); }, token: CanDeactivateService, providedIn: "root" });
    return CanDeactivateService;
}());
export { CanDeactivateService };
