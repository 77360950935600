import { HttpClient } from "@angular/common/http";
import { DEVICES_CONFIG } from "../@config/api";
import { UserInfoService } from "./user-info.service";
import { DialogService } from "../../@util/dialog/dialog.service";
import * as i0 from "@angular/core";
import * as i1 from "../../@util/dialog/dialog.service";
import * as i2 from "@angular/common/http";
import * as i3 from "./user-info.service";
var DevicesService = /** @class */ (function () {
    function DevicesService(dialog, http, userInfoService) {
        this.dialog = dialog;
        this.http = http;
        this.userInfoService = userInfoService;
        this.config = DEVICES_CONFIG;
    }
    DevicesService.prototype.add = function (uuid, type) {
        var userName = this.getUserName();
        return this.http.post(this.config.add, {
            userName: userName,
            uuid: uuid,
            type: type,
        });
    };
    DevicesService.prototype.setLocalFirebaseToken = function () {
        var token = this.userInfoService.getCurrentFirebaseToken();
        this.saveFirebaseToken(token);
    };
    DevicesService.prototype.setFirebaseToken = function (token) {
        this.saveFirebaseToken(token);
    };
    DevicesService.prototype.saveFirebaseToken = function (token) {
        var _this = this;
        var userName = this.getUserName();
        var currentFirebaseToken = this.userInfoService.getCurrentFirebaseToken();
        var uuid = this.userInfoService.getDeviceUuid();
        if (currentFirebaseToken != token && userName != '' && uuid != '') {
            this.http.post(this.config.saveFirebaseToken, {
                userName: userName,
                token: token,
                uuid: uuid
            }).subscribe(function (data) {
                _this.userInfoService.setCurrentFirebaseToken(token);
            });
        }
    };
    DevicesService.prototype.getUserName = function () {
        return this.userInfoService.getCurrentUserName();
    };
    DevicesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DevicesService_Factory() { return new DevicesService(i0.ɵɵinject(i1.DialogService), i0.ɵɵinject(i2.HttpClient), i0.ɵɵinject(i3.UserInfoService)); }, token: DevicesService, providedIn: "root" });
    return DevicesService;
}());
export { DevicesService };
