import * as i0 from "@angular/core";
var MenuService = /** @class */ (function () {
    function MenuService() {
    }
    MenuService.prototype.getAuditLogConfig = function () {
        return {
            // width: '250px',
            panelClass: ['mobile-popup', 'with-shadow'],
            backdropClass: 'mobile-popup__overlay',
            position: { top: '8%', left: '18%' },
            disableClose: true
        };
    };
    MenuService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MenuService_Factory() { return new MenuService(); }, token: MenuService, providedIn: "root" });
    return MenuService;
}());
export { MenuService };
