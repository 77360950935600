import { HttpClient } from "@angular/common/http";
import { POST_AWARD_CONFIG } from "../@config/api";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var PostAwardDetailsService = /** @class */ (function () {
    function PostAwardDetailsService(http) {
        this.http = http;
        this.config = POST_AWARD_CONFIG.award_details;
    }
    PostAwardDetailsService.prototype.getOverview = function (sslgId) {
        return this.http.get(this.config.overview(sslgId));
    };
    PostAwardDetailsService.prototype.setOverview = function (sslgId, data) {
        return this.http.post(this.config.overview(sslgId), data);
    };
    PostAwardDetailsService.prototype.getDependent = function (sslgId) {
        return this.http.get(this.config.dependent(sslgId));
    };
    PostAwardDetailsService.prototype.setDependent = function (sslgId, data) {
        return this.http.post(this.config.dependent(sslgId), data);
    };
    PostAwardDetailsService.prototype.getPrimary = function (sslgId) {
        return this.http.get(this.config.primary(sslgId));
    };
    PostAwardDetailsService.prototype.setPrimary = function (sslgId, data) {
        return this.http.post(this.config.primary(sslgId), data);
    };
    PostAwardDetailsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PostAwardDetailsService_Factory() { return new PostAwardDetailsService(i0.ɵɵinject(i1.HttpClient)); }, token: PostAwardDetailsService, providedIn: "root" });
    return PostAwardDetailsService;
}());
export { PostAwardDetailsService };
