import { FileUploadingService } from '../../@util/file-uploading-progress/file-uploading.service';
import { Observable } from "rxjs";
import { HttpClient, HttpParams } from "@angular/common/http";
import * as i0 from "@angular/core";
import * as i1 from "../../@util/file-uploading-progress/file-uploading.service";
import * as i2 from "@angular/common/http";
var DocumentService = /** @class */ (function () {
    function DocumentService(fileUploadingService, http) {
        this.fileUploadingService = fileUploadingService;
        this.http = http;
        this.utl = function (sslgId) { return "/api/claimant/" + sslgId + "/documents"; };
    }
    DocumentService.prototype.pushFileToStorage = function (data) {
        this.fileUploadingService.uploadFile(this.utl(data.fileInfo.sslgId), data);
    };
    DocumentService.prototype.uploadEnded = function () {
        return this.fileUploadingService.uploadEnded;
    };
    DocumentService.prototype.downloadDocument = function (sslgId, documentId) {
        return this.fileUploadingService.downloadDocument(this.utl(sslgId) + "/" + documentId + "/download");
    };
    DocumentService.prototype.getDocuments = function (sslgId, title) {
        var params = new HttpParams();
        if (title && title.trim().length > 0 && title !== 'null') {
            params = params.append('title', title);
        }
        return this.http.get(this.utl(sslgId), { params: params });
    };
    DocumentService.prototype.getById = function (sslgId, documentId) {
        return this.http.get(this.utl(sslgId) + "/" + documentId);
    };
    DocumentService.prototype.isExist = function (sslgId, name) {
        return this.http.get(this.utl(sslgId) + "/exist/" + name);
    };
    DocumentService.prototype.getTitlesForClaim = function (sslgId) {
        return this.http.get(this.utl(sslgId) + "/titles");
    };
    DocumentService.prototype.getTitles = function () {
        return this.http.get('/api/claimant/documents/titles');
    };
    DocumentService.prototype.updateFileInfo = function (sslgId, documentId, formData) {
        return this.http.post(this.utl(sslgId) + "/" + documentId + "/update", formData);
    };
    DocumentService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DocumentService_Factory() { return new DocumentService(i0.ɵɵinject(i1.FileUploadingService), i0.ɵɵinject(i2.HttpClient)); }, token: DocumentService, providedIn: "root" });
    return DocumentService;
}());
export { DocumentService };
