import * as tslib_1 from "tslib";
import { EventEmitter } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { MatSlider } from "@angular/material/slider";
import { DynamicFormControlComponent, DynamicFormLayoutService, DynamicFormValidationService, DynamicSliderModel } from "@ng-dynamic-forms/core";
var DynamicMaterialSliderComponent = /** @class */ (function (_super) {
    tslib_1.__extends(DynamicMaterialSliderComponent, _super);
    function DynamicMaterialSliderComponent(layoutService, validationService) {
        var _this = _super.call(this, layoutService, validationService) || this;
        _this.layoutService = layoutService;
        _this.validationService = validationService;
        _this.blur = new EventEmitter();
        _this.change = new EventEmitter();
        _this.customEvent = new EventEmitter();
        _this.focus = new EventEmitter();
        return _this;
    }
    return DynamicMaterialSliderComponent;
}(DynamicFormControlComponent));
export { DynamicMaterialSliderComponent };
