import { HttpClient } from "@angular/common/http";
import { LTD_INFO_CONFIG } from "../@config/api";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var LtdInfoService = /** @class */ (function () {
    function LtdInfoService(http) {
        this.http = http;
        this.config = LTD_INFO_CONFIG;
    }
    LtdInfoService.prototype.getLtdInfo = function () {
        return this.http.get(this.config.getLtd, { responseType: 'text' });
    };
    LtdInfoService.prototype.setLtdInfo = function (data) {
        return this.http.post(this.config.setLtd, data, { responseType: 'text' });
    };
    LtdInfoService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LtdInfoService_Factory() { return new LtdInfoService(i0.ɵɵinject(i1.HttpClient)); }, token: LtdInfoService, providedIn: "root" });
    return LtdInfoService;
}());
export { LtdInfoService };
