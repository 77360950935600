import * as tslib_1 from "tslib";
import { EventEmitter } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { ErrorStateMatcher, LabelOptions, RippleGlobalOptions } from "@angular/material/core";
import { MatSelect } from "@angular/material/select";
import { DynamicFormControlComponent, DynamicFormLayoutService, DynamicFormValidationService, DynamicSelectModel } from "@ng-dynamic-forms/core";
var DynamicMaterialSelectComponent = /** @class */ (function (_super) {
    tslib_1.__extends(DynamicMaterialSelectComponent, _super);
    function DynamicMaterialSelectComponent(layoutService, validationService, errorStateMatcher, LABEL_OPTIONS, RIPPLE_OPTIONS) {
        var _this = _super.call(this, layoutService, validationService) || this;
        _this.layoutService = layoutService;
        _this.validationService = validationService;
        _this.errorStateMatcher = errorStateMatcher;
        _this.LABEL_OPTIONS = LABEL_OPTIONS;
        _this.RIPPLE_OPTIONS = RIPPLE_OPTIONS;
        _this.blur = new EventEmitter();
        _this.change = new EventEmitter();
        _this.customEvent = new EventEmitter();
        _this.focus = new EventEmitter();
        return _this;
    }
    return DynamicMaterialSelectComponent;
}(DynamicFormControlComponent));
export { DynamicMaterialSelectComponent };
