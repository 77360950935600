import * as tslib_1 from "tslib";
import { NavigationService } from "./@core/navigation.service";
import { ReferralService } from "./@core/referral.service";
import { map } from "rxjs/operators";
var TitleRouterResolver = /** @class */ (function () {
    function TitleRouterResolver(navService, referralService) {
        this.navService = navService;
        this.referralService = referralService;
    }
    TitleRouterResolver.prototype.resolve = function (route, state) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var sslgId;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                sslgId = this.getSslgId(route);
                if (route.data.title) {
                    if (route.data.title.indexOf("${referral}") > -1) {
                        if (!this.referralService.current) {
                            if (!sslgId) {
                                Error("SSLG Id Not Found");
                                return [2 /*return*/, false];
                            }
                            return [2 /*return*/, this.referralService.getClaimantLevelDetails(sslgId).pipe(map(function (current) {
                                    _this.referralService.setCurrent(current);
                                    _this.navService
                                        .setTitle(route.data.title.replace('${referral}', _this.referralService.current.firstName + ' ' + _this.referralService.current.lastName));
                                })).subscribe()];
                        }
                        else {
                            this.navService
                                .setTitle(route.data.title.replace('${referral}', this.referralService.current.firstName + ' ' + this.referralService.current.lastName));
                        }
                    }
                    else {
                        this.navService.setTitle(route.data.title);
                    }
                }
                else if (sslgId) {
                    return [2 /*return*/, this.referralService.getClaimantLevelDetails(sslgId).pipe(map(function (current) {
                            _this.referralService.setCurrent(current);
                        })).subscribe()];
                }
                return [2 /*return*/, true];
            });
        });
    };
    TitleRouterResolver.prototype.getSslgId = function (route) {
        var sslgId = route.paramMap.get('sslgId');
        if (!sslgId && route.parent)
            return this.getSslgId(route.parent);
        return sslgId;
    };
    return TitleRouterResolver;
}());
export { TitleRouterResolver };
