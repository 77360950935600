import { NgZone } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AuthenticationService } from "../auth/_services/authentication.service";
import { UserInfoService } from "../@core/user-info.service";
import { LoadingService } from "../../@util/loading/loading.service";
import { DialogService } from "../../@util/dialog/dialog.service";
import { DevicesService } from "./devices.service";
import * as i0 from "@angular/core";
import * as i1 from "../../@util/dialog/dialog.service";
import * as i2 from "../auth/_services/authentication.service";
import * as i3 from "./user-info.service";
import * as i4 from "./devices.service";
import * as i5 from "../../@util/loading/loading.service";
var PhoneService = /** @class */ (function () {
    function PhoneService(dialog, ngZone, authenticationService, userInfoService, devicesService, loading) {
        this.dialog = dialog;
        this.ngZone = ngZone;
        this.authenticationService = authenticationService;
        this.userInfoService = userInfoService;
        this.devicesService = devicesService;
        this.loading = loading;
        this.avatarChanged = new BehaviorSubject(null);
        this.showPreloader = new BehaviorSubject(null);
        this.hidePreloader = new BehaviorSubject(null);
    }
    PhoneService.prototype.init = function () {
        window.myPhone = window.myPhone || {};
        window.myPhone.namespace = window.myPhone.namespace || {};
        window.myPhone.namespace.publicFunc = this.publicFunc.bind(this);
    };
    PhoneService.prototype.destroy = function (blockId) {
        window.myPhone.namespace.publicFunc = null;
    };
    PhoneService.prototype.publicFunc = function (json) {
        //this.dialog.show(json, ['OK']);
        if (typeof json === 'string') {
            try {
                json = JSON.parse(json);
            }
            catch (e) {
            }
        }
        if (json.status == "true") {
            // this.loading.loaded(this.loader);
            this.loading.loaded();
            var command = json.command;
            if (command == 'changeLogo') {
                this.avatarChanged.next(Math.round(+new Date() / 1000) + Math.random());
            }
            if (command == 'uploadDocumentMedicalRecords') {
                this.dialog.show(json.message, ['OK']);
            }
            if (command == 'showPreloader') {
                this.loader = this.loading.loading();
            }
            if (command == 'hidePreloader') {
                //this.dialog.show('showPreloader', ['OK']);
                //this.loading.loaded(this.loader);
            }
            if (command == 'firebaseToken') {
                var token = json.token;
                //this.dialog.show(command, ['OK']);
                //this.dialog.show(token, ['OK']);
                this.devicesService.setFirebaseToken(token);
            }
        }
    };
    PhoneService.prototype.setNotificationToken = function () {
        var claimantName = this.userInfoService.getCurrentUserName();
        var token = this.userInfoService.getToken();
        var json = '{"command":"notificationToken","token":"' + token + '","claimantName":"' + claimantName + '"}';
        this.sendCommand(json);
    };
    PhoneService.prototype.setChangeAvatar = function (claimantName) {
        var token = this.userInfoService.getToken();
        var json = '{"command":"changeLogo","token":"' + token + '","claimantName":"' + claimantName + '"}';
        this.sendCommand(json);
    };
    PhoneService.prototype.sendMedicalRecords = function () {
        var token = this.userInfoService.getToken();
        var json = '{"command":"sendMedicalRecords","token":"' + token + '"}';
        this.sendCommand(json);
    };
    PhoneService.prototype.uploadDocumentMedicalRecords = function (claimantName, title) {
        var token = this.userInfoService.getToken();
        var json = '{"command":"uploadDocumentMedicalRecords","token":"' + token + '","claimantName":"' + claimantName + '","data":"' + title + '"}';
        this.sendCommand(json);
    };
    PhoneService.prototype.setChangeAvatarInsurance = function (id) {
        var token = this.userInfoService.getToken();
        var json = '{"command":"changeAvatarInsurance","token":"' + token + '","id":"' + id + '"}';
        this.sendCommand(json);
    };
    PhoneService.prototype.setMakeCall = function (phone) {
        var json = '{"command":"makePhone","phone":"' + phone + '"}';
        this.sendCommand(json);
    };
    PhoneService.prototype.setMakeMailTo = function (email) {
        var json = '{"command":"makeMailTo","email":"' + email + '"}';
        this.sendCommand(json);
    };
    PhoneService.prototype.sendCommand = function (json) {
        if (window.webkit) {
            window.webkit.messageHandlers.phoneListener.postMessage(json);
        }
        try {
            AndroidFunction.getCommand(json);
        }
        catch (e) {
        }
    };
    PhoneService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PhoneService_Factory() { return new PhoneService(i0.ɵɵinject(i1.DialogService), i0.ɵɵinject(i0.NgZone), i0.ɵɵinject(i2.AuthenticationService), i0.ɵɵinject(i3.UserInfoService), i0.ɵɵinject(i4.DevicesService), i0.ɵɵinject(i5.LoadingService)); }, token: PhoneService, providedIn: "root" });
    return PhoneService;
}());
export { PhoneService };
