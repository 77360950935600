import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { FormGroup } from "@angular/forms";
import { DynamicFormControlCustomEvent, DynamicFormLayout, DynamicFormLayoutService, DynamicFormValidationService } from "@ng-dynamic-forms/core";
import { MatAutocomplete, MatAutocompleteDefaultOptions } from "@angular/material/autocomplete";
import { ErrorStateMatcher, LabelOptions, RippleGlobalOptions } from "@angular/material/core";
import { MatInput } from "@angular/material/input";
import { DynamicMaterialFormInputControlComponent } from "../dynamic-material-form-input-control.component";
import { DynamicMoneyInputModel } from "./dynamic-money-input-model";
var DynamicMoneyComponent = /** @class */ (function (_super) {
    tslib_1.__extends(DynamicMoneyComponent, _super);
    function DynamicMoneyComponent(layoutService, validationService, errorStateMatcher, AUTOCOMPLETE_OPTIONS, LABEL_OPTIONS, RIPPLE_OPTIONS) {
        var _this = _super.call(this, layoutService, validationService) || this;
        _this.layoutService = layoutService;
        _this.validationService = validationService;
        _this.errorStateMatcher = errorStateMatcher;
        _this.AUTOCOMPLETE_OPTIONS = AUTOCOMPLETE_OPTIONS;
        _this.LABEL_OPTIONS = LABEL_OPTIONS;
        _this.RIPPLE_OPTIONS = RIPPLE_OPTIONS;
        _this.blur = new EventEmitter();
        _this.change = new EventEmitter();
        _this.customEvent = new EventEmitter();
        _this.focus = new EventEmitter();
        return _this;
    }
    DynamicMoneyComponent.prototype.onFocus = function (value) {
        var _this = this;
        setTimeout(function () { return _this.matInput.value = _this.model.parse(_this.matInput.value); });
        _super.prototype.onFocus.call(this, value);
    };
    DynamicMoneyComponent.prototype.onBlur = function (value) {
        var _this = this;
        setTimeout(function () { return _this.matInput.value = _this.model.transform(_this.matInput.value); });
        _super.prototype.onBlur.call(this, value);
    };
    DynamicMoneyComponent.prototype.ngOnInit = function () {
        this.model.inputElement = this;
    };
    return DynamicMoneyComponent;
}(DynamicMaterialFormInputControlComponent));
export { DynamicMoneyComponent };
