import * as tslib_1 from "tslib";
import { EventEmitter } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { MatRadioGroup } from "@angular/material/radio";
import { DynamicFormControlComponent, DynamicFormLayoutService, DynamicFormValidationService, DynamicRadioGroupModel } from "@ng-dynamic-forms/core";
var DynamicMaterialRadioGroupComponent = /** @class */ (function (_super) {
    tslib_1.__extends(DynamicMaterialRadioGroupComponent, _super);
    function DynamicMaterialRadioGroupComponent(layoutService, validationService) {
        var _this = _super.call(this, layoutService, validationService) || this;
        _this.layoutService = layoutService;
        _this.validationService = validationService;
        _this.blur = new EventEmitter();
        _this.change = new EventEmitter();
        _this.customEvent = new EventEmitter();
        _this.focus = new EventEmitter();
        return _this;
    }
    return DynamicMaterialRadioGroupComponent;
}(DynamicFormControlComponent));
export { DynamicMaterialRadioGroupComponent };
