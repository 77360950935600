import { OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { AuthenticationService } from '../_services/authentication.service';
import { ClientsService } from '../../@core/clients.service';
import { DialogService } from "../../../@util/dialog/dialog.service";
import { BreakpointObserver } from "@angular/cdk/layout";
import { AuthConfig } from "../_services/auth-config";
var RestoreComponent = /** @class */ (function () {
    function RestoreComponent(authConfig, formBuilder, route, router, breakpointObserver, authenticationService, clientsService, dialog) {
        this.authConfig = authConfig;
        this.formBuilder = formBuilder;
        this.route = route;
        this.router = router;
        this.breakpointObserver = breakpointObserver;
        this.authenticationService = authenticationService;
        this.clientsService = clientsService;
        this.dialog = dialog;
        this.loading = false;
        this.submitted = false;
        this.error = '';
        this.email = '';
        this.hash = '';
    }
    RestoreComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.params.subscribe(function (data) {
            if (data.email && data.hash) {
                _this.email = data.email;
                _this.hash = data.hash;
            }
            else {
                _this.router.navigate([_this.authConfig.loginRoute]);
            }
        });
        this.restoreForm = this.formBuilder.group({
            confirmPassword: [null, Validators.required],
            password: [null, Validators.required],
        });
        this.restoreForm.controls.password.setValidators([Validators.minLength(6), Validators.maxLength(16)]);
        this.restoreForm.controls.confirmPassword.setValidators([Validators.minLength(6), Validators.maxLength(16)]);
    };
    Object.defineProperty(RestoreComponent.prototype, "f", {
        //
        // convenience getter for easy access to form fields
        get: function () {
            return this.restoreForm.controls;
        },
        enumerable: true,
        configurable: true
    });
    RestoreComponent.prototype.onSubmit = function () {
        var thisEl = this;
        thisEl.submitted = true;
        // stop here if form is invalid
        if (thisEl.restoreForm.invalid) {
            return;
        }
        this.loading = true;
        var password = this.restoreForm.controls.password.value;
        var confirmPassword = this.restoreForm.controls.confirmPassword.value;
        if (password == confirmPassword) {
            thisEl.authenticationService.restore(this.email, this.hash, password)
                .subscribe(function (data) {
                if (data.status && data.status == 'true') {
                    var login_1 = data.userId;
                    thisEl.dialog.show('Successfully updated. Redirecting in five seconds.', ['OK']);
                    setTimeout(function () {
                        thisEl.goToLogin(login_1, password);
                    }, 5000);
                }
                else {
                    thisEl.dialog.show(data.message, ['OK']);
                    thisEl.loading = false;
                }
            }, function (error1) {
                thisEl.loading = false;
            });
        }
        else {
            thisEl.dialog.show('Passwords do not match', ['OK']);
            thisEl.loading = false;
        }
    };
    RestoreComponent.prototype.goToLogin = function (login, password, ignore, pin) {
        var _this = this;
        if (ignore === void 0) { ignore = false; }
        if (pin === void 0) { pin = ''; }
        this.authenticationService.login(login, password, pin, ignore)
            .subscribe(function (data) {
            _this.router.navigate(["/"]);
            _this.loading = false;
        }, function (error1) {
            _this.loading = false;
        });
    };
    return RestoreComponent;
}());
export { RestoreComponent };
