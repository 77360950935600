import { OnInit } from '@angular/core';
import { UserAdditionalInfo } from "../../../@models/user-additional-info";
import { environment } from "../../../../environments/environment";
import { Claimant } from "../../../@models/system-managment/claimant";
import { ReferralService } from "../../../@core/referral.service";
import { GlobalSaveService } from "../../../@core/global-save.service";
var AdditionalUserInfoComponent = /** @class */ (function () {
    function AdditionalUserInfoComponent(referallService, globalSaveService) {
        this.referallService = referallService;
        this.globalSaveService = globalSaveService;
        this.additionalInfo = new UserAdditionalInfo();
    }
    Object.defineProperty(AdditionalUserInfoComponent.prototype, "currentRole", {
        set: function (role) {
            if (role) {
                this._currentRole = role;
                this.isSetAdditional();
            }
        },
        enumerable: true,
        configurable: true
    });
    ;
    Object.defineProperty(AdditionalUserInfoComponent.prototype, "currentUser", {
        set: function (user) {
            if (user) {
                this._currentUser = user;
                this.isSetAdditional();
            }
        },
        enumerable: true,
        configurable: true
    });
    ;
    Object.defineProperty(AdditionalUserInfoComponent.prototype, "currentUserInfo", {
        set: function (userInfo) {
            if (userInfo) {
                this._currentUserInfo = userInfo;
                this.isSetAdditional();
            }
        },
        enumerable: true,
        configurable: true
    });
    ;
    AdditionalUserInfoComponent.prototype.ngOnInit = function () {
        // this.setAdditionalInfo();
    };
    AdditionalUserInfoComponent.prototype.isSetAdditional = function () {
        if (this._currentUser && this._currentRole && this._currentUserInfo) {
            var additionalObjName = Object.getOwnPropertyNames(this.additionalInfo);
            for (var i in additionalObjName) {
                this.additionalInfo[additionalObjName[i]].name = null;
                this.additionalInfo[additionalObjName[i]].img = null;
            }
            this.setAdditionalInfo();
        }
    };
    AdditionalUserInfoComponent.prototype.setAdditionalInfo = function () {
        var levelDetail = this._currentUserInfo.levelDetails;
        if (this._currentRole && this._currentUser && this._currentRole.indexOf('CLIENT') !== -1) {
            this.additionalInfo.left.name = this._currentUserInfo.lastAccessedName;
            this.additionalInfo.left.img = this.getAvatarUrl(this._currentUserInfo.lastAccessedImg);
            this.additionalInfo.left.title = "Last SSLG Staffer who worked on the record";
            this.additionalInfo.left.email = this._currentUserInfo.lastAccessedEmail;
            this.additionalInfo.left.phone = this._currentUserInfo.lastAccessedPhone;
        }
        else {
            this.additionalInfo.left.name = this._currentUserInfo.claimantName;
            this.additionalInfo.left.img = this.getAvatarUrl(this._currentUserInfo.claimantAvatar);
            this.additionalInfo.left.title = "";
            this.additionalInfo.left.email = this._currentUserInfo.claimantEmail;
            if (this._currentUserInfo.phone) {
                this.additionalInfo.left.phone = this._currentUserInfo.phone;
            }
            else {
                this.additionalInfo.left.phone = this._currentUserInfo.claimantPhone;
            }
        }
        this.additionalInfo.middle.name = this._currentUserInfo.assignedToName;
        this.additionalInfo.middle.img = this.getAvatarUrl(this._currentUserInfo.assignedToAvatar);
        this.additionalInfo.middle.title = "SSLG Staffer Assigned to your case";
        this.additionalInfo.middle.phone = this._currentUserInfo.assignedToPhone;
        this.additionalInfo.middle.email = this._currentUserInfo.assignedToEmail;
        var statusAward = false;
        for (var i = 0; i < levelDetail.length; i++) {
            if (levelDetail[i].decision == 'AWARDED') {
                statusAward = true;
                break;
            }
        }
        if (statusAward) {
            this.additionalInfo.middle.name = this._currentUserInfo.postAwardCSRName;
            this.additionalInfo.middle.img = this.getAvatarUrl(this._currentUserInfo.postAwardCSRAvatar);
            this.additionalInfo.middle.title = "Post Award SSLG Staffer Assigned to your case";
            this.additionalInfo.middle.phone = this._currentUserInfo.postAwardCSRPhone;
            this.additionalInfo.middle.email = this._currentUserInfo.postAwardCSREmail;
        }
        this.additionalInfo.right.name = this._currentUserInfo.claimantManagerName;
        this.additionalInfo.right.img = this.getAvatarUrl(this._currentUserInfo.claimantManagerImg);
        this.additionalInfo.right.title = "LTD adjuster managed by";
        this.additionalInfo.right.directDial = this._currentUserInfo.managetDirectDial;
        this.additionalInfo.right.phone = this._currentUserInfo.managerPhone;
        this.additionalInfo.right.email = this._currentUserInfo.managerEmail;
    };
    AdditionalUserInfoComponent.prototype.getAvatarUrl = function (id) {
        if (this._currentUser) {
            var url = '/api/user/avatar/' + id;
            if (environment.production) {
                return window.location.origin + url;
            }
            else {
                return 'https://sslg.dev.iriskeysolutions.com' + url;
            }
        }
    };
    AdditionalUserInfoComponent.prototype.isStaff = function () {
        return this.globalSaveService.isStaff();
    };
    return AdditionalUserInfoComponent;
}());
export { AdditionalUserInfoComponent };
