import * as i0 from "@angular/core";
var DataSortService = /** @class */ (function () {
    function DataSortService() {
    }
    DataSortService.prototype.sortAlphabeticallyByKey = function (keyName, additionKey) {
        return function (a, b) {
            if (a[keyName] > b[keyName]) {
                return 1;
            }
            if (b[keyName] > a[keyName]) {
                return -1;
            }
            if (additionKey) {
                if (a[additionKey] > b[additionKey]) {
                    return 1;
                }
                if (b[additionKey] > a[additionKey]) {
                    return -1;
                }
                return 0;
            }
            return 0;
        };
    };
    DataSortService.prototype.alphabeticalCompareSelectFunc = function (a, b) {
        if (a.label.toLowerCase() < b.label.toLowerCase())
            return -1;
        if (a.label.toLowerCase() > b.label.toLowerCase())
            return 1;
        return 0;
    };
    DataSortService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DataSortService_Factory() { return new DataSortService(); }, token: DataSortService, providedIn: "root" });
    return DataSortService;
}());
export { DataSortService };
