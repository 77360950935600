import { ElementRef, OnInit, QueryList } from '@angular/core';
import { IrisTableTabsService } from "../../../app/@core/iris-table-tabs.service";
import { FIELD_TYPES } from "../../../app/@config/prospects/fieldTypes.config";
import { DATA_MASKS } from "../../../app/@config/dataMasks.config";
import { FormBuilder, FormGroup } from "@angular/forms";
import { DateService } from "../../../app/@core/functions/date.service";
import { PromotionsService } from "../../../app/@core/promotions.service";
import { LoadingService } from "../../loading/loading.service";
import { PERMANENT_TABS_ID } from "../../../app/@config/prospects/permanentTabs";
import { ActivatedRoute, Router } from "@angular/router";
var IrisTableTabsComponent = /** @class */ (function () {
    function IrisTableTabsComponent(irisTableTabsService, formBuilder, promotionsService, loadingService, router, route, dateService) {
        var _this = this;
        this.irisTableTabsService = irisTableTabsService;
        this.formBuilder = formBuilder;
        this.promotionsService = promotionsService;
        this.loadingService = loadingService;
        this.router = router;
        this.route = route;
        this.dateService = dateService;
        this.activeUserId = null;
        this.questionsForm = FormGroup;
        this.activeId = null;
        this.requestedId = null;
        this.groupEnd = false;
        this.DATA_MASKS = DATA_MASKS;
        this.MESSAGING_TAB_ID = PERMANENT_TABS_ID.messaging;
        this.content = [];
        this.initialData = [];
        this.changes = [];
        if (this.router.url.indexOf('other') === -1) {
            this.irisTableTabsService.content.subscribe(function (val) {
                if (val === 'reset' && _this.activeUserId !== _this.userId) {
                    _this.content = [];
                    _this.initialData = [];
                    _this.changes = [];
                }
                else if (val && val.result) {
                    _this.activeUserId = _this.userId;
                    _this.activeId = _this.requestedId;
                    _this.content = [];
                    _this.initialData = val.result;
                    _this.changes = [];
                    _this.groupEnd = false;
                    _this.updateContent(val.result);
                    _this.requestedId = null;
                }
            });
        }
    }
    Object.defineProperty(IrisTableTabsComponent.prototype, "tabs", {
        get: function () {
            return this._tabs;
        },
        set: function (value) {
            this._tabs = value;
            if (value !== null) {
                if (this.route.snapshot.queryParams.messaging) {
                    this.changeActiveTab(this.MESSAGING_TAB_ID);
                }
                else {
                    this.changeActiveTab(value[0].id);
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    IrisTableTabsComponent.prototype.updateContent = function (data) {
        this.content = [];
        this.groupEnd = false;
        var dataArray = data ? data : this.initialData;
        if (this.activeId === this.MESSAGING_TAB_ID) {
            this.content = dataArray;
        }
        else {
            this.setDynamicTabsContent(dataArray);
        }
    };
    IrisTableTabsComponent.prototype.setDynamicTabsContent = function (dataArray) {
        var _this = this;
        dataArray.forEach(function (el) {
            var answer = el;
            if (!_this.groupEnd) {
                _this.displayChoicesQuestion(el);
                if (el.fieldType === FIELD_TYPES.text || el.fieldType === FIELD_TYPES.textMessage || el.fieldType === FIELD_TYPES.textarea ||
                    el.fieldType === FIELD_TYPES.number || el.fieldType === FIELD_TYPES.phone ||
                    el.fieldType === FIELD_TYPES.dollar) {
                    _this.content.push(el);
                    if (!el.answer && el.fieldType !== FIELD_TYPES.textMessage) {
                        _this.groupEnd = true;
                    }
                }
                if (el.fieldType === FIELD_TYPES.textList || el.fieldType === FIELD_TYPES.textListTwo) {
                    if (!el.answerList) {
                        el.answerList = [{ id: 'new', answer_id: 'new', title: '', tempId: 1 }];
                    }
                    _this.content.push(el);
                    if (!el.answer || !el.answerList.length) {
                        _this.groupEnd = true;
                    }
                }
            }
        });
        // console.log(this.initialData, '1', this.content, '2');
    };
    IrisTableTabsComponent.prototype.displayChoicesQuestion = function (el) {
        var _this = this;
        if (el.fieldType === FIELD_TYPES.select || el.fieldType === FIELD_TYPES.radio) {
            var itemAnswer = el.choices.find(function (choice) { return +choice.value === +el.answer; });
            this.content.push(Object.assign({}, el));
            if (itemAnswer) {
                if (itemAnswer.groupEnd) {
                    this.groupEnd = true;
                }
                if (itemAnswer.newElement) {
                    var newElement = Object.assign({}, itemAnswer.newElement);
                    newElement.questionId = el.questionId;
                    newElement.questionTitle = newElement.title;
                    newElement.answer = el.answerElement ? el.answerElement : null;
                    this.content.push(newElement);
                    if (!el.answerElement) {
                        this.groupEnd = true;
                    }
                }
                if (itemAnswer.newElements) {
                    itemAnswer.newElements.forEach(function (newEl) {
                        if (!_this.groupEnd) {
                            var newElement = Object.assign({}, newEl);
                            newElement.questionId = el.questionId;
                            newElement.questionTitle = newEl.title;
                            newElement.answer = newEl.answer ? newEl.answer : '';
                            newElement.isNewElementsItem = true;
                            _this.content.push(newElement);
                            if (!newEl.answer) {
                                _this.groupEnd = true;
                            }
                        }
                    });
                }
            }
            else {
                this.groupEnd = true;
            }
        }
    };
    IrisTableTabsComponent.prototype.addItemToContent = function (question, answer, type) {
        this.content.push({
            question: question,
            answer: answer,
            type: type
        });
    };
    IrisTableTabsComponent.prototype.ngOnInit = function () {
    };
    IrisTableTabsComponent.prototype.changeActiveTab = function (categoryId) {
        this.requestedId = categoryId;
        this.irisTableTabsService.requestContent(this.userId, categoryId);
    };
    IrisTableTabsComponent.prototype.isDollarType = function (fieldType) {
        return fieldType === FIELD_TYPES.dollar;
    };
    IrisTableTabsComponent.prototype.isPhoneType = function (fieldType) {
        return fieldType === FIELD_TYPES.phone;
    };
    IrisTableTabsComponent.prototype.isDateType = function (fieldType) {
        return fieldType === FIELD_TYPES.date || fieldType === FIELD_TYPES.yearDate;
    };
    IrisTableTabsComponent.prototype.isNumberType = function (fieldType) {
        return fieldType === FIELD_TYPES.number;
    };
    IrisTableTabsComponent.prototype.isTextType = function (fieldType) {
        return fieldType === FIELD_TYPES.text;
    };
    IrisTableTabsComponent.prototype.isTextareaType = function (fieldType) {
        return fieldType === FIELD_TYPES.textarea;
    };
    IrisTableTabsComponent.prototype.isRadioType = function (fieldType) {
        return fieldType === FIELD_TYPES.radio;
    };
    IrisTableTabsComponent.prototype.isCheckBoxType = function (fieldType) {
        return fieldType === FIELD_TYPES.checkbox;
    };
    IrisTableTabsComponent.prototype.isSelectType = function (fieldType) {
        return fieldType === FIELD_TYPES.select;
    };
    IrisTableTabsComponent.prototype.isTextListType = function (fieldType) {
        return fieldType === FIELD_TYPES.textList || fieldType === FIELD_TYPES.textListTwo;
    };
    IrisTableTabsComponent.prototype.isTextListTwoType = function (fieldType) {
        return fieldType === FIELD_TYPES.textListTwo;
    };
    IrisTableTabsComponent.prototype.getPlaceHolder = function (item) {
        if (item.fieldType === FIELD_TYPES.textListTwo) {
            if (item.answerList.length % 2 === 0)
                return 'Industry';
            else
                return 'Job Title';
        }
        else {
            return '';
        }
    };
    IrisTableTabsComponent.prototype.showText = function (item) {
        var add = 'Add';
        if (item.fieldType === FIELD_TYPES.textListTwo) {
            if (item.answerList.length % 2 === 0)
                return add + ' Job Title';
            else
                return add + ' Industry';
        }
        else {
            return add;
        }
    };
    IrisTableTabsComponent.prototype.isTextMessageType = function (fieldType) {
        return fieldType === FIELD_TYPES.textMessage;
    };
    IrisTableTabsComponent.prototype.removeAnswerListItem = function (item, answerId, tempId) {
        var answerList = this.content.find(function (el) { return el.questionId === item.questionId; }).answerList;
        var newAnswer = answerList.filter(function (listItem) { return (String(listItem.id) !== String(answerId) && !listItem.tempId) || (String(listItem.tempId) !== String(tempId) && listItem.tempId); });
        if (newAnswer.length) {
            this.content.find(function (el) { return el.questionId === item.questionId; }).answerList = newAnswer;
        }
        else {
            this.content.find(function (el) { return el.questionId === item.questionId; }).answerList = [{ id: 'new', tempId: 1, answer_id: answerList[0].answer_id, title: '' }];
            this.initialData.find(function (el) { return el.questionId === item.questionId; }).answer = '';
        }
        this.updateContent();
    };
    IrisTableTabsComponent.prototype.onAnswerListItemInputChange = function (event, item, answer) {
        var value = event.target.value;
        if (value) {
            this.initialData.find(function (el) { return String(el.questionId) === String(item.questionId); }).answer = 'done';
            this.content.find(function (el) { return el.questionId === item.questionId; }).answer = 'done';
        }
        this.content.find(function (el) { return el.questionId === item.questionId; }).answerList.find(function (listItem) { return (String(listItem.id) === String(answer.id) && !listItem.tempId) || (String(listItem.tempId) === String(answer.tempId) && listItem.tempId); }).title = value;
        this.updateContent();
    };
    IrisTableTabsComponent.prototype.addAnswerListItem = function (item) {
        var tempId = 1;
        var answerList = this.content.find(function (el) { return el.questionId === item.questionId; }).answerList;
        this.content.find(function (el) { return el.questionId === item.questionId; }).answerList.filter(function (el) { return el.id === 'new'; }).forEach(function (el) {
            if (el.tempId >= tempId) {
                tempId = el.tempId + 1;
            }
        });
        var labelText = answerList.length % 2 === 0 ? 'Job Title' : 'Industry';
        this.content.find(function (el) { return el.questionId === item.questionId; }).answerList.push({ id: 'new', tempId: tempId, answer_id: answerList[0].answer_id, title: '', label: labelText });
    };
    IrisTableTabsComponent.prototype.disableAddAnswerListItem = function (questionId) {
        var result = false;
        if (this.answerListItemInput) {
            this.answerListItemInput.forEach(function (el) {
                if (+el.nativeElement.getAttribute('data-question-id') === +questionId) {
                    if (!el.nativeElement.value || !el.nativeElement.value.trim()) {
                        result = true;
                    }
                }
            });
        }
        return result;
    };
    IrisTableTabsComponent.prototype.identifyQuestion = function (index, item) {
        return item.questionId;
    };
    IrisTableTabsComponent.prototype.identifyAnswer = function (index, item) {
        return item.id;
    };
    IrisTableTabsComponent.prototype.onSelectChange = function ($event, item) {
        var value = $event.value.value;
        this.setNewValue(item, value);
    };
    IrisTableTabsComponent.prototype.getSelectValue = function (item) {
        var answer = item.choices.find(function (choice) { return +choice.value === +item.answer; });
        return answer;
    };
    IrisTableTabsComponent.prototype.saveChanges = function () {
        var _this = this;
        var sendChanges = [];
        var userId = this.userId;
        var categoryId = this.activeId;
        this.initialData.forEach(function (el) {
            if (el.fieldType === FIELD_TYPES.textList) {
                var noTempAnswer_1 = [];
                el.answerList.forEach(function (answer) {
                    if (answer.title) {
                        noTempAnswer_1.push(Object.assign({}, answer));
                    }
                });
                noTempAnswer_1.forEach(function (answer) {
                    delete answer.tempId;
                });
                var newEl = Object.assign({}, el);
                newEl.answerList = noTempAnswer_1;
                if (newEl.answerList.length) {
                    newEl.answer = 'done';
                }
                sendChanges.push(newEl);
            }
            else {
                sendChanges.push(el);
            }
        });
        // console.log(sendChanges, this.initialData, this.content);
        this.promotionsService.updateChatAnswers(userId, categoryId, sendChanges).subscribe(function (data) {
            _this.loadingService.showError([data['message']], true);
            if (data['status']) {
                _this.changeActiveTab(categoryId);
            }
        });
    };
    IrisTableTabsComponent.prototype.isRadioChecked = function (item, option) {
        return +item.answer === +option.value;
    };
    IrisTableTabsComponent.prototype.datePick = function ($event, item) {
        var newAnswer;
        if ($event) {
            var newDate = new Date($event);
            newAnswer = item.fieldType === FIELD_TYPES.yearDate ? newDate.getMonth() + 1 + "/" + newDate.getFullYear() : newDate.getMonth() + 1 + "/" + newDate.getDate() + "/" + newDate.getFullYear();
        }
        else {
            newAnswer = '';
        }
        this.content.find(function (el) { return (+el.questionId === +item.questionId) && (!item.optionId || (item.optionId && +el.optionId === +item.optionId)); }).answer = newAnswer;
        this.setNewValue(item, newAnswer);
    };
    IrisTableTabsComponent.prototype.getNumberMask = function (length) {
        var mask = '0';
        for (var i = 1; i < length; i++) {
            mask += '0';
        }
        return mask;
    };
    IrisTableTabsComponent.prototype.onRadioChange = function (event, item) {
        var value = event.value;
        this.setNewValue(item, value);
    };
    IrisTableTabsComponent.prototype.onInputChange = function (event, item) {
        var value = event.target.value;
        if (value[0] === '$') {
            value = value.slice(1);
        }
        this.setNewValue(item, value);
    };
    IrisTableTabsComponent.prototype.setNewValue = function (item, value) {
        if (item.isNewElementsItem) {
            var answer_1 = this.initialData.find(function (question) { return question.questionId === item.questionId; }).answer;
            this.initialData.find(function (question) { return question.questionId === item.questionId; }).choices.find(function (choise) { return +choise.value === +answer_1; }).newElements.find(function (newEl) { return newEl.optionId === item.optionId; }).answer = value;
            // console.log(this.initialData, value);
        }
        else if (item.title && item.questionTitle) {
            this.initialData.find(function (question) { return question.questionId === item.questionId; }).answerElement = value;
        }
        else {
            this.initialData.find(function (question) { return question.questionId === item.questionId; }).answer = value;
        }
        this.updateContent();
    };
    IrisTableTabsComponent.prototype.isMessagingTab = function () {
        return this.activeId === this.MESSAGING_TAB_ID;
    };
    IrisTableTabsComponent.prototype.addMessage = function () {
        var returnUrl = this.getUrl();
        this.router.navigate(['/prospects/initial/new'], { queryParams: { returnUrl: returnUrl, id: this.promotionsService.activeUser } });
    };
    IrisTableTabsComponent.prototype.getUrl = function () {
        return this.router.url.indexOf('?') !== -1 ?
            this.router.url.substring(0, this.router.url.indexOf('?')) : this.router.url;
    };
    return IrisTableTabsComponent;
}());
export { IrisTableTabsComponent };
