import { PROMOTIONS_CONFIG } from '../@config/api';
// import {Promotion} from "../@models/promotions/promotion.model";
import { HttpClient } from '@angular/common/http';
import { Router } from "@angular/router";
import { PROMOTIONS_CHAT_TYPES } from "../@view/pages/promotions/promotions-list/pronotions-types.model";
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/common/http";
var PromotionsService = /** @class */ (function () {
    function PromotionsService(router, http) {
        this.router = router;
        this.http = http;
        this.config = PROMOTIONS_CONFIG;
        this.newClaimantInSessionStorage = 'prospectsNewClaimant';
        this.newClaimantPositionInSessionStorage = 'prospectsNewClaimantPosition';
        this.paginationPageActive = 0;
        this.paginationPageSize = 6;
        this.activeUser = null;
    }
    PromotionsService.prototype.getPromotionsList = function (page, status, items) {
        var kind = this.router.url.indexOf(PROMOTIONS_CHAT_TYPES.initial) >= 0 ? PROMOTIONS_CHAT_TYPES.application : PROMOTIONS_CHAT_TYPES.appeal;
        var type = 0;
        if (this.router.url.indexOf(PROMOTIONS_CHAT_TYPES.other) >= 0) {
            kind = PROMOTIONS_CHAT_TYPES.other;
            this.router.url.indexOf('something-else') >= 0 ? type = 0 : type = 1;
        }
        return this.http.post(this.config.getList, { page: page, status: status, items: items, kind: kind, type: type });
    };
    PromotionsService.prototype.getPromotionsCategories = function (kind) {
        return this.http.post(this.config.getCategories, { kind: kind });
    };
    PromotionsService.prototype.getPromotionsCategory = function (userId, categoryId) {
        return this.http.post(this.config.getCategory, { userId: userId, categoryId: categoryId });
    };
    PromotionsService.prototype.changeStatus = function (id, status) {
        return this.http.post(this.config.changeStatus, { id: id, status: status });
    };
    PromotionsService.prototype.deleteProspects = function (userId, type) {
        return this.http.post(this.config.deleteProspect, { userId: userId, type: type });
    };
    PromotionsService.prototype.claimantWasCreated = function (id) {
        return this.http.post(this.config.claimantCreated, { id: id });
    };
    PromotionsService.prototype.updateChatAnswers = function (userId, categoryId, data) {
        return this.http.post(this.config.updateChatAnswers, { userId: userId, categoryId: categoryId, answers: JSON.stringify(data) });
    };
    PromotionsService.prototype.createNewClaimant = function (info, pagination, page) {
        var newClaimantPosition = {
            pagination: pagination,
            page: page
        };
        sessionStorage.setItem(this.newClaimantPositionInSessionStorage, JSON.stringify(newClaimantPosition));
        sessionStorage.setItem(this.newClaimantInSessionStorage, JSON.stringify(info));
        this.router.navigate(['/claimants/new/claimant'], { queryParams: { from: 'prospects' } });
    };
    PromotionsService.prototype.getNewClaimantInfo = function () {
        return sessionStorage.getItem(this.newClaimantInSessionStorage);
    };
    PromotionsService.prototype.getNewClaimantPosition = function () {
        return sessionStorage.getItem(this.newClaimantPositionInSessionStorage);
    };
    PromotionsService.prototype.sendMessage = function (data) {
        return this.http.post(this.config.sendMessage, data);
    };
    PromotionsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PromotionsService_Factory() { return new PromotionsService(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.HttpClient)); }, token: PromotionsService, providedIn: "root" });
    return PromotionsService;
}());
export { PromotionsService };
