import * as tslib_1 from "tslib";
import { EventEmitter } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { LabelOptions } from "@angular/material/core";
import { MatDatepicker } from "@angular/material/datepicker";
import { MatInput } from "@angular/material/input";
import { DynamicDatePickerModel, DynamicFormControlComponent, DynamicFormLayoutService, DynamicFormValidationService } from "@ng-dynamic-forms/core";
var DynamicMaterialDatePickerComponent = /** @class */ (function (_super) {
    tslib_1.__extends(DynamicMaterialDatePickerComponent, _super);
    function DynamicMaterialDatePickerComponent(layoutService, validationService, LABEL_OPTIONS) {
        var _this = _super.call(this, layoutService, validationService) || this;
        _this.layoutService = layoutService;
        _this.validationService = validationService;
        _this.LABEL_OPTIONS = LABEL_OPTIONS;
        _this.blur = new EventEmitter();
        _this.change = new EventEmitter();
        _this.customEvent = new EventEmitter();
        _this.focus = new EventEmitter();
        _this.date = new Date();
        return _this;
    }
    DynamicMaterialDatePickerComponent.prototype.clearDate = function () {
        this.model.valueUpdates.next(null);
        _super.prototype.onChange.call(this, {});
    };
    DynamicMaterialDatePickerComponent.prototype.onChange = function (event) {
        _super.prototype.onChange.call(this, event);
    };
    return DynamicMaterialDatePickerComponent;
}(DynamicFormControlComponent));
export { DynamicMaterialDatePickerComponent };
