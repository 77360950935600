import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { MatCheckbox } from "@angular/material/checkbox";
import { RippleGlobalOptions } from "@angular/material/core";
import { MatDatepicker } from "@angular/material/datepicker";
import { DynamicFormControlComponent, DynamicFormLayoutService, DynamicFormValidationService, } from "@ng-dynamic-forms/core";
import { DatepickerCheckboxModel } from "./datepicker-checkbox-model";
import { GlobalSaveService } from "../../../app/@core/global-save.service";
var DatepickerCheckboxComponent = /** @class */ (function (_super) {
    tslib_1.__extends(DatepickerCheckboxComponent, _super);
    function DatepickerCheckboxComponent(layoutService, validationService, globalSaveService, RIPPLE_OPTIONS) {
        var _this = _super.call(this, layoutService, validationService) || this;
        _this.layoutService = layoutService;
        _this.validationService = validationService;
        _this.globalSaveService = globalSaveService;
        _this.RIPPLE_OPTIONS = RIPPLE_OPTIONS;
        _this.blur = new EventEmitter();
        _this.change = new EventEmitter();
        _this.customEvent = new EventEmitter();
        _this.focus = new EventEmitter();
        _this.checkboxDisabled = false;
        return _this;
    }
    DatepickerCheckboxComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.matDatePicker.closedStream.subscribe(function (j) {
            if (!_this.matDatePicker._validSelected) {
                _this.matCheckbox.checked = false;
                _this.model.valueUpdates.next(null);
            }
            else {
                _this.model.valueUpdates.next(_this.matDatePicker._validSelected);
            }
        });
        this.model.valueUpdates.subscribe(function (j) {
            _this.matCheckbox.checked = (_this.model && _this.model.value);
        });
    };
    DatepickerCheckboxComponent.prototype.changed = function ($event) {
        _super.prototype.onChange.call(this, $event);
        if ($event.checked)
            this.matDatePicker.open();
        else
            this.model.valueUpdates.next(null);
    };
    DatepickerCheckboxComponent.prototype.isChecked = function () {
        return this.model.value !== undefined && this.model.value !== null;
    };
    DatepickerCheckboxComponent.prototype.onChange = function (event) {
    };
    DatepickerCheckboxComponent.prototype.onClosed = function (event) {
        _super.prototype.onChange.call(this, event);
    };
    DatepickerCheckboxComponent.prototype.checkGsStatus = function () {
        this.checkboxDisabled = this.globalSaveService.getStatusFalse();
        return true;
    };
    return DatepickerCheckboxComponent;
}(DynamicFormControlComponent));
export { DatepickerCheckboxComponent };
