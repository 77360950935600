import * as tslib_1 from "tslib";
import { EventEmitter, QueryList } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { DynamicFormComponent, DynamicFormLayoutService, DynamicFormService, DynamicTemplateDirective, } from "@ng-dynamic-forms/core";
import { DynamicMaterialFormControlContainerComponent } from "./dynamic-material-form-control-container.component";
var DynamicMaterialFormComponent = /** @class */ (function (_super) {
    tslib_1.__extends(DynamicMaterialFormComponent, _super);
    function DynamicMaterialFormComponent(formService, layoutService) {
        var _this = _super.call(this, formService, layoutService) || this;
        _this.formService = formService;
        _this.layoutService = layoutService;
        _this.blur = new EventEmitter();
        _this.change = new EventEmitter();
        _this.focus = new EventEmitter();
        _this.customEvent = new EventEmitter();
        return _this;
    }
    return DynamicMaterialFormComponent;
}(DynamicFormComponent));
export { DynamicMaterialFormComponent };
