import { HttpClient, HttpRequest } from '@angular/common/http';
import { CODES_DICTIONARY_CONFIG } from '../@config/api';
import { FileUploadingService } from '../../@util/file-uploading-progress/file-uploading.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../@util/file-uploading-progress/file-uploading.service";
var CodeDictionaryService = /** @class */ (function () {
    function CodeDictionaryService(http, fileService) {
        this.http = http;
        this.fileService = fileService;
        this.config = CODES_DICTIONARY_CONFIG;
    }
    CodeDictionaryService.prototype.getFollowUpsCodesByType = function (claimantLevelType) {
        return this.http.get(this.config.FOLLOW_UPS.getCodesByType(claimantLevelType));
    };
    CodeDictionaryService.prototype.getFollowUpsCodes = function () {
        return this.http.get(this.config.FOLLOW_UPS.getCodes);
    };
    CodeDictionaryService.prototype.addFollowUpsCode = function (data) {
        return this.http.post(this.config.FOLLOW_UPS.addCode, data);
    };
    CodeDictionaryService.prototype.getFollowUpsCode = function (id) {
        return this.http.get(this.config.FOLLOW_UPS.getCode(id));
    };
    CodeDictionaryService.prototype.getAvailableFollowUpCodes = function (type) {
        return this.http.get(this.config.FOLLOW_UPS.getAvailable(type));
    };
    CodeDictionaryService.prototype.getProspectsCode = function (id) {
        return this.http.get(this.config.PROSPECTS_TYPE.getCode(id));
    };
    CodeDictionaryService.prototype.getProspectsCodes = function () {
        return this.http.get(this.config.PROSPECTS_TYPE.getCodes);
    };
    CodeDictionaryService.prototype.addProspectsCode = function (data) {
        return this.http.post(this.config.PROSPECTS_TYPE.addCode, data);
    };
    CodeDictionaryService.prototype.getImpairmentsCodes = function () {
        return this.http.get(this.config.IMPAIRMENT.getCodes);
    };
    CodeDictionaryService.prototype.addImpairmentsCode = function (data) {
        return this.http.post(this.config.IMPAIRMENT.addCode, data);
    };
    CodeDictionaryService.prototype.getImpairmentsCode = function (id) {
        return this.http.get(this.config.IMPAIRMENT.getCode(id));
    };
    CodeDictionaryService.prototype.importImpairmentsCodeFromFile = function (selectedFile, splitter) {
        var formData = new FormData();
        if (selectedFile === undefined || selectedFile === null) {
            return;
        }
        formData.append('file', selectedFile);
        formData.append('splitter', splitter);
        var req = new HttpRequest('POST', this.config.IMPAIRMENT.fromFile, formData, {
            reportProgress: true,
            responseType: 'text'
        });
        return this.http.request(req);
    };
    CodeDictionaryService.prototype.getReferralTypeCodes = function () {
        return this.http.get(this.config.REFERRAL_TYPE.getCodes);
    };
    CodeDictionaryService.prototype.addReferralTypeCode = function (data) {
        return this.http.post(this.config.REFERRAL_TYPE.addCode, data);
    };
    CodeDictionaryService.prototype.getReferralTypeCode = function (id) {
        return this.http.get(this.config.REFERRAL_TYPE.getCode(id));
    };
    CodeDictionaryService.prototype.findImairamentCodeLike = function (value) {
        return this.http.get(this.config.IMPAIRMENT.findLike(value));
    };
    CodeDictionaryService.prototype.findImairamentCodeByDescription = function (value) {
        return this.http.get(this.config.IMPAIRMENT.findByDescription(value));
    };
    CodeDictionaryService.prototype.getPostAwardCodes = function () {
        return this.http.get(this.config.POST_AWARD.getCodes);
    };
    CodeDictionaryService.prototype.getPostAwardCode = function (id) {
        return this.http.get(this.config.POST_AWARD.getCode(id));
    };
    CodeDictionaryService.prototype.addPostAwardCode = function (value) {
        return this.http.post(this.config.POST_AWARD.addCode, value);
    };
    CodeDictionaryService.prototype.getFormsSetCodes = function () {
        return this.http.get(this.config.FORMS_SET.getCodes);
    };
    CodeDictionaryService.prototype.getFormsSetCode = function (id) {
        return this.http.get(this.config.FORMS_SET.getCode(id));
    };
    CodeDictionaryService.prototype.addFormsSetCode = function (value) {
        return this.http.post(this.config.FORMS_SET.addCode, value);
    };
    CodeDictionaryService.prototype.getPolicyholderCodes = function () {
        return this.http.get(this.config.POLICYHOLDER.getCodes);
    };
    CodeDictionaryService.prototype.getPolicyholderCode = function (id) {
        return this.http.get(this.config.POLICYHOLDER.getCode(id));
    };
    CodeDictionaryService.prototype.addPolicyholderCode = function (value) {
        return this.http.post(this.config.POLICYHOLDER.addCode, value);
    };
    CodeDictionaryService.prototype.getClosedCodes = function () {
        return this.http.get(this.config.CLOSED.getCodes);
    };
    CodeDictionaryService.prototype.getClosedCode = function (id) {
        return this.http.get(this.config.CLOSED.getCode(id));
    };
    CodeDictionaryService.prototype.getAvailableClosedCodes = function (type) {
        return this.http.get(this.config.CLOSED.getAvalibleCode(type), { responseType: 'text' });
    };
    CodeDictionaryService.prototype.getClosedCodeTypes = function () {
        return this.http.get(this.config.CLOSED.getTypes);
    };
    CodeDictionaryService.prototype.addClosedCode = function (data) {
        return this.http.post(this.config.CLOSED.addCode, data);
    };
    CodeDictionaryService.prototype.isClosedCodeExist = function (newCode) {
        return this.http.get(this.config.CLOSED.isExist(newCode), { responseType: 'text' });
    };
    CodeDictionaryService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CodeDictionaryService_Factory() { return new CodeDictionaryService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.FileUploadingService)); }, token: CodeDictionaryService, providedIn: "root" });
    return CodeDictionaryService;
}());
export { CodeDictionaryService };
