import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { LoadingService } from 'src/@util/loading/loading.service';
import { ReferralService } from '../../@core/referral.service';
import { ActivatedRoute } from "@angular/router";
import { MatDialog } from "@angular/material";
var ConsolidatorReportListComponent = /** @class */ (function () {
    function ConsolidatorReportListComponent(referralService, loading, route, dialog) {
        this.referralService = referralService;
        this.loading = loading;
        this.route = route;
        this.dialog = dialog;
        this.displayedColumns = ['Last Name', 'First Name', 'Status', 'Phone', 'IC Examiner', 'Case ID', 'Direct Dial'];
        this.referralService.setCurrent(null);
    }
    ConsolidatorReportListComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.paramMap.subscribe(function (j) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.initDataSource();
                return [2 /*return*/];
            });
        }); });
    };
    ConsolidatorReportListComponent.prototype.initDataSource = function () {
        var _this = this;
        this.referralService.getConsolidator().subscribe(function (data) {
            _this.claiments = data.sort(function (a, b) {
                if (a.lastName < b.lastName) {
                    return -1;
                }
                if (a.lastName > b.lastName) {
                    return 1;
                }
                return 0;
            });
            _this.dataSource = _this.claiments;
            _this.loading.loaded();
        });
    };
    ConsolidatorReportListComponent.prototype.print = function () {
        window.print();
    };
    return ConsolidatorReportListComponent;
}());
export { ConsolidatorReportListComponent };
