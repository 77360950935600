import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { DiariesService } from "../../../../../../@core/diaries.service";
import { CompleteFollowUpComponent } from '../../../../../pages/claimants/follow-ups/pending/complet-follow-up/complete-follow-up.component';
import { ReferralService } from "../../../../../../@core/referral.service";
import { DateService } from "../../../../../../@core/date.service";
import { GlobalSaveService } from '../../../../../../@core/global-save.service';
import { DATE_FORMAT } from "../../../../../../@config/dateFormat";
import { MatTableDataSource } from '@angular/material/table';
import { PERMISSIONS } from 'src/app/@config/permissions';
var DiariesComponent = /** @class */ (function () {
    function DiariesComponent(router, route, diariesService, dialog, referralService, dateService, globalSaveService) {
        this.router = router;
        this.route = route;
        this.diariesService = diariesService;
        this.dialog = dialog;
        this.referralService = referralService;
        this.dateService = dateService;
        this.globalSaveService = globalSaveService;
        this.DATE_FORMAT = DATE_FORMAT;
        this.title = "Diaries";
        this.diaries = [];
        this.overDueDiaries = [];
        this.followUpResult = [];
        this.canScrollLeft = false;
        this.canScrollRight = true;
        this.PERMISSIONS = PERMISSIONS;
        this.dataSource = new MatTableDataSource([]);
    }
    DiariesComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.dataSource.paginator = this.paginator;
                this.loadDiaries();
                return [2 /*return*/];
            });
        });
    };
    DiariesComponent.prototype.loadDiaries = function () {
        var _this = this;
        this.route.paramMap.subscribe(function (j) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var typeRes;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                typeRes = 'pending';
                this.diariesService.getList(typeRes, this.username).subscribe(function (data) {
                    _this.diaries = JSON.parse(data.message);
                    _this.sortItemsArrayByZIndex();
                    _this.dataSource.data = _this.diaries;
                    _this.data = _this.dataSource.connect();
                    if (_this.route.snapshot.queryParamMap.get('page')) {
                        _this.setPageData(+_this.route.snapshot.queryParamMap.get('page'));
                    }
                });
                return [2 /*return*/];
            });
        }); });
    };
    DiariesComponent.prototype.edit = function (item) {
        var url = this.getUrl();
        this.router.navigate(["claimants/" + item.sslgId + "/follow-ups/pending/" + item.id + "/edit"], { queryParams: { returnUrl: url, page: this.paginator.pageIndex } });
    };
    DiariesComponent.prototype.toClaimantRecord = function (item) {
        var url = this.getUrl();
        this.router.navigate(["claimants/record/" + item.sslgId], { queryParams: { returnUrl: url, page: this.paginator.pageIndex } });
    };
    DiariesComponent.prototype.setComplete = function (item, $event) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var dialogRef, dialogRez;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        dialogRef = this.dialog.open(CompleteFollowUpComponent, {
                            position: {
                            // top: $event.pageY + 'px',
                            // left: 'calc(' + $event.pageX + 'px - 35%)'
                            },
                            data: item,
                            backdropClass: 'overlay',
                            panelClass: 'mobile-popup',
                            minWidth: '35%',
                            minHeight: '25%'
                        });
                        return [4 /*yield*/, dialogRef.afterClosed().toPromise()];
                    case 1:
                        dialogRez = _a.sent();
                        if (!dialogRez) return [3 /*break*/, 3];
                        item.completedDate = this.dateService.convertDateWithTimezone(dialogRez.completeDate);
                        item.completedBy = dialogRez.completedBy;
                        item.createDate = this.dateService.convertDateWithTimezone(item.createDate);
                        item.dueDate = this.dateService.convertDateWithTimezone(item.dueDate);
                        item.editedDate = this.dateService.convertDateWithTimezone(item.editedDate);
                        return [4 /*yield*/, this.referralService.setFollowUpCompleted(item).toPromise()];
                    case 2:
                        _a.sent();
                        this.loadDiaries();
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    DiariesComponent.prototype.getUrl = function () {
        return this.router.url.indexOf('?') !== -1 ?
            this.router.url.substring(0, this.router.url.indexOf('?')) : this.router.url;
    };
    DiariesComponent.prototype.setPageData = function (pageNum) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.paginator.pageIndex = pageNum;
                this.data.next(this.dataSource._pageData(this.diaries));
                return [2 /*return*/];
            });
        });
    };
    DiariesComponent.prototype.getClaimentName = function (data) {
        return data.claiment.firstName + ' ' + data.claiment.lastName;
    };
    DiariesComponent.prototype.getFollowUp = function (data) {
        return data.followUpResult;
    };
    DiariesComponent.prototype.getFollowUpDays = function (data) {
        var daysBetweenCompleted = data.daysBetweenCompleted;
        var daysBetweenCurrent = data.daysBetweenCurrent;
        var completedBy = data.completedBy;
        var days = daysBetweenCurrent;
        if (completedBy != null) {
            days = daysBetweenCompleted;
        }
        if (days < 0) {
            days = days * -1;
        }
        return days;
    };
    DiariesComponent.prototype.getStatusName = function (data) {
        return data.claiment.primaryStatus;
    };
    DiariesComponent.prototype.getStatus2Name = function (data) {
        return data.claiment.secondaryStatus;
    };
    DiariesComponent.prototype.sortItemsArrayByZIndex = function () {
        this.overDueDiaries = tslib_1.__spread(this.diaries);
        for (var i = 0; i < this.overDueDiaries.length; i++) {
            var followUp = this.overDueDiaries[i].followUp;
            var pendingDayMin = 0;
            var comletedDayMin = 0;
            var pending = [];
            var comleted = [];
            for (var p = 0; p < followUp.length; p++) {
                var completedBy = null;
                try {
                    completedBy = followUp[p].completedBy;
                }
                catch (e) { }
                var daysBetweenCurrent = 0;
                try {
                    daysBetweenCurrent = parseInt(followUp[p].daysBetweenCurrent);
                }
                catch (e) { }
                var daysBetweenCompleted = parseInt(followUp[p].daysBetweenCompleted);
                if (completedBy == null) {
                    pending.push(followUp[p]);
                    if (daysBetweenCurrent < pendingDayMin) {
                        pendingDayMin = daysBetweenCurrent;
                    }
                }
                else {
                    comleted.push(followUp[p]);
                    if (daysBetweenCompleted < comletedDayMin) {
                        comletedDayMin = daysBetweenCompleted;
                    }
                }
            }
            pending.sort(function (i1, i2) {
                return parseInt(i2.daysBetweenCurrent) - parseInt(i1.daysBetweenCurrent);
            });
            pending.reverse();
            comleted.sort(function (i1, i2) {
                return parseInt(i1.daysBetweenCompleted) - parseInt(i2.daysBetweenCompleted);
            });
            this.overDueDiaries[i].pendingDayMin = pendingDayMin;
            this.overDueDiaries[i].comletedDayMin = comletedDayMin;
            var merge = tslib_1.__spread(pending, comleted);
            var filtered = this.filterOverDueDiaries(merge);
            if (filtered.length) {
                this.overDueDiaries[i].followUpResult = filtered;
            }
            else {
                this.overDueDiaries.splice(i, 1);
                i = i - 1;
            }
        }
        this.overDueDiaries.sort(function (i1, i2) {
            return parseInt(i1.pendingDayMin) - parseInt(i2.pendingDayMin);
        });
        this.diaries = this.overDueDiaries;
    };
    DiariesComponent.prototype.getIndicatorColor = function (data) {
        var daysBetweenCompleted = data.daysBetweenCompleted;
        var daysBetweenCurrent = data.daysBetweenCurrent;
        var completedBy = data.completedBy;
        var days = daysBetweenCurrent;
        if (completedBy != null) {
            days = daysBetweenCompleted;
        }
        var indicatorColor;
        if (days < 0) {
            indicatorColor = 'red';
        }
        else if (days >= 0 && days <= 4) {
            indicatorColor = 'yellow';
        }
        else {
            indicatorColor = 'green';
        }
        return indicatorColor;
    };
    DiariesComponent.prototype.tableScrolled = function ($event) {
        var scrollLeft = $event.target.scrollLeft;
        var scrollWidth = $event.target.scrollWidth;
        var offsetWidth = $event.target.offsetWidth;
        if (scrollLeft > 0) {
            this.canScrollLeft = true;
        }
        else {
            this.canScrollLeft = false;
        }
        if (scrollWidth - scrollLeft > offsetWidth) {
            this.canScrollRight = true;
        }
        else {
            this.canScrollRight = false;
        }
    };
    DiariesComponent.prototype.getDate = function (date) {
        this.dateService.convertDateWithTimezone(date);
    };
    DiariesComponent.prototype.filterOverDueDiaries = function (data) {
        var _this = this;
        return data.filter(function (item) { return _this.getIndicatorColor(item) == 'red'; });
    };
    return DiariesComponent;
}());
export { DiariesComponent };
