import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ReferralService } from "../../../@core/referral.service";
var ReportHeaderComponent = /** @class */ (function () {
    function ReportHeaderComponent(referralService) {
        this.referralService = referralService;
    }
    ReportHeaderComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.referralService.getClaimantPdfInfo(this.sslgId).toPromise()];
                    case 1:
                        _a.currentUser = _b.sent();
                        console.log(this.currentUser);
                        return [2 /*return*/];
                }
            });
        });
    };
    ReportHeaderComponent.prototype.getUserPhone = function () {
        if (this.currentUser) {
            return this.currentUser.phone ? this.currentUser.phone : 'Not specified';
        }
        else {
            return '';
        }
    };
    ReportHeaderComponent.prototype.getUserEmail = function () {
        if (this.currentUser) {
            return this.currentUser.email ? this.currentUser.email : 'Not specified';
        }
        else {
            return '';
        }
    };
    ReportHeaderComponent.prototype.getIcdId = function (j, index) {
        if (this.currentUser.icdId.length == index + 1) {
            return j.code;
        }
        else {
            return j.code + ',';
        }
    };
    return ReportHeaderComponent;
}());
export { ReportHeaderComponent };
