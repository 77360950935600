import { POST_AWARD_CONFIG } from '../@config/api';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var CoraDetailsService = /** @class */ (function () {
    function CoraDetailsService(http) {
        this.http = http;
        this.config = POST_AWARD_CONFIG.cora_details;
    }
    CoraDetailsService.prototype.getPrimary = function (sslgId) {
        return this.http.get(this.config.primary(sslgId));
    };
    CoraDetailsService.prototype.setPrimary = function (sslgId, data) {
        return this.http.post(this.config.primary(sslgId), data);
    };
    CoraDetailsService.prototype.getDependent = function (sslgId) {
        return this.http.get(this.config.dependent(sslgId));
    };
    CoraDetailsService.prototype.setDependent = function (sslgId, data) {
        return this.http.post(this.config.dependent(sslgId), data);
    };
    CoraDetailsService.prototype.getEnrolment = function (sslgId) {
        return this.http.get(this.config.enrolment(sslgId));
    };
    CoraDetailsService.prototype.setEnrolment = function (sslgId, data) {
        return this.http.post(this.config.enrolment(sslgId), data);
    };
    CoraDetailsService.prototype.getSummary = function (sslgId) {
        return this.http.get(this.config.summary(sslgId));
    };
    CoraDetailsService.prototype.setSummary = function (sslgId, data) {
        return this.http.post(this.config.summary(sslgId), data);
    };
    CoraDetailsService.prototype.getMeters = function (sslgId) {
        return this.http.get(this.config.meters(sslgId));
    };
    CoraDetailsService.prototype.setMeters = function (sslgId, data) {
        return this.http.post(this.config.meters(sslgId), data);
    };
    CoraDetailsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CoraDetailsService_Factory() { return new CoraDetailsService(i0.ɵɵinject(i1.HttpClient)); }, token: CoraDetailsService, providedIn: "root" });
    return CoraDetailsService;
}());
export { CoraDetailsService };
