import * as tslib_1 from "tslib";
import { EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';
import { UserIdleService } from '../user-idle/user-idle.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthConfig } from './auth-config';
import { Subscription } from 'rxjs';
import { UserIdleConfig } from '../user-idle/user-idle-config';
import { AppStorage } from "../../storage";
import { GlobalSaveService } from "../../@core/global-save.service";
import { UserTempService } from "../../@core/userTemp.service";
import * as i0 from "@angular/core";
import * as i1 from "./auth-config";
import * as i2 from "@angular/common/http";
import * as i3 from "../user-idle/user-idle.service";
import * as i4 from "@angular/router";
import * as i5 from "../../@core/userTemp.service";
import * as i6 from "../../@core/global-save.service";
var AuthenticationService = /** @class */ (function () {
    function AuthenticationService(authConfig, http, userIdle, router, route, userTempService, globalSaveService) {
        this.authConfig = authConfig;
        this.http = http;
        this.userIdle = userIdle;
        this.router = router;
        this.route = route;
        this.userTempService = userTempService;
        this.globalSaveService = globalSaveService;
        this.storage = sessionStorage;
        this.rolesObservable = new EventEmitter();
    }
    // get userName() {
    //   return AppStorage.getStorage().getItem('user');
    // }
    AuthenticationService.prototype.sendUserName = function (username) {
        return this.http.post(this.authConfig.authUserNameUrl, { username: username });
    };
    AuthenticationService.prototype.login = function (username, password, pin, ignore) {
        var _this = this;
        return this.http.post(this.authConfig.authUrl, { username: username, password: password, pin: pin, ignore: ignore })
            .pipe(map(function (res) {
            // login successful if there's a jwt token in the response
            if (res && res.token) {
                _this.onStartWatching();
                _this.storage = sessionStorage;
                // store username and jwt token in local storage to keep user logged in between page refreshes
                _this.storage.setItem('currentUser', JSON.stringify({ token: res.token }));
                _this.storage.setItem('currentUserName', username);
                _this.storage.setItem('currentUserRole', res.role);
                _this.userTempService.setUserTempState(res);
                //this.storage.setItem('currentUserName', username);
                _this.storage.setItem('audioNotifiication', "0");
                _this.roles = [res.role];
                _this.rolesObservable.next(_this.roles);
                _this.globalSaveService.setLastClaimantData(res.globalSave, res.globalSaveLastClaimant, res.globalSaveLastClaimentFullName);
                if (UserIdleConfig.REMEMBER_ME) {
                    AppStorage.getStorage().setItem('user', username);
                }
                else {
                    AppStorage.getStorage().removeItem('user');
                }
            }
        }));
    };
    AuthenticationService.prototype.checkUser = function (email, hash) {
        return this.http.post(this.authConfig.checkUser, { email: email, hash: hash });
    };
    AuthenticationService.prototype.restore = function (email, hash, password) {
        return this.http.post(this.authConfig.restoreUrl, { email: email, hash: hash, password: password });
    };
    AuthenticationService.prototype.logout = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(this.router.url.indexOf(this.authConfig.loginRoute) === -1)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.router.navigate([this.authConfig.loginRoute])];
                    case 1:
                        _a.sent();
                        location.reload();
                        return [2 /*return*/];
                    case 2:
                        this.storage.removeItem('currentUser');
                        if (this.timerStartSubscription && !this.timerStartSubscription.closed) {
                            this.onStopWatching();
                            // window.location.reload();
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    AuthenticationService.prototype.refreshToken = function () {
        var _this = this;
        if (!this.storage.getItem('currentUser')) {
            return;
        }
        return this.http.post(this.authConfig.refreshTokenUrl, {}).subscribe(function (res) {
            _this.storage.removeItem('currentUser');
            if (res && res.token) {
                _this.storage.setItem('currentUser', JSON.stringify({ token: res.token }));
            }
        }, function (error) {
            _this.storage.removeItem('currentUser');
        });
    };
    AuthenticationService.prototype.checkSession = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var pathname, dir;
            return tslib_1.__generator(this, function (_a) {
                this.storage = sessionStorage;
                if (this.storage.getItem('currentUser')) {
                    try {
                        this.onStopWatching();
                    }
                    catch (e) {
                        ////console.log(e);
                    }
                    this.refreshToken();
                    this.onStartWatching();
                }
                else {
                    pathname = location.pathname;
                    dir = pathname.split('/');
                    if (this.authConfig.restoreRoute !== '/' + dir[1]) {
                        this.router.navigate([this.authConfig.loginRoute], {
                            queryParams: this.authConfig.loginRoute !== location.pathname &&
                                this.authConfig.restoreRoute !== location.pathname ?
                                {
                                    returnUrl: location.pathname
                                } : null
                        });
                    }
                }
                return [2 /*return*/];
            });
        });
    };
    AuthenticationService.prototype.onStartWatching = function () {
        var _this = this;
        if (this.userIdle.getConfigValue().idle === 0) {
            return;
        }
        // Start watching for user inactivity.
        this.userIdle.startWatching();
        // Start watching when user idle is starting.
        this.timerStartSubscription = this.userIdle.onTimerStart()
            .pipe(tap(function () { return _this.isTimer = true; }))
            .subscribe(function (count) {
            if (count == null && !_this.timeIsUp) {
                _this.isTimer = false;
            }
            else if (count == null) {
                _this.isTimer = false;
            }
        });
        // Start watch when time is followup.
        this.timeoutSubscription = this.userIdle.onTimeout()
            .subscribe(function (j) {
            _this.timeIsUp = true;
            _this.logout();
            ////console.log('time is followup.... LOGOUT', j);
        });
        this.pingSubscription = this.userIdle.ping$
            .subscribe(function (value) {
            if (!_this.isTimer && !_this.timeIsUp) {
                ////console.log('ping');
                _this.refreshToken();
            }
        });
    };
    AuthenticationService.prototype.onStopWatching = function () {
        if (this.userIdle.getConfigValue().idle === 0) {
            return;
        }
        this.userIdle.resetTimer();
        this.userIdle.stopWatching();
        this.isTimer = false;
        this.timeIsUp = false;
        this.lastPing = null;
        if (!this.timerStartSubscription) {
            return;
        }
        this.timerStartSubscription.unsubscribe();
        this.timeoutSubscription.unsubscribe();
        this.pingSubscription.unsubscribe();
    };
    AuthenticationService.prototype.isAuthorized = function () {
        if (this.storage.getItem('currentUser')) {
            return true;
        }
        return false;
    };
    AuthenticationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthenticationService_Factory() { return new AuthenticationService(i0.ɵɵinject(i1.AuthConfig), i0.ɵɵinject(i2.HttpClient), i0.ɵɵinject(i3.UserIdleService), i0.ɵɵinject(i4.Router), i0.ɵɵinject(i4.ActivatedRoute), i0.ɵɵinject(i5.UserTempService), i0.ɵɵinject(i6.GlobalSaveService)); }, token: AuthenticationService, providedIn: "root" });
    return AuthenticationService;
}());
export { AuthenticationService };
