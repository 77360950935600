import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from './auth/_services/authentication.service';
import { UserIdleConfig } from './auth/user-idle/user-idle-config';
import { UserInfoService } from './@core/user-info.service';
import { PhoneService } from './@core/phone.service';
import { PageLoaderService } from "./@core/page-loader.service";
var AppComponent = /** @class */ (function () {
    function AppComponent(router, pageLoaderService, authService, userInfoService, phoneService, route) {
        var _this = this;
        this.router = router;
        this.pageLoaderService = pageLoaderService;
        this.authService = authService;
        this.userInfoService = userInfoService;
        this.phoneService = phoneService;
        this.route = route;
        this.show = true;
        if (sessionStorage.getItem('currentUser')) {
            UserIdleConfig.REMEMBER_ME = false;
        }
        // this.router.events.subscribe((ev) => {
        // 	if (ev instanceof NavigationEnd) {
        // 		//alert('ssss')
        // 	}
        // });
        this.pageLoaderService.loaderShow.subscribe(function (value) { return _this.loaderChanged(value); });
        var isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
        if (isMac) {
            document.body.classList.add('is-mac-ios');
        }
    }
    AppComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var queryString, urlParams, deviceId, deviceType;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        queryString = window.location.search;
                        urlParams = new URLSearchParams(queryString);
                        deviceId = urlParams.get('device-id');
                        deviceType = urlParams.get('device-type');
                        if (deviceId && deviceType) {
                            this.userInfoService.setDeviceData(deviceId, deviceType);
                        }
                        this.phoneService.init();
                        return [4 /*yield*/, this.authService.checkSession()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    AppComponent.prototype.loaderChanged = function (value) {
        this.show = value;
    };
    return AppComponent;
}());
export { AppComponent };
