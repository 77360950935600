import { DATE_FORMAT, DEFAULT_DATE } from "../../@config/dateFormat";
import { DatePipe } from "@angular/common";
import * as i0 from "@angular/core";
//import * as moment from 'moment';
//import 'moment/locale/pt-br';
var DateService = /** @class */ (function () {
    function DateService() {
        this.DEFAULT_DATE = DEFAULT_DATE;
        this.DATE_FORMAT = DATE_FORMAT;
    }
    DateService.prototype.getUTCDate = function (date) {
        var data = date.getUTCDate();
        if (data < 10) {
            data = '0' + data;
        }
        return data;
    };
    DateService.prototype.getUTCMonth = function (date) {
        var data = date.getUTCMonth();
        if (data < 10) {
            data = '0' + data;
        }
        return data;
    };
    DateService.prototype.getUTCFullYear = function (date) {
        return date.getUTCFullYear();
    };
    DateService.prototype.getUTCHours = function (date) {
        var data = date.getUTCHours();
        if (data < 10) {
            data = '0' + data;
        }
        return data;
    };
    DateService.prototype.getUTCMinutes = function (date) {
        var data = date.getUTCMinutes();
        if (data < 10) {
            data = '0' + data;
        }
        return data;
    };
    DateService.prototype.getUTCSeconds = function (date) {
        var data = date.getUTCSeconds();
        if (data < 10) {
            data = '0' + data;
        }
        return data;
    };
    DateService.prototype.getFullYear = function (date) {
        return date.getFullYear();
    };
    DateService.prototype.getMonth = function (date) {
        var month = date.getMonth();
        month = month + 1;
        if (month < 10) {
            month = '0' + month;
        }
        return month;
    };
    DateService.prototype.getDate = function (date) {
        return date.getDate();
    };
    DateService.prototype.getHours = function (date) {
        return date.getHours();
    };
    DateService.prototype.getMinutes = function (date) {
        return date.getMinutes();
    };
    DateService.prototype.getSeconds = function (date) {
        return date.getSeconds();
    };
    DateService.prototype.getDateWithoutTimeZone = function (date) {
        if (date === this.DEFAULT_DATE) {
            return null;
        }
        if (date && date != '' && date != null) {
            date = this.toDate(date);
            return this.getFullYear(date) + '-' + this.getMonth(date) + '-' + this.getDate(date) + ' ' + this.getHours(date) + ':' + this.getMinutes(date) + ':' + this.getSeconds(date);
        }
        //return '0000-00-00 00:00:00';
        return null;
    };
    DateService.prototype.getDateSafari = function (date) {
        if (date === this.DEFAULT_DATE) {
            return '';
        }
        if (date && date != '' && date != null) {
            date = this.toDate(date.replace(/-/g, "/"));
            return this.getFullYear(date) + '/' + this.getMonth(date) + '/' + this.getDate(date) + ' ' + this.getHours(date) + ':' + this.getMinutes(date) + ':' + this.getSeconds(date);
        }
        return '';
    };
    DateService.prototype.getDateWithoutHours = function (date) {
        if (date === this.DEFAULT_DATE) {
            return null;
        }
        if (date && date != '') {
            date = this.toDate(date);
            return this.getFullYear(date) + '-' + this.getMonth(date) + '-' + this.getDate(date) + " 00:00:00";
        }
        //return '0000-00-00 00:00:00';
        return null;
    };
    DateService.prototype.isValidDate = function (dateString) {
        // First check for the pattern
        if (!/^\d{1,4}-\d{1,2}-\d{2} \d{1,2}:\d{1,2}:\d{1,2}$/.test(dateString))
            return false;
        // Parse the date parts to integers
        var parts = dateString.split("/");
        var day = parseInt(parts[1], 10);
        var month = parseInt(parts[0], 10);
        var year = parseInt(parts[2], 10);
        // Check the ranges of month and year
        if (year < 1000 || year > 3000 || month == 0 || month > 12)
            return false;
        var monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
        // Adjust for leap years
        if (year % 400 == 0 || (year % 100 != 0 && year % 4 == 0))
            monthLength[1] = 29;
        // Check the range of the day
        return day > 0 && day <= monthLength[month - 1];
    };
    ;
    DateService.prototype.toDate = function (date) {
        //const date1 = moment();
        if (date === this.DEFAULT_DATE) {
            return '';
        }
        if (date && date != '0000-00-00 00:00:00') {
            var dateRes = new Date(date);
            var status_1 = false;
            if (typeof date === "string") {
                var dateS = date.split(' ');
                if (dateS[0]) {
                    var dateS1 = dateS[0].split('/');
                    var dateS2 = dateS[0].split('-');
                    if (dateS1[0]) {
                        if (dateS1[0].length == 4) {
                            status_1 = true;
                        }
                    }
                    if (dateS2[0]) {
                        if (dateS2[0].length == 4) {
                            status_1 = true;
                        }
                    }
                    if (dateS1[2]) {
                        if (dateS1[2].length == 4) {
                            status_1 = true;
                        }
                    }
                    if (dateS2[2]) {
                        if (dateS2[2].length == 4) {
                            status_1 = true;
                        }
                    }
                }
            }
            if (status_1 == true) {
                return dateRes;
            }
            else if (dateRes.getFullYear() > 1900 && dateRes.getFullYear() < 2150) {
                return dateRes;
            }
            else {
                return date;
            }
            //return this.toUTCString(new Date(date));
            //return date1.utc(date);
            //let dateRes = new Date(date);
            //let timezone = -dateRes.getTimezoneOffset();
            //timezone = -240;
            //var currentTimeZoneOffsetInHours = dateRes.getTimezoneOffset() / 60;
            //alert(dateRes.getTimezoneOffset()+"="+currentTimeZoneOffsetInHours)
            //return new Date(Date.UTC(dateRes.getFullYear(), dateRes.getMonth(), dateRes.getDate(), dateRes.getHours(), dateRes.getMinutes(), dateRes.getSeconds()));
            //return new Date(dateRes.valueOf() - timezone * 60000);
            // if(timezone > 0){
            // 	//alert(dateRes.valueOf()+"-"+timezone)
            // 	return new Date(dateRes.valueOf() - timezone * 60000);
            // }else{
            // 	return new Date(dateRes.valueOf() + (timezone * (-1)) * 60000);
            // }
            //new Date(date.valueOf() + date.getTimezoneOffset() * 60000);
            // if(typeof date === "string"){
            // 	var regex_date = /^\d{4}\-\d{1,2}\-\d{1,2}\ \d{1,2}\:\d{1,2}\:\d{1,2}$/;
            //
            // 	if(!regex_date.test(date))
            // 	{
            // 		//return new Date(date);
            // 	}
            //
            // 	// Parse the date parts to integers
            // 	var parts   = date.split("-");
            // 	var day     = parseInt(parts[2], 10);
            // 	var month   = parseInt(parts[1], 10);
            // 	var year    = parseInt(parts[0], 10);
            //
            // 	// Check the ranges of month and year
            // 	if(year < 1000 || year > 3000 || month == 0 || month > 12)
            // 	{
            // 		//return new Date(date);
            // 	}
            //
            // 	var monthLength = [ 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31 ];
            //
            // 	// Adjust for leap years
            // 	if(year % 400 == 0 || (year % 100 != 0 && year % 4 == 0))
            // 	{
            // 		monthLength[1] = 29;
            // 	}
            //
            // 	// Check the range of the day
            // 	//return day > 0 && day <= monthLength[month - 1];
            //
            // 	console.log("ll");
            // 	return new Date(year, month, day);
            // }else{
            // 	return new Date(date);
            // }
        }
        return null;
    };
    DateService.prototype.convertToDate = function (data) {
        if (typeof data === "string") {
            if (!isNaN(Date.parse(data)) && !this.isInt(data) && !this.isFloat(data)) {
                data = this.toDate(data);
            }
        }
        else {
            for (var d in data) {
                if (typeof data[d] === "object") {
                    for (var p in data[d]) {
                        if (typeof data[d][p] === "object") {
                            for (var t in data[d][p]) {
                                if (typeof data[d][p][t] === "object") {
                                    for (var k in data[d][p][t]) {
                                        if (!isNaN(Date.parse(data[d][p][t][k])) && !this.isInt(data[d][p][t][k]) && !this.isFloat(data[d][p][t][k])) {
                                            data[d][p][t][k] = this.toDate(data[d][p][t][k]);
                                        }
                                    }
                                }
                                else {
                                    if (!isNaN(Date.parse(data[d][p][t])) && !this.isInt(data[d][p][t]) && !this.isFloat(data[d][p][t])) {
                                        data[d][p][t] = this.toDate(data[d][p][t]);
                                    }
                                }
                            }
                        }
                        else {
                            if (!isNaN(Date.parse(data[d][p])) && !this.isInt(data[d][p]) && !this.isFloat(data[d][p])) {
                                data[d][p] = this.toDate(data[d][p]);
                            }
                        }
                    }
                }
                else {
                    if (!isNaN(Date.parse(data[d])) && !this.isInt(data[d]) && !this.isFloat(data[d])) {
                        data[d] = this.toDate(data[d]);
                    }
                }
            }
        }
        return data;
    };
    DateService.prototype.isInt = function (value) {
        return !isNaN(value) && (function (x) { return (x | 0) === x; })(parseFloat(value));
    };
    DateService.prototype.isFloat = function (n) {
        return Number(n) === n && n % 1 !== 0;
    };
    DateService.prototype.dateToFormat = function (date) {
        if (date === this.DEFAULT_DATE || !!date) {
            return '';
        }
        var datePipe = new DatePipe("en-US");
        if (date) {
            date = datePipe.transform(date.replace(/ /g, "T"), this.DATE_FORMAT.defaultPipe);
        }
        return date;
    };
    DateService.prototype.setDateFormat = function (date) {
        if (date && (new Date(date).toUTCString() !== new Date('1969-12-31 00:00:00').toUTCString())) {
            var newDate = new Date(date);
            return newDate;
        }
        return null;
    };
    DateService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DateService_Factory() { return new DateService(); }, token: DateService, providedIn: "root" });
    return DateService;
}());
export { DateService };
