import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { DynamicFormService, } from '@ng-dynamic-forms/core';
import { ADDRESS, CYTY, EXT, PHONE, PRESS, STATE_SELECT, STRING_FIELD, ZIP } from '../../../../../../../@util/dynamic-form/dynamic-controlls';
import { MedicalService } from '../../../../../../@core/medical.service';
import { FormValueBuilder } from '../../../../../partial/forms/form-tamplate';
import { ActivatedRoute, Router } from '@angular/router';
import { map, startWith } from 'rxjs/operators';
import { CatalogItemType } from '../../../../../../@models/medical/catalog-item-view';
import { AddressService } from "../../../../../../@core/address.service";
import { State } from "../../../../../../test-new-stepper-form/test-form/on-changes";
import { Functions } from "../../../../../../../@util/functions";
import { DynamicFormGroupModel } from "../../../../../../../@util/ui-material/form-controls";
import { UserInfoService } from "../../../../../../@core/user-info.service";
import { MedicalRequestsComponent } from "../../medical-requests/medical-requests.component";
import { MedicalPaymentsComponent } from "../../medical-payments/medical-payments.component";
import { MatAutocompleteTrigger, MatDialogRef } from "@angular/material";
import { DialogService } from "../../../../../../../@util/dialog/dialog.service";
import { DateService } from "../../../../../../@core/functions/date.service";
import { MedicalSourcesService } from "../../../../../../@core/medical-sources.service";
import { MEDICAL_TESTS_MODE } from "../../../../../partial/partial-medical-tests/partial-medical-tests.config";
var creationSteps = {
    'initial': 1,
    'visit': 2,
    'visitNotes': 3,
    'medicalTests': 4,
    'medications': 5,
    'finished': 6,
    addAnotherVisitStepOne: 7,
    addAnotherVisitStepTwo: 8,
    addAnotherVisitStepTests: 9,
    addAnotherVisitStepMedications: 10
};
var HospitalFormNewComponent = /** @class */ (function () {
    function HospitalFormNewComponent(medicalService, formService, addressService, userInfoService, dateService, dialog, medicalSourcesService, dialogRef, componentData, route, router) {
        var _this = this;
        this.medicalService = medicalService;
        this.formService = formService;
        this.addressService = addressService;
        this.userInfoService = userInfoService;
        this.dateService = dateService;
        this.dialog = dialog;
        this.medicalSourcesService = medicalSourcesService;
        this.dialogRef = dialogRef;
        this.componentData = componentData;
        this.route = route;
        this.router = router;
        this.updateHospitalsList = new EventEmitter();
        this.title = 'New Hospital';
        this.MEDICAL_TESTS_MODE = MEDICAL_TESTS_MODE;
        this.formLayout = {
            phones: {
                element: {
                    container: 'row-mb',
                    control: 'form-row',
                }
            },
            contact_phone: {
                element: {
                    host: 'form-element'
                }
            },
            contact_press: {
                element: {
                    host: 'form-element'
                }
            },
            contact_ext: {
                element: {
                    host: 'form-element'
                }
            },
            contact_fax: {
                element: {
                    container: 'row-mb'
                }
            },
            contact_address: {
                element: {
                    container: 'row-mb'
                }
            },
            contact_address2: {
                element: {
                    container: 'row-mb'
                }
            },
            contact_cyti: {
                element: {
                    container: 'row-mb'
                }
            },
            state_zip: {
                element: {
                    control: 'form-row',
                    container: 'row-mb'
                }
            },
            contact_state: {
                element: {
                    host: 'form-element'
                }
            },
            contact_zip: {
                element: {
                    host: 'form-element label-accent'
                }
            }
        };
        this.formTemplate = {
            name: STRING_FIELD({ id: 'name', label: 'Name', hidden: true }),
            // recipient: {
            // 	recipientName: STRING_FIELD({
            // 		id: 'recipientName',
            // 		label: 'Person To Contact',
            // 		maxLength: 128,
            // 		required: true,
            // 		validators: {required: null}
            // 	}),
            // },
            contact: {
                phone: PHONE({ id: 'contact_phone', label: 'Phone' }),
                press: PRESS({ id: 'contact_press', label: '"Press"' }),
                ext: EXT({ id: 'contact_ext', label: 'Ext.' }),
                fax: PHONE({ id: 'contact_fax', label: 'Business Fax' }),
                street: ADDRESS({ id: 'contact_address' }),
                street2: ADDRESS({ id: 'contact_address2', label: 'Address 2' }),
                city: CYTY({ id: 'contact_cyti' }),
                state: STATE_SELECT({ id: 'contact_state', disabled: true }),
                zip: ZIP({ id: 'contact_zip' }),
            }
        };
        this.form = {
            name: this.formTemplate.name,
            // recipientName: this.formTemplate.recipient.recipientName,
            contact: DynamicFormGroupModel({
                id: 'contact',
                group: [
                    DynamicFormGroupModel({
                        id: 'phones',
                        group: [
                            this.formTemplate.contact.phone,
                            this.formTemplate.contact.press,
                            this.formTemplate.contact.ext
                        ]
                    }),
                    this.formTemplate.contact.fax,
                    DynamicFormGroupModel({
                        id: 'state_zip',
                        group: [
                            this.formTemplate.contact.zip,
                            this.formTemplate.contact.state,
                        ]
                    }),
                    this.formTemplate.contact.city,
                    this.formTemplate.contact.street,
                    this.formTemplate.contact.street2,
                ]
            }),
        };
        this.subscribed = true;
        this.state = State.before;
        this.allFormState = State.before;
        this.tabNavigationActive = 0;
        this.typeAskingStep = creationSteps.initial;
        this.resetVisits = false;
        this.creationSteps = creationSteps;
        this.createdHospitalName = '';
        this.nameCtrl = new FormControl();
        this.nameCtrlId = null;
        this.names = [];
        this.formModel = FormValueBuilder.buildFormModel(this.form);
        this.formGroup = this.formService.createFormGroup(this.formModel);
        this.nameCtrl.setValidators(Validators.required);
        //this.sub = navService.back().subscribe(j => this.closeDoctorForm());
        router.events.subscribe(function (val) {
            if (val) {
                document.querySelectorAll('.mat-drawer-content').forEach(function (el) {
                    el.style.overflow = 'scroll';
                });
                _this.dialogRef.close({});
            }
        });
    }
    HospitalFormNewComponent.prototype.onMousewheel = function (event) {
        var input = document.querySelector('.mat-input-element');
        input.blur();
        this.trigger.closePanel();
    };
    HospitalFormNewComponent.prototype.ngOnDestroy = function () {
        //this.sub.unsubscribe();
    };
    HospitalFormNewComponent.prototype._filterNames = function (value) {
        ////console.log(value);
        var filterValue = typeof value === 'string' ? value.toLowerCase() : value.name ? value.name.toLowerCase() : '';
        ////console.log(filterValue);
        return this.names.filter(function (state) { return state.name.toLowerCase().indexOf(filterValue) === 0; });
    };
    HospitalFormNewComponent.prototype.displayFn = function (item) {
        return item ? item.name : undefined;
    };
    HospitalFormNewComponent.prototype.ngAfterViewInit = function () {
    };
    HospitalFormNewComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.sslgId = this.getSslgId();
                        _a = this;
                        return [4 /*yield*/, this.medicalService.getCatalog(CatalogItemType.HOSPITAL).toPromise()];
                    case 1:
                        _a.names = _b.sent();
                        this.filteredNames = this.nameCtrl.valueChanges
                            .pipe(map(function (value) {
                            _this.hospital = null;
                            _this.formTemplate.name.valueUpdates.next(_this.nameCtrl.value + '');
                            if (value.id) {
                                _this.subscription.unsubscribe();
                                if (value.id != -1) {
                                    _this.formGroup.disable({ onlySelf: true });
                                    setTimeout(function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                        var _a;
                                        return tslib_1.__generator(this, function (_b) {
                                            switch (_b.label) {
                                                case 0:
                                                    _a = this;
                                                    return [4 /*yield*/, this.medicalService.getCatalogItem(CatalogItemType.HOSPITAL, value.id).toPromise()];
                                                case 1:
                                                    _a.hospital = _b.sent();
                                                    return [4 /*yield*/, this.setFormData()];
                                                case 2:
                                                    _b.sent();
                                                    return [2 /*return*/];
                                            }
                                        });
                                    }); });
                                }
                                else {
                                    _this.addressService.getPlaceInfo(value.placeId).subscribe(function (j) {
                                        _this.formTemplate.name.autocompleteSelectedValue = null;
                                        if (j.zip)
                                            _this.formTemplate.contact.zip.valueUpdates.next(j.zip);
                                        if (j.state)
                                            _this.formTemplate.contact.state.valueUpdates.next(j.state);
                                        if (j.city)
                                            _this.formTemplate.contact.city.valueUpdates.next(j.city);
                                        if (j.locations)
                                            _this.formTemplate.contact.street.valueUpdates.next((j.streetNumber ? j.streetNumber + ' ' : '') + (j.locations ? j.locations : ''));
                                    });
                                }
                            }
                            else {
                                if (!_this.subscribed) {
                                    _this.medicalService.setMedicalConditionsTemplate([]);
                                    // this.subscribeZipCode();
                                }
                                _this.formGroup.enable({ onlySelf: true });
                                _this.formTemplate.contact.state.disabledUpdates.next(true);
                                setTimeout(function () {
                                    _this.formGroup.reset();
                                    _this.subscribeZipCode();
                                });
                            }
                            return value;
                        }), startWith(''), map(function (state) {
                            _this.onStateChanges();
                            if (state) {
                                var names = _this._filterNames(state);
                                // if (typeof state === 'string' && state.length > 3 || state.name) {
                                // 	let query = typeof state === 'string' ? state : state.name;
                                // 	this.addressService.findPlace(query, "HOSPITAL").pipe(map((place: any) => {
                                // 		return place.map(j=>{
                                // 			return {
                                // 				id: -1,
                                // 				name: j.title,
                                // 				label: j.description,
                                // 				...j
                                // 			};
                                // 		})
                                // 	})).subscribe(j => {
                                // 		names.push(...j);
                                // 		//console.log(this.filteredNames)
                                // 	});
                                // }
                                return names;
                            }
                            return _this.names.slice();
                        }));
                        this.subscribeZipCode();
                        this.oldFormData = FormValueBuilder.getData(this.form);
                        this.oldSourceNameData = this.nameCtrl.value ? this.nameCtrl.value : '';
                        return [2 /*return*/];
                }
            });
        });
    };
    HospitalFormNewComponent.prototype.getSslgId = function () {
        return this.componentData.sslgId;
    };
    HospitalFormNewComponent.prototype.subscribeZipCode = function () {
        var _this = this;
        this.subscription = this.formTemplate.contact.zip.valueUpdates.subscribe(function (j) {
            if (j && j.length == 5) {
                _this.addressService.findAddress(j).subscribe(function (addr) {
                    if (addr && addr.state) {
                        _this.formTemplate.contact.state.valueUpdates.next(addr.state);
                        _this.formTemplate.contact.city.valueUpdates.next(addr.city);
                    }
                });
            }
            _this.subscribed = true;
        });
    };
    HospitalFormNewComponent.prototype.showName = function () {
        return true;
    };
    HospitalFormNewComponent.prototype.setFormData = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                setTimeout(function () {
                    if (_this.hospital !== null) {
                        try {
                            var street = _this.hospital.contact.street;
                            _this.hospital.contact.street = street.replace(/,/g, '');
                        }
                        catch (e) {
                        }
                        FormValueBuilder.setData(_this.hospital, _this.formTemplate);
                    }
                });
                return [2 /*return*/];
            });
        });
    };
    HospitalFormNewComponent.prototype.onBlur = function ($event) {
    };
    HospitalFormNewComponent.prototype.onChange = function ($event) {
    };
    HospitalFormNewComponent.prototype.onFocus = function ($event) {
    };
    // closeDoctorForm() {
    // 	this.router.navigate([this.route.snapshot.queryParams['returnUrl']],
    // 		{queryParams: {page: this.route.snapshot.queryParams['page']}});
    // 	// this.dialogRef.close();
    // }
    HospitalFormNewComponent.prototype.saveHospital = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var formTemplateCopy, data, catalogItemId, eRVisit, eRVisitDate, outPatient, outPatientDate, inPatient, inPatientAdmission, inPatientDischarge, hospitalizationReason, treatmentDetails, conditions, treatedDoctor, treatment, medicalTests, medicalTestsList, medications, medicationsList, hospital;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        FormValueBuilder.setControlsToched(this.formGroup);
                        if (!this.nameCtrl.value) return [3 /*break*/, 2];
                        formTemplateCopy = tslib_1.__assign({}, this.formTemplate);
                        formTemplateCopy.name = this.nameCtrl.value.name ? this.nameCtrl.value.name : this.nameCtrl.value;
                        data = FormValueBuilder.getData(formTemplateCopy);
                        if (this.nameCtrl && this.nameCtrl.value && this.nameCtrl.value.id) {
                            data.id = this.nameCtrl.value.id;
                        }
                        data.name = formTemplateCopy.name;
                        // this.formTemplate.name = this.nameCtrl.value.name ? this.nameCtrl.value.name : this.nameCtrl.value;
                        // const data = FormValueBuilder.getData(this.formTemplate);
                        data.sslgId = this.sslgId;
                        catalogItemId = this.hospitalVisitData ? this.hospitalVisitData.catalogItemId : null;
                        eRVisit = this.hospitalVisitData ? this.hospitalVisitData.eRVisit : null;
                        eRVisitDate = this.hospitalVisitData ? this.dateService.getDateWithoutHours(this.hospitalVisitData.eRVisitDate) : null;
                        outPatient = this.hospitalVisitData ? this.hospitalVisitData.outPatient : null;
                        outPatientDate = this.hospitalVisitData ? this.dateService.getDateWithoutHours(this.hospitalVisitData.outPatientDate) : null;
                        inPatient = this.hospitalVisitData ? this.hospitalVisitData.inPatient : null;
                        inPatientAdmission = this.hospitalVisitData ? this.dateService.getDateWithoutHours(this.hospitalVisitData.inPatientAdmission) : null;
                        inPatientDischarge = this.hospitalVisitData ? this.dateService.getDateWithoutHours(this.hospitalVisitData.inPatientDischarge) : null;
                        hospitalizationReason = this.hospitalsVisitNotesData ? this.hospitalsVisitNotesData.hospitalizationReason : null;
                        treatmentDetails = this.hospitalsVisitNotesData ? this.hospitalsVisitNotesData.treatmentDetails : null;
                        conditions = this.hospitalsVisitNotesData ? this.hospitalsVisitNotesData.medicalConditions : null;
                        treatedDoctor = this.hospitalsVisitNotesData ? this.hospitalsVisitNotesData.treatedDoctor : null;
                        treatment = this.medicalTestsData ? this.medicalTestsData.treatment : null;
                        medicalTests = this.medicalTestsData ? this.medicalTestsData.showMedicalTests : null;
                        medicalTestsList = this.medicalTestsData ? this.medicalTestsData.medicalTestsList : null;
                        medications = this.medicationsData ? this.medicationsData.showMedications : null;
                        medicationsList = this.medicationsData ? this.medicationsData.medications : null;
                        return [4 /*yield*/, this.medicalSourcesService.addTreatingSourcesHospital(this.sslgId, data, eRVisit, eRVisitDate, outPatient, outPatientDate, inPatient, inPatientAdmission, inPatientDischarge, hospitalizationReason, treatmentDetails, conditions, treatedDoctor, medications, medicationsList, treatment, medicalTests, medicalTestsList)];
                    case 1:
                        hospital = _a.sent();
                        this.createdHospitalName = this.nameCtrl.value.name ? this.nameCtrl.value.name : this.nameCtrl.value;
                        this.hospitalNewVisitId = hospital.id;
                        this.resetVisits = true;
                        this.dialog.show("THANK YOU! You have successfully added this treating source to status star", ["OK"]);
                        this.nameCtrl.setValue({});
                        this.hospital = null;
                        this.formGroup.reset();
                        setTimeout(function () {
                            _this.resetVisits = false;
                        });
                        setTimeout(function () {
                            _this.updateHospitalsList.next(Math.random());
                            _this.changeTypeAskingStep(_this.creationSteps.finished);
                        });
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    HospitalFormNewComponent.prototype.onStateChanges = function () {
        var dataNew = FormValueBuilder.getData(this.form);
        var sourceNew = this.nameCtrl.value ? this.nameCtrl.value : '';
        if (this.oldFormData != '' && this.oldSourceNameData != '') {
            var isChanges = Functions.equals(this.oldFormData, dataNew);
            var isChangesSource = Functions.equals(this.oldSourceNameData, sourceNew);
            if (!isChanges || !isChangesSource) {
                this.state = State.after;
            }
            else {
                this.state = State.before;
            }
        }
    };
    HospitalFormNewComponent.prototype.isInputDirty = function () {
        return this.state == State.after;
    };
    HospitalFormNewComponent.prototype.addVisit = function () {
        // this.saveDoctor('addVisit');
    };
    HospitalFormNewComponent.prototype.isClaimant = function () {
        return this.userInfoService.isClaimant();
    };
    HospitalFormNewComponent.prototype.canAccess = function () {
        if (this.userInfoService.isClaimant() || this.userInfoService.isSslgStaff() || this.userInfoService.isStaff()) {
            return true;
        }
        return false;
    };
    HospitalFormNewComponent.prototype.cancel = function () {
        document.querySelectorAll('.mat-drawer-content').forEach(function (el) {
            el.style.overflow = 'scroll';
        });
        this.dialogRef.close(false);
    };
    HospitalFormNewComponent.prototype.saveVisit = function (e) {
    };
    HospitalFormNewComponent.prototype.closeForm = function (e) {
    };
    HospitalFormNewComponent.prototype.onFormStateChanged = function ($event) {
    };
    HospitalFormNewComponent.prototype.onAllFormStateChanged = function ($event) {
    };
    HospitalFormNewComponent.prototype.changeTypeAskingStep = function (step) {
        this.typeAskingStep = step;
        var name;
        if (this.nameCtrl && this.nameCtrl.value) {
            name = this.nameCtrl.value.name ? this.nameCtrl.value.name : this.nameCtrl.value;
        }
        if (this.isInitialStep()) {
            this.title = "New Hospital";
        }
        if (this.isVisitStep()) {
            this.title = "Hospital Visit <span>" + name + "</span>";
        }
        if (this.isVisitNotesStep()) {
            this.title = "Visit Notes <span>" + name + "</span>";
        }
        if (this.isMedicalTestsStep()) {
            this.title = "Medical Tests <span>" + name + "</span>";
        }
        if (this.isMedicationsStep()) {
            this.title = "Medications <span>" + name + "</span>";
        }
        if (this.isFinishedStep()) {
            this.title = "What would you like to do next?";
        }
        if (this.isAddAnotherVisitStepOne()) {
            this.title = "Hospital Visit <span>" + this.createdHospitalName + "</span>";
        }
        if (this.isAddAnotherVisitStepTwo()) {
            this.title = "Visit Notes <span>" + this.createdHospitalName + "</span>";
        }
        if (this.isAddAnotherVisitStepTests()) {
            this.title = "Medical Tests <span>" + this.createdHospitalName + "</span>";
        }
        if (this.isAddAnotherVisitStepMedications()) {
            this.title = "Medications <span>" + this.createdHospitalName + "</span>";
        }
    };
    HospitalFormNewComponent.prototype.isInitialStep = function () {
        return this.typeAskingStep === creationSteps.initial;
    };
    HospitalFormNewComponent.prototype.isVisitStep = function () {
        return this.typeAskingStep === creationSteps.visit;
    };
    HospitalFormNewComponent.prototype.isVisitNotesStep = function () {
        return this.typeAskingStep === creationSteps.visitNotes;
    };
    HospitalFormNewComponent.prototype.isMedicalTestsStep = function () {
        return this.typeAskingStep === creationSteps.medicalTests;
    };
    HospitalFormNewComponent.prototype.isMedicationsStep = function () {
        return this.typeAskingStep === creationSteps.medications;
    };
    HospitalFormNewComponent.prototype.isFinishedStep = function () {
        return this.typeAskingStep === creationSteps.finished;
    };
    HospitalFormNewComponent.prototype.isAddAnotherVisitStepOne = function () {
        return this.typeAskingStep === creationSteps.addAnotherVisitStepOne;
    };
    HospitalFormNewComponent.prototype.isAddAnotherVisitStepTwo = function () {
        return this.typeAskingStep === creationSteps.addAnotherVisitStepTwo;
    };
    HospitalFormNewComponent.prototype.isAddAnotherVisitStepTests = function () {
        return this.typeAskingStep === creationSteps.addAnotherVisitStepTests;
    };
    HospitalFormNewComponent.prototype.isAddAnotherVisitStepMedications = function () {
        return this.typeAskingStep === creationSteps.addAnotherVisitStepMedications;
    };
    HospitalFormNewComponent.prototype.nextVisit = function ($event) {
        this.hospitalVisitData = $event;
        if (this.isAddAnotherVisitStepOne()) {
            this.changeTypeAskingStep(creationSteps.addAnotherVisitStepTwo);
        }
        else {
            this.changeTypeAskingStep(creationSteps.visitNotes);
        }
    };
    HospitalFormNewComponent.prototype.backVisit = function () {
        this.changeTypeAskingStep(creationSteps.initial);
    };
    HospitalFormNewComponent.prototype.backVisitNotes = function () {
        if (this.isAddAnotherVisitStepTwo()) {
            this.changeTypeAskingStep(creationSteps.addAnotherVisitStepOne);
        }
        else {
            this.changeTypeAskingStep(creationSteps.visit);
        }
    };
    HospitalFormNewComponent.prototype.nextVisitNotes = function ($event) {
        this.hospitalsVisitNotesData = $event;
        if (this.isAddAnotherVisitStepTwo()) {
            this.changeTypeAskingStep(creationSteps.addAnotherVisitStepTests);
        }
        else {
            this.changeTypeAskingStep(creationSteps.medicalTests);
        }
    };
    HospitalFormNewComponent.prototype.saveMedication = function ($event) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.medicationsData = $event;
                        if (!this.isAddAnotherVisitStepMedications()) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.saveHospitalVisit()];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, this.saveHospital()];
                    case 3:
                        _a.sent();
                        _a.label = 4;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    HospitalFormNewComponent.prototype.backMedication = function ($event) {
        this.medicationsData = $event;
        // this.changeTypeAskingStep(creationSteps.medicalTests);
        if (this.isAddAnotherVisitStepMedications()) {
            this.changeTypeAskingStep(creationSteps.addAnotherVisitStepTests);
        }
        else {
            this.changeTypeAskingStep(creationSteps.medicalTests);
        }
    };
    HospitalFormNewComponent.prototype.nextMedicalTest = function ($event) {
        this.medicalTestsData = $event;
        if (this.isAddAnotherVisitStepTests()) {
            this.changeTypeAskingStep(creationSteps.addAnotherVisitStepMedications);
        }
        else {
            this.changeTypeAskingStep(creationSteps.medications);
        }
    };
    HospitalFormNewComponent.prototype.saveMedicalTest = function ($event) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.medicalTestsData = $event;
                        if (!this.isAddAnotherVisitStepTests()) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.saveHospitalVisit()];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, this.saveHospital()];
                    case 3:
                        _a.sent();
                        _a.label = 4;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    HospitalFormNewComponent.prototype.backMedicalTest = function ($event) {
        this.medicalTestsData = $event;
        // this.changeTypeAskingStep(creationSteps.visitNotes);
        if (this.isAddAnotherVisitStepTests()) {
            this.changeTypeAskingStep(creationSteps.addAnotherVisitStepTwo);
        }
        else {
            this.changeTypeAskingStep(creationSteps.visitNotes);
        }
    };
    // saveVisitData($event) {
    // 	this.hospitalVisitData = $event;
    // }
    HospitalFormNewComponent.prototype.saveHospitalVisit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var catalogItemId, eRVisit, eRVisitDate, outPatient, outPatientDate, inPatient, inPatientAdmission, inPatientDischarge, hospitalizationReason, treatmentDetails, conditions, treatedDoctor, treatment, medicalTests, medicalTestsList, medications, medicationsList;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        catalogItemId = this.hospitalNewVisitId;
                        eRVisit = this.hospitalVisitData ? this.hospitalVisitData.eRVisit : null;
                        eRVisitDate = this.hospitalVisitData ? this.dateService.getDateWithoutHours(this.hospitalVisitData.eRVisitDate) : null;
                        outPatient = this.hospitalVisitData ? this.hospitalVisitData.outPatient : null;
                        outPatientDate = this.hospitalVisitData ? this.dateService.getDateWithoutHours(this.hospitalVisitData.outPatientDate) : null;
                        inPatient = this.hospitalVisitData ? this.hospitalVisitData.inPatient : null;
                        inPatientAdmission = this.hospitalVisitData ? this.dateService.getDateWithoutHours(this.hospitalVisitData.inPatientAdmission) : null;
                        inPatientDischarge = this.hospitalVisitData ? this.dateService.getDateWithoutHours(this.hospitalVisitData.inPatientDischarge) : null;
                        hospitalizationReason = this.hospitalsVisitNotesData ? this.hospitalsVisitNotesData.hospitalizationReason : null;
                        treatmentDetails = this.hospitalsVisitNotesData ? this.hospitalsVisitNotesData.treatmentDetails : null;
                        conditions = this.hospitalsVisitNotesData ? this.hospitalsVisitNotesData.medicalConditions : null;
                        treatedDoctor = this.hospitalsVisitNotesData ? this.hospitalsVisitNotesData.treatedDoctor : null;
                        treatment = this.medicalTestsData ? this.medicalTestsData.treatment : null;
                        medicalTests = this.medicalTestsData ? this.medicalTestsData.showMedicalTests : null;
                        medicalTestsList = this.medicalTestsData ? this.medicalTestsData.medicalTestsList : null;
                        medications = this.medicationsData ? this.medicationsData.showMedications : null;
                        medicationsList = this.medicationsData ? this.medicationsData.medications : null;
                        return [4 /*yield*/, this.medicalSourcesService.addHospitalVisit(this.sslgId, catalogItemId, eRVisit, eRVisitDate, outPatient, outPatientDate, inPatient, inPatientAdmission, inPatientDischarge, hospitalizationReason, treatmentDetails, conditions, treatedDoctor, medications, medicationsList, treatment, medicalTests, medicalTestsList)];
                    case 1:
                        _a.sent();
                        this.resetVisits = true;
                        this.dialog.show("THANK YOU! You have successfully added another Visit to the Hospital", ["OK"]);
                        setTimeout(function () {
                            _this.resetVisits = false;
                        });
                        setTimeout(function () {
                            _this.changeTypeAskingStep(_this.creationSteps.finished);
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    HospitalFormNewComponent.prototype.addNewHospital = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.medicalService.getCatalog(CatalogItemType.HOSPITAL).toPromise()];
                    case 1:
                        _a.names = _b.sent();
                        this.formGroup.reset();
                        this.nameCtrl.setValue({});
                        this.hospital = null;
                        this.changeTypeAskingStep(creationSteps.initial);
                        return [2 /*return*/];
                }
            });
        });
    };
    HospitalFormNewComponent.prototype.addHospitalization = function () {
        document.querySelectorAll('.mat-drawer-content').forEach(function (el) {
            el.style.overflow = 'scroll';
        });
        this.dialogRef.close({});
        this.addDoctor();
    };
    HospitalFormNewComponent.prototype.addDoctor = function () {
        this.router.navigate(['/claimants/medical-treating-sources/' + this.sslgId + '/doctors'], {
            queryParams: {
                returnUrl: this.route.snapshot.queryParams['returnUrl'],
                popup: 'new',
                from: 'hospitals'
            }
        });
    };
    HospitalFormNewComponent.prototype.addAHospitalVisit = function () {
        this.changeTypeAskingStep(this.creationSteps.addAnotherVisitStepOne);
    };
    HospitalFormNewComponent.prototype.addOtherTreatingSource = function () {
        document.querySelectorAll('.mat-drawer-content').forEach(function (el) {
            el.style.overflow = 'scroll';
        });
        this.dialogRef.close({});
        this.router.navigate(['/claimants/medical-treating-sources/' + this.sslgId + '/others'], {
            queryParams: {
                returnUrl: this.route.snapshot.queryParams['returnUrl'],
                popup: 'new'
            }
        });
    };
    HospitalFormNewComponent.prototype.returnToMyProfile = function () {
        document.querySelectorAll('.mat-drawer-content').forEach(function (el) {
            el.style.overflow = 'scroll';
        });
        this.dialogRef.close({});
    };
    return HospitalFormNewComponent;
}());
export { HospitalFormNewComponent };
