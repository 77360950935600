import * as tslib_1 from "tslib";
import { EventEmitter, OnInit, QueryList } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { DynamicFormControlComponent, DynamicFormGroupModel, DynamicFormLayoutService, DynamicFormValidationService } from "@ng-dynamic-forms/core";
var DynamicMaterialFormGroupComponent = /** @class */ (function (_super) {
    tslib_1.__extends(DynamicMaterialFormGroupComponent, _super);
    function DynamicMaterialFormGroupComponent(layoutService, validationService) {
        var _this = _super.call(this, layoutService, validationService) || this;
        _this.layoutService = layoutService;
        _this.validationService = validationService;
        _this.blur = new EventEmitter();
        _this.change = new EventEmitter();
        _this.customEvent = new EventEmitter();
        _this.focus = new EventEmitter();
        return _this;
    }
    DynamicMaterialFormGroupComponent.prototype.ngOnInit = function () {
        // console.log(this.group);
    };
    DynamicMaterialFormGroupComponent.prototype.isDirty = function () {
        if (this.model.isDirty != undefined)
            return this.model.isDirty();
    };
    return DynamicMaterialFormGroupComponent;
}(DynamicFormControlComponent));
export { DynamicMaterialFormGroupComponent };
