import { HttpClient } from '@angular/common/http';
import { INSURERS_CONFIG } from '../@config/api';
import { Social } from '../@models/system-managment/social';
import { Functions } from '../../@util/functions';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var OfficeService = /** @class */ (function () {
    function OfficeService(http) {
        this.http = http;
        this.config = INSURERS_CONFIG.OFFICES;
    }
    OfficeService.prototype.getCurrentOffice = function (officeId) {
        return this.http.get(this.config.byId(officeId));
    };
    OfficeService.prototype.getAll = function () {
        return this.http.get(this.config.getAll);
    };
    OfficeService.prototype.addOffice = function (value) {
        return this.http.post(this.config.addOffice(value.insurenceId), value);
    };
    OfficeService.prototype.setOfficeContact = function (officeId, value) {
        value.press = Functions.checkPhone(value.press);
        value.fax = Functions.checkPhone(value.fax);
        value.phone = Functions.checkPhone(value.phone);
        return this.http.post(this.config.setContact(officeId), value);
    };
    OfficeService.prototype.setOfficeNote = function (officeId, value) {
        return this.http.post(this.config.setNote(officeId), value);
    };
    OfficeService.prototype.getInsuranceOffices = function (insuranceId) {
        return this.http.get(this.config.getAllByCompanyId(insuranceId));
    };
    OfficeService.prototype.setSocial = function (officeId, value) {
        Social.checkLinck(value);
        return this.http.post(this.config.setSocial(officeId), value);
    };
    OfficeService.prototype.setBasic = function (value) {
        return this.http.post(this.config.setBasic(value.id), value);
    };
    OfficeService.prototype.setWebAndLtd = function (id, value) {
        return this.http.post(this.config.setWebAndLtd(id), value);
    };
    OfficeService.prototype.getWebAndLtd = function (id) {
        return this.http.get(this.config.getWebAndLtd(id), { responseType: 'text' });
    };
    OfficeService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function OfficeService_Factory() { return new OfficeService(i0.ɵɵinject(i1.HttpClient)); }, token: OfficeService, providedIn: "root" });
    return OfficeService;
}());
export { OfficeService };
