import * as tslib_1 from "tslib";
import { INSURERS_CONFIG } from '../@config/api';
import { HttpClient } from '@angular/common/http';
import { Social } from '../@models/system-managment/social';
import { Functions } from '../../@util/functions';
import { FileUploadingService } from "../../@util/file-uploading-progress/file-uploading.service";
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../@util/file-uploading-progress/file-uploading.service";
var InsurancesService = /** @class */ (function () {
    function InsurancesService(http, fileUploadingService) {
        this.http = http;
        this.fileUploadingService = fileUploadingService;
        this.config = INSURERS_CONFIG;
    }
    InsurancesService.prototype.getInsurers = function () {
        return this.http.get(this.config.COMPANIES.getAll);
    };
    InsurancesService.prototype.addInsurer = function (data) {
        return this.http.post(this.config.COMPANIES.addCompany, data);
    };
    InsurancesService.prototype.setInsurerContact = function (insuraceId, value) {
        value.press = Functions.checkPhone(value.press);
        value.fax = Functions.checkPhone(value.fax);
        value.phone = Functions.checkPhone(value.phone);
        return this.http.post(this.config.COMPANIES.setContact(insuraceId), value);
    };
    InsurancesService.prototype.setInsurerNote = function (insuraceId, val) {
        return this.http.post(this.config.COMPANIES.setNote(insuraceId), val);
    };
    InsurancesService.prototype.getCurrentInsurance = function (insuraceId) {
        return this.http.get(this.config.COMPANIES.byId(insuraceId));
    };
    InsurancesService.prototype.setSocial = function (insuraceId, value) {
        Social.checkLinck(value);
        return this.http.post(this.config.COMPANIES.setSocial(insuraceId), value);
    };
    InsurancesService.prototype.setBasic = function (value) {
        return this.http.post(this.config.COMPANIES.setBasic(value.id), value);
    };
    InsurancesService.prototype.setWebSite = function (id, website) {
        return this.http.post(this.config.COMPANIES.setWebSite(id), website);
    };
    InsurancesService.prototype.uploadInsurerLogo = function (id, file) {
        return this.fileUploadingService.uploadFile(this.config.COMPANIES.uploadLogo(id), { file: file, fileInfo: null });
    };
    InsurancesService.prototype.removeLogo = function (id) {
        return this.http.post(this.config.COMPANIES.removeLogo(id), {});
    };
    InsurancesService.prototype.getInsurersOption = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var rez;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getInsurers().pipe(map(function (data) { return _this.mapInsurer(data); })).toPromise()];
                    case 1:
                        rez = _a.sent();
                        return [2 /*return*/, rez.sort(function (a, b) {
                                if (a.label < b.label) {
                                    return -1;
                                }
                                if (a.label > b.label) {
                                    return 1;
                                }
                                return 0;
                            })];
                }
            });
        });
    };
    InsurancesService.prototype.mapInsurer = function (data) {
        return data.map(function (j) {
            return {
                value: j.id,
                label: !(j.name) ?
                    'Fill Name' : j.name
            };
        });
    };
    InsurancesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function InsurancesService_Factory() { return new InsurancesService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.FileUploadingService)); }, token: InsurancesService, providedIn: "root" });
    return InsurancesService;
}());
export { InsurancesService };
