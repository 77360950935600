import { EventEmitter, OnInit } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
var NoteComponent = /** @class */ (function () {
    function NoteComponent() {
        this.isValueChanged = false;
        this.changeEditorEvent = new EventEmitter();
    }
    Object.defineProperty(NoteComponent.prototype, "_isValueChanged", {
        set: function (val) {
            console.log(val);
            if (this.isValueChanged != val) {
                this.isValueChanged = val;
                this.oldNoteValue = this.value;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NoteComponent.prototype, "value", {
        get: function () {
            return this._value;
        },
        set: function (value) {
            this._value = value;
            if (this.onChange) {
                this.onChange(value);
            }
        },
        enumerable: true,
        configurable: true
    });
    NoteComponent.prototype.getHeight = function (editor) {
        if (editor.editorElem) {
            // editor.editorElem.style.height = '100%'
            editor.editorElem.style.height = editor.editorElem.clientHeight + 'px';
        }
    };
    NoteComponent.prototype.writeValue = function (obj) {
        this.value = obj;
    };
    // сохраняем обратный вызов для изменений
    NoteComponent.prototype.registerOnChange = function (fn) {
        this.onChange = fn;
    };
    // сохраняем обратный вызов для "касаний"
    NoteComponent.prototype.registerOnTouched = function (fn) {
        this.onTouched = fn;
    };
    NoteComponent.prototype.setDisabledState = function (isDisabled) {
        this.disabled = isDisabled;
    };
    NoteComponent.prototype.ngOnInit = function () {
        var _this = this;
        setTimeout(function () {
            _this.oldNoteValue = _this.value;
            console.log(_this.oldNoteValue);
        });
    };
    NoteComponent.prototype.onStateChanges = function () {
        var newVal = this.value == null ? '' : this.value;
        console.log(this.value);
        if (this.oldNoteValue != null) {
            if (this.oldNoteValue == newVal) {
                this.isValueChanged = false;
                this.changeEditorEvent.emit(false);
            }
            else {
                this.isValueChanged = true;
                this.changeEditorEvent.emit(true);
            }
        }
    };
    return NoteComponent;
}());
export { NoteComponent };
