import * as tslib_1 from "tslib";
import { DynamicDatePickerModel } from "@ng-dynamic-forms/core";
var DatepickerCheckboxModel = /** @class */ (function (_super) {
    tslib_1.__extends(DatepickerCheckboxModel, _super);
    function DatepickerCheckboxModel() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.type = 'DATE_CHECK_BOX';
        return _this;
    }
    return DatepickerCheckboxModel;
}(DynamicDatePickerModel));
export { DatepickerCheckboxModel };
