import * as tslib_1 from "tslib";
import { EventEmitter } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { LabelOptions } from "@angular/material/core";
import { MatInput } from "@angular/material/input";
import { DynamicFormLayoutService, DynamicFormValidationService, DynamicTextAreaModel } from "@ng-dynamic-forms/core";
import { DynamicMaterialFormInputControlComponent } from "../dynamic-material-form-input-control.component";
var DynamicMaterialTextAreaComponent = /** @class */ (function (_super) {
    tslib_1.__extends(DynamicMaterialTextAreaComponent, _super);
    function DynamicMaterialTextAreaComponent(layoutService, validationService, LABEL_OPTIONS) {
        var _this = _super.call(this, layoutService, validationService) || this;
        _this.layoutService = layoutService;
        _this.validationService = validationService;
        _this.LABEL_OPTIONS = LABEL_OPTIONS;
        _this.blur = new EventEmitter();
        _this.change = new EventEmitter();
        _this.customEvent = new EventEmitter();
        _this.focus = new EventEmitter();
        return _this;
    }
    return DynamicMaterialTextAreaComponent;
}(DynamicMaterialFormInputControlComponent));
export { DynamicMaterialTextAreaComponent };
