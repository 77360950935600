import { HttpClient } from "@angular/common/http";
import { POST_AWARD_CONFIG } from "../@config/api";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var CoraAuthorizationService = /** @class */ (function () {
    function CoraAuthorizationService(http) {
        this.http = http;
        this.config = POST_AWARD_CONFIG.auth;
    }
    CoraAuthorizationService.prototype.getAuth = function (sslgId) {
        return this.http.get(this.config.auth(sslgId));
    };
    CoraAuthorizationService.prototype.getSent = function (sslgId, url) {
        return this.http.post(this.config.sent(sslgId), { url: url });
    };
    CoraAuthorizationService.prototype.getReceive = function (sslgId) {
        return this.http.post(this.config.receive(sslgId), {});
    };
    CoraAuthorizationService.prototype.clearAuth = function (sslgId) {
        return this.http.post(this.config.clear(sslgId), {});
    };
    CoraAuthorizationService.prototype.setCoraAuthText = function (sslgId, text) {
        return this.http.post(this.config.setText(sslgId), text);
    };
    CoraAuthorizationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CoraAuthorizationService_Factory() { return new CoraAuthorizationService(i0.ɵɵinject(i1.HttpClient)); }, token: CoraAuthorizationService, providedIn: "root" });
    return CoraAuthorizationService;
}());
export { CoraAuthorizationService };
