import * as tslib_1 from "tslib";
import { MEDICAL_SOURCE_CONFIG } from '../@config/api';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var MedicalSourcesService = /** @class */ (function () {
    function MedicalSourcesService(http) {
        this.http = http;
        this.config = MEDICAL_SOURCE_CONFIG;
    }
    MedicalSourcesService.prototype.getAllDoctors = function () {
        return this.http.get(this.config.getAllDoctors);
    };
    MedicalSourcesService.prototype.getDoctorById = function (doctorId) {
        return this.http.get(this.config.getDoctorById(doctorId));
    };
    MedicalSourcesService.prototype.setDoctor = function (result) {
        return this.http.post(this.config.setDoctor, result);
    };
    MedicalSourcesService.prototype.saveTreatingSourcesRequests = function (sslgId, sourceId, requests) {
        return this.http.post(this.config.saveTreatingSourcesRequests(sslgId), { sourceId: sourceId, requests: requests });
    };
    MedicalSourcesService.prototype.saveTreatingSourcesDoctor = function (sslgId, result) {
        return this.http.post(this.config.saveTreatingSourcesDoctor(sslgId), result);
    };
    MedicalSourcesService.prototype.addTreatingSourcesDoctor = function (sslgId, result, typeClaimAppeal, dateIc, dateRecon, dateHrg, treatment, medicalTests, medicalTestsList, medications, medicationsList) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        data = tslib_1.__assign({}, result, { typeClaimAppeal: typeClaimAppeal, dateIc: dateIc, dateRecon: dateRecon, dateHrg: dateHrg, treatment: treatment, medicalTests: medicalTests,
                            medicalTestsList: medicalTestsList,
                            medications: medications,
                            medicationsList: medicationsList });
                        return [4 /*yield*/, this.http.post(this.config.addTreatingSourcesDoctor(sslgId), data).toPromise()];
                    case 1: 
                    // console.log(data);
                    return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    MedicalSourcesService.prototype.addTreatingSourcesDoctorDates = function (sslgId) {
        return this.http.post(this.config.addTreatingSourcesDoctorDates(sslgId), {});
    };
    MedicalSourcesService.prototype.addTreatingSourcesHospital = function (sslgId, result, eRVisit, eRVisitDate, outPatient, outPatientDate, inPatient, inPatientAdmission, inPatientDischarge, hospitalizationReason, treatmentDetails, conditions, treatedDoctor, medications, medicationsList, treatment, medicalTests, medicalTestsList) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        data = tslib_1.__assign({}, result, { eRVisit: eRVisit,
                            eRVisitDate: eRVisitDate,
                            outPatient: outPatient,
                            outPatientDate: outPatientDate,
                            inPatient: inPatient,
                            inPatientAdmission: inPatientAdmission,
                            inPatientDischarge: inPatientDischarge,
                            hospitalizationReason: hospitalizationReason,
                            treatmentDetails: treatmentDetails,
                            conditions: conditions,
                            treatedDoctor: treatedDoctor,
                            medications: medications,
                            medicationsList: medicationsList,
                            treatment: treatment,
                            medicalTests: medicalTests,
                            medicalTestsList: medicalTestsList });
                        return [4 /*yield*/, this.http.post(this.config.addTreatingSourcesHospital(sslgId), data).toPromise()];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    MedicalSourcesService.prototype.addHospitalVisit = function (sslgId, catalogItemId, eRVisit, eRVisitDate, outPatient, outPatientDate, inPatient, inPatientAdmission, inPatientDischarge, hospitalizationReason, treatmentDetails, conditions, treatedDoctor, medications, medicationsList, treatment, medicalTests, medicalTestsList) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        data = {
                            catalogItemId: catalogItemId,
                            eRVisit: eRVisit,
                            eRVisitDate: eRVisitDate,
                            outPatient: outPatient,
                            outPatientDate: outPatientDate,
                            inPatient: inPatient,
                            inPatientAdmission: inPatientAdmission,
                            inPatientDischarge: inPatientDischarge,
                            hospitalizationReason: hospitalizationReason,
                            treatmentDetails: treatmentDetails,
                            conditions: conditions,
                            treatedDoctor: treatedDoctor,
                            medications: medications,
                            medicationsList: medicationsList,
                            treatment: treatment,
                            medicalTests: medicalTests,
                            medicalTestsList: medicalTestsList
                        };
                        return [4 /*yield*/, this.http.post(this.config.addHospitalVisit(sslgId), data).toPromise()];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    MedicalSourcesService.prototype.saveTreatingSourcesHospital = function (sslgId, result) {
        return this.http.post(this.config.saveTreatingSourcesHospital(sslgId), result);
    };
    MedicalSourcesService.prototype.addTreatingSourcesOther = function (sslgId, result, treatmentStart, treatmentEnd, treatmentOngoing, treatmentReason, treatmentDetails, nextVisit) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        data = tslib_1.__assign({}, result, { treatmentStart: treatmentStart,
                            treatmentEnd: treatmentEnd,
                            treatmentOngoing: treatmentOngoing,
                            treatmentReason: treatmentReason,
                            treatmentDetails: treatmentDetails,
                            nextVisit: nextVisit });
                        return [4 /*yield*/, this.http.post(this.config.addTreatingSourcesOther(sslgId), data).toPromise()];
                    case 1: 
                    // console.log(data);
                    return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    MedicalSourcesService.prototype.saveTreatingSourcesOther = function (sslgId, result) {
        return this.http.post(this.config.saveTreatingSourcesOther(sslgId), result);
    };
    MedicalSourcesService.prototype.treatingSourcesRequestsList = function (sslgId, sourceId) {
        return this.http.post(this.config.treatingSourcesRequestsList(sslgId), { sourceId: sourceId });
    };
    MedicalSourcesService.prototype.treatingSourcesPaymentsList = function (sslgId, sourceId) {
        return this.http.post(this.config.treatingSourcesPaymentsList(sslgId), { sourceId: sourceId });
    };
    MedicalSourcesService.prototype.saveTreatingSourcesRequest = function (sslgId, id, sourceId, data) {
        return this.http.post(this.config.saveTreatingSourcesRequest(sslgId, id, sourceId), data);
    };
    MedicalSourcesService.prototype.saveTreatingSourcesPayment = function (sslgId, id, sourceId, data) {
        return this.http.post(this.config.saveTreatingSourcesPayment(sslgId, id, sourceId), data);
    };
    MedicalSourcesService.prototype.treatingSourcesFindPayment = function (id) {
        return this.http.post(this.config.treatingSourcesFindPayment(), { id: id });
    };
    MedicalSourcesService.prototype.treatingSourcesLastUserToRecords = function (sourceId) {
        return this.http.post(this.config.treatingSourcesLastUserToRecords(sourceId), {});
    };
    MedicalSourcesService.prototype.treatingSourcesFindRequest = function (id) {
        return this.http.post(this.config.treatingSourcesFindRequest(), { id: id });
    };
    MedicalSourcesService.prototype.deleteSourceRequest = function (request) {
        return this.http.post(this.config.deleteSourceRequest(), { requestId: request });
    };
    MedicalSourcesService.prototype.deleteSourcePayment = function (request) {
        return this.http.post(this.config.deleteSourcePayment(), { requestId: request });
    };
    MedicalSourcesService.prototype.getHospital = function (hospitalId) {
        return this.http.get(this.config.getHospitalById(hospitalId));
    };
    MedicalSourcesService.prototype.getClaimantDoctors = function (sslgId) {
        return this.http.get(this.config.getClaimantDoctors(sslgId));
    };
    MedicalSourcesService.prototype.getAllHospitals = function () {
        return this.http.get(this.config.getAllHospitals);
    };
    MedicalSourcesService.prototype.setHospital = function (result) {
        return this.http.post(this.config.setHospital, result);
    };
    MedicalSourcesService.prototype.getOther = function (id) {
        return this.http.get(this.config.getOtherById(id));
    };
    MedicalSourcesService.prototype.getAllOthers = function () {
        return this.http.get(this.config.getAllOthers);
    };
    MedicalSourcesService.prototype.setOther = function (result) {
        return this.http.post(this.config.setOther, result);
    };
    MedicalSourcesService.prototype.deleteSource = function (sslgId, id) {
        return this.http.post(this.config.deleteSource, { sslgId: sslgId, id: id });
    };
    MedicalSourcesService.prototype.deleteSources = function (sources) {
        return this.http.post(this.config.deleteSources, { sources: sources });
    };
    MedicalSourcesService.prototype.changeStatusSource = function (sslgId, id, status) {
        return this.http.post(this.config.changeStatusSource, { sslgId: sslgId, id: id, status: status });
    };
    MedicalSourcesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MedicalSourcesService_Factory() { return new MedicalSourcesService(i0.ɵɵinject(i1.HttpClient)); }, token: MedicalSourcesService, providedIn: "root" });
    return MedicalSourcesService;
}());
export { MedicalSourcesService };
