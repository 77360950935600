import * as tslib_1 from "tslib";
import { EventEmitter } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { MatAutocomplete, MatAutocompleteDefaultOptions } from "@angular/material/autocomplete";
import { ErrorStateMatcher, LabelOptions, RippleGlobalOptions } from "@angular/material/core";
import { MatInput } from "@angular/material/input";
import { DynamicFormLayoutService, DynamicFormValidationService, } from "@ng-dynamic-forms/core";
import { DynamicMaterialFormInputControlComponent } from "../dynamic-material-form-input-control.component";
import { DynamicInputAutocompleteModel } from "./dynamic-input-autocomplete-model";
var DynamicMaterialInputComponent = /** @class */ (function (_super) {
    tslib_1.__extends(DynamicMaterialInputComponent, _super);
    function DynamicMaterialInputComponent(layoutService, validationService, errorStateMatcher, AUTOCOMPLETE_OPTIONS, LABEL_OPTIONS, RIPPLE_OPTIONS) {
        var _this = _super.call(this, layoutService, validationService) || this;
        _this.layoutService = layoutService;
        _this.validationService = validationService;
        _this.errorStateMatcher = errorStateMatcher;
        _this.AUTOCOMPLETE_OPTIONS = AUTOCOMPLETE_OPTIONS;
        _this.LABEL_OPTIONS = LABEL_OPTIONS;
        _this.RIPPLE_OPTIONS = RIPPLE_OPTIONS;
        _this.blur = new EventEmitter();
        _this.change = new EventEmitter();
        _this.customEvent = new EventEmitter();
        _this.focus = new EventEmitter();
        return _this;
    }
    DynamicMaterialInputComponent.prototype.isObject = function (option) {
        return (typeof option !== 'string');
    };
    DynamicMaterialInputComponent.prototype.setSelectedValue = function ($event, param) {
        this.model.autocompleteSelectedValue = param;
        this.model.autocompleteSelectedValueChanged.next(param);
        _super.prototype.onChange.call(this, $event);
    };
    return DynamicMaterialInputComponent;
}(DynamicMaterialFormInputControlComponent));
export { DynamicMaterialInputComponent };
