import * as tslib_1 from "tslib";
import { HttpClient, HttpParams } from '@angular/common/http';
import { DASHBOARD_CONFIG, REFERRAL_CONFIG } from '../@config/api';
import { map } from 'rxjs/operators';
import { AdvisorsService } from './advisors.service';
import { ClientsService } from './clients.service';
import { AdjustersService } from './adjusters.service';
import { SystemUsersService } from "./system-users.service";
import { StatusService } from "./status.service";
import { Subject } from "rxjs";
import { UserInfoService } from "./user-info.service";
import { DateService } from "./functions/date.service";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./adjusters.service";
import * as i3 from "./system-users.service";
import * as i4 from "./advisors.service";
import * as i5 from "./clients.service";
import * as i6 from "./status.service";
import * as i7 from "./functions/date.service";
import * as i8 from "./user-info.service";
var ReferralService = /** @class */ (function () {
    function ReferralService(http, adjusterService, systemUserService, advisorService, clientService, statusService, dateService, userInfoService) {
        this.http = http;
        this.adjusterService = adjusterService;
        this.systemUserService = systemUserService;
        this.advisorService = advisorService;
        this.clientService = clientService;
        this.statusService = statusService;
        this.dateService = dateService;
        this.userInfoService = userInfoService;
        this.claim = new Subject();
        this.updateClaimInfo = new Subject();
        this.isClaimHeaderAttention = new Subject();
        this.config = REFERRAL_CONFIG;
        this.configHeader = DASHBOARD_CONFIG;
    }
    ReferralService.prototype.getList = function (type) {
        return this.http.post(this.config.CLAIMANT.getList, {
            userName: this.getUserName(),
            type: type
        });
        //let param = new HttpParams();
        //return this.http.get<Claimant[]>(this.config.CLAIMANT.getClaimants, {params: param});
    };
    ReferralService.prototype.getConsolidator = function () {
        return this.http.post(this.configHeader.header.consolidator, { userName: this.getUserName() });
    };
    ReferralService.prototype.getAll = function (archived) {
        var param = new HttpParams();
        if (archived != undefined) {
            param = param.append('archived', archived);
        }
        return this.http.get(this.config.CLAIMANT.getClaimants, { params: param });
    };
    ReferralService.prototype.getClaimantLevelDetails = function (sslgId) {
        return this.http.get(this.config.CLAIMANT.getClaimantLevelDetails(sslgId));
    };
    ReferralService.prototype.getById = function (sslgId) {
        return this.http.get(this.config.REFERRAL.getById(sslgId));
    };
    ReferralService.prototype.addNew = function (val) {
        return this.http.post(this.config.CLAIMANT.addClaimant, val);
    };
    ReferralService.prototype.setAcquisition = function (sslgId, val) {
        return this.http.post(this.config.REFERRAL.setAcquisition(sslgId), val);
    };
    // setSummary(sslgId, val: Summary) { //@deprecated
    // 	return this.http.post(this.config.REFERRAL.setSummary(sslgId), val);
    // }
    ReferralService.prototype.getClients = function () {
        return this.clientService.getUsersList().pipe(map(function (data) {
            return data.map(function (j) {
                var name = 'Fill Client Name';
                if (j.clientBasic && j.clientBasic.firstName && j.clientBasic.lastName) {
                    name = j.clientBasic.firstName + ' ' + j.clientBasic.lastName;
                }
                return {
                    value: j.username,
                    label: name
                };
            });
        }));
    };
    ReferralService.prototype.getReferrals = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var rez, managers;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.advisorService.getUsersList().pipe(map(function (data) { return _this.mapUser(data); })).toPromise()];
                    case 1:
                        rez = _a.sent();
                        return [4 /*yield*/, this.getManagers().toPromise()];
                    case 2:
                        managers = _a.sent();
                        rez.push.apply(rez, tslib_1.__spread(managers));
                        return [2 /*return*/, rez.sort(function (a, b) {
                                var aLabel = a.label.toLowerCase();
                                var bLabel = b.label.toLowerCase();
                                if (aLabel < bLabel) {
                                    return -1;
                                }
                                if (aLabel > bLabel) {
                                    return 1;
                                }
                                return 0;
                            })];
                }
            });
        });
    };
    ReferralService.prototype.getManagers = function () {
        var _this = this;
        return this.adjusterService.getUsersList().pipe(map(function (data) { return _this.mapUser(data).sort(function (a, b) {
            var aLabel = a.label.toLowerCase();
            var bLabel = b.label.toLowerCase();
            if (aLabel < bLabel) {
                return -1;
            }
            if (aLabel > bLabel) {
                return 1;
            }
            return 0;
        }); }));
    };
    ReferralService.prototype.mapUser = function (data) {
        return data.map(function (j) {
            return {
                value: j.username,
                label: !(j.personal && j.personal.firstName && j.personal.lastName) ?
                    'Fill Name' : j.personal.firstName + ' ' + j.personal.lastName
            };
        });
    };
    ReferralService.prototype.mapUserStaff = function (data) {
        return data.map(function (j) {
            var thisLabel = '';
            if (!(j.personal && j.personal.firstName && j.personal.lastName)) {
                thisLabel = 'Fill Name';
            }
            else {
                thisLabel = j.personal.firstName + ' ' + j.personal.lastName;
            }
            if (j.personal && j.personal.title) {
                thisLabel = thisLabel + ', ' + j.personal.title;
            }
            else {
                thisLabel = thisLabel + ' ';
            }
            if (!j.contact || !j.contact.email) {
                thisLabel = thisLabel + '';
            }
            else {
                thisLabel = thisLabel + ' ~ ' + j.contact.email;
            }
            return {
                value: j.username,
                label: thisLabel
            };
        });
    };
    ReferralService.prototype.getCurrent = function () {
        return this.claim;
    };
    ReferralService.prototype.setCurrent = function (param) {
        this.current = param;
        this.claim.next(param);
    };
    ReferralService.prototype.getNotes = function (sslgId, type) {
        var url = this.config.NOTES.getAll(sslgId, type);
        return this.http.get(url);
    };
    ReferralService.prototype.getNoteById = function (sslgId, id) {
        return this.http.get(this.config.NOTES.getById(sslgId, id));
    };
    ReferralService.prototype.saveNote = function (sslgId, data) {
        return this.http.post(this.config.NOTES.save(sslgId), data);
    };
    ReferralService.prototype.deleteNoteById = function (sslgId, id) {
        return this.http.delete(this.config.NOTES.getById(sslgId, id), { responseType: 'text' });
    };
    ReferralService.prototype.bulkDeleteNotesByIds = function (sslgId, ids) {
        var url = this.config.NOTES.bulkDeleteByIds(sslgId);
        return this.http.request('delete', url, {
            body: ids,
            responseType: 'text'
        });
    };
    ReferralService.prototype.addFollowUp = function (sslgId, data) {
        return this.http.post(this.config.FOLLOW_UPS.addPending(sslgId), data);
    };
    ReferralService.prototype.getFollowUpsPending = function (sslgId) {
        return this.http.get(this.config.FOLLOW_UPS.getPending(sslgId));
    };
    ReferralService.prototype.getStatusAndFollowUpsPending = function (sslgId) {
        var userName = this.getUserName();
        return this.http.get(this.config.FOLLOW_UPS.getPendingAndStatus(sslgId, userName));
    };
    ReferralService.prototype.setFollowUpCompleted = function (data) {
        return this.http.post(this.config.FOLLOW_UPS.setCompleted(data.sslgId), data);
    };
    ReferralService.prototype.getFollowUpsCompleted = function (sslgId) {
        return this.http.get(this.config.FOLLOW_UPS.getCompleted(sslgId));
    };
    ReferralService.prototype.getExecutiveStaff = function () {
        var _this = this;
        return this.systemUserService.getUsersList(null).pipe(map(function (data) { return _this.mapUser(data); }));
    };
    ReferralService.prototype.getExecutiveStaffUsers = function () {
        return this.systemUserService.getUsersList(null); //.pipe(map((data: User[]) => this.mapUserStaff(data)));
    };
    ReferralService.prototype.getFollowUp = function (sslgId, followUpId) {
        return this.http.get(this.config.FOLLOW_UPS.getById(sslgId, followUpId));
    };
    ReferralService.prototype.createClaimant = function (data) {
        return this.http.post(this.config.CLAIMANT.create, data);
    };
    ReferralService.prototype.getNewSslgId = function (referredBy) {
        return this.http.get(this.config.CLAIMANT.getNewSslgId(referredBy), { responseType: 'text' });
    };
    ReferralService.prototype.saveSSARules = function (sslgId, data) {
        return this.http.post(this.config.REFERRAL.SSARules(sslgId), data);
    };
    ReferralService.prototype.saveSSLGStaff = function (sslgId, data) {
        return this.http.post(this.config.REFERRAL.SSLGStaff(sslgId), data);
    };
    ReferralService.prototype.saveDisablingConditions = function (sslgId, disCond, disCondClaim) {
        return this.http.post(this.config.REFERRAL.DisablingConditions(sslgId), {
            disablingConditions: disCond,
            disablingConditionsClaimant: disCondClaim
        });
    };
    ReferralService.prototype.saveIncome = function (sslgId, data) {
        return this.http.post(this.config.REFERRAL.Income(sslgId), data);
    };
    ReferralService.prototype.getSSARules = function (sslgId) {
        return this.http.get(this.config.REFERRAL.SSARules(sslgId));
    };
    ReferralService.prototype.getSSLGStaff = function (sslgId) {
        return this.http.get(this.config.REFERRAL.SSLGStaff(sslgId));
    };
    ReferralService.prototype.getSSLGStaffForUser = function (sslgId) {
        return this.http.get(this.config.REFERRAL.SSLGStaffForUser(sslgId, this.getUserName()));
    };
    ReferralService.prototype.getDisablingConditions = function (sslgId) {
        return this.http.get(this.config.REFERRAL.DisablingConditions(sslgId));
    };
    ReferralService.prototype.getIncome = function (sslgId) {
        return this.http.get(this.config.REFERRAL.Income(sslgId));
    };
    ReferralService.prototype.getPeriod = function (param) {
        var req = param;
        if (!req) {
            req = { startDate: null, endDate: null };
        }
        req.startDate = this.dateService.getDateWithoutHours(req.startDate);
        req.endDate = this.dateService.getDateWithoutHours(req.endDate);
        return this.http.post('/api/service/date-time/period', req);
    };
    ReferralService.prototype.getPolicyholderCodes = function (sslgId) {
        return this.http.get(this.config.CLAIMANT.policyHolderCodes(sslgId));
    };
    ReferralService.prototype.saveDependents = function (sslgId, data) {
        return this.http.post(this.config.REFERRAL.Dependents(sslgId), data);
    };
    ReferralService.prototype.getDependents = function (sslgId) {
        return this.http.get(this.config.REFERRAL.Dependents(sslgId));
    };
    ReferralService.prototype.getClaimantFlag = function (sslgId) {
        return this.http.get(this.config.REFERRAL.ClaimantFlag(sslgId));
    };
    ReferralService.prototype.setClaimantFlag = function (sslgId, data) {
        return this.http.post(this.config.REFERRAL.ClaimantFlag(sslgId), data);
    };
    ReferralService.prototype.getClaimantInfo = function (sslgId) {
        return this.http.get(this.config.CLAIMANT.getInfo(sslgId));
    };
    ReferralService.prototype.getClaimantPdfInfo = function (sslgId) {
        return this.http.get(this.config.CLAIMANT.getInfoForPdf(sslgId));
    };
    ReferralService.prototype.getFollowUpsCodes = function (sslgId) {
        return this.http.get(this.config.FOLLOW_UPS.getCodes(sslgId));
    };
    ReferralService.prototype.setClaimantFlagIncome = function (sslgId, data) {
        return this.http.post(this.config.REFERRAL.setClaimantFlagIncome(sslgId), data);
    };
    ReferralService.prototype.setClaimantOtherIncome = function (sslgId, data) {
        return this.http.post(this.config.REFERRAL.setClaimantOtherIncome(sslgId), data);
    };
    ReferralService.prototype.getClaimantFlagIncome = function (sslgId) {
        return this.http.get(this.config.REFERRAL.getClaimantFlagIncome(sslgId));
    };
    ReferralService.prototype.getClaimantOtherIncome = function (sslgId) {
        return this.http.get(this.config.REFERRAL.getClaimantOtherIncome(sslgId));
    };
    ReferralService.prototype.getUserName = function () {
        return this.userInfoService.getCurrentUserName();
    };
    ReferralService.prototype.updateClaimHeaderAttention = function (sslgId) {
        var _this = this;
        this.getClaimantLevelDetails(sslgId).subscribe(function (val) {
            _this.isClaimHeaderAttention.next(val.attention);
        });
    };
    ReferralService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ReferralService_Factory() { return new ReferralService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.AdjustersService), i0.ɵɵinject(i3.SystemUsersService), i0.ɵɵinject(i4.AdvisorsService), i0.ɵɵinject(i5.ClientsService), i0.ɵɵinject(i6.StatusService), i0.ɵɵinject(i7.DateService), i0.ɵɵinject(i8.UserInfoService)); }, token: ReferralService, providedIn: "root" });
    return ReferralService;
}());
export { ReferralService };
