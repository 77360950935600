import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
var PdfViewBlocksMedicalTestComponent = /** @class */ (function () {
    function PdfViewBlocksMedicalTestComponent() {
    }
    PdfViewBlocksMedicalTestComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/];
            });
        });
    };
    return PdfViewBlocksMedicalTestComponent;
}());
export { PdfViewBlocksMedicalTestComponent };
