import { PERMISSIONS_CONFIG } from '../@config/api';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ClaimantsPermissionsService = /** @class */ (function () {
    function ClaimantsPermissionsService(http) {
        this.http = http;
        this.config = PERMISSIONS_CONFIG;
    }
    ClaimantsPermissionsService.prototype.geUsers = function (type) {
        return this.http.get(this.config.users(type));
    };
    ClaimantsPermissionsService.prototype.getPermissions = function (sslgId) {
        return this.http.get(this.config.claimant.permissions(sslgId));
    };
    ClaimantsPermissionsService.prototype.addPermission = function (sslgId, userName) {
        return this.http.post(this.config.claimant.permit(sslgId, userName), {}, { responseType: 'text' });
    };
    ClaimantsPermissionsService.prototype.removePermission = function (sslgId, userName) {
        return this.http.post(this.config.claimant.forbid(sslgId, userName), {}, { responseType: 'text' });
    };
    ClaimantsPermissionsService.prototype.getClaimats = function () {
        return this.http.get(this.config.claimant.claimants);
    };
    ClaimantsPermissionsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ClaimantsPermissionsService_Factory() { return new ClaimantsPermissionsService(i0.ɵɵinject(i1.HttpClient)); }, token: ClaimantsPermissionsService, providedIn: "root" });
    return ClaimantsPermissionsService;
}());
export { ClaimantsPermissionsService };
