import * as tslib_1 from "tslib";
import { EventEmitter } from '@angular/core';
import { DynamicFormControlModel, DynamicFormService } from '@ng-dynamic-forms/core';
import { FormValueBuilder } from '../forms/form-tamplate';
import { FormMode } from '../../../@models/system-managment/form-mode';
import { FACEBOOK, INSTAGRAM, TWITTER } from '../../../../@util/dynamic-form/dynamic-controlls';
import { Social } from '../../../@models/system-managment/social';
import { DynamicForm } from "../../../../@util/dynamic-form/dynamic-form";
var FormSocialComponent = /** @class */ (function (_super) {
    tslib_1.__extends(FormSocialComponent, _super);
    function FormSocialComponent(formService) {
        var _this = _super.call(this) || this;
        _this.formService = formService;
        _this.closeForm = new EventEmitter();
        _this.saveForm = new EventEmitter();
        _this.title = 'Social';
        _this.form = {
            facebook: FACEBOOK(),
            instagram: INSTAGRAM(),
            twitter: TWITTER()
        };
        _this.formModel = FormValueBuilder.buildFormModel(_this.form);
        _this.formGroup = _this.formService.createFormGroup(_this.formModel);
        return _this;
    }
    FormSocialComponent.prototype.onBlur = function ($event) {
    };
    FormSocialComponent.prototype.onChange = function ($event) {
    };
    FormSocialComponent.prototype.onFocus = function ($event) {
    };
    FormSocialComponent.prototype.ngOnInit = function () {
        var _this = this;
        setTimeout(function () { return _this.initForm(); }, 0);
    };
    FormSocialComponent.prototype.saveNote = function () {
        FormValueBuilder.setControlsToched(this.formGroup);
        if (this.formGroup.valid) {
            this.saveForm.emit(FormValueBuilder.getData(this.form));
            // TODO State before
            FormValueBuilder.resetToDefault(this.form);
            this.formStateChanged.emit({ key: this.formKey, value: FormValueBuilder.isDirty(this.form) });
        }
    };
    FormSocialComponent.prototype.initForm = function () {
        var _this = this;
        if (this.mode !== FormMode.CreateMode && this.social) {
            FormValueBuilder.setData(this.social, this.form);
        }
        setTimeout(function () {
            // TODO State reset to default
            FormValueBuilder.resetToDefault(_this.form);
            // TODO Form State if Value Changed
            _this.formGroup.valueChanges.subscribe(function (j) {
                _this.formStateChanged.emit({ key: _this.formKey, value: FormValueBuilder.isDirty(_this.form) });
            });
        });
    };
    FormSocialComponent.prototype.ngOnDestroy = function () {
        this.formStateChanged.next({ value: false, key: this.formKey });
    };
    return FormSocialComponent;
}(DynamicForm));
export { FormSocialComponent };
