import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { DynamicFormArrayModel, DynamicFormControlLayout, DynamicFormControlModel, DynamicFormService, } from '@ng-dynamic-forms/core';
import { ADDRESS, CYTY, DATE_PICKER, EXT, PHONE, PRESS, STATE_SELECT, STRING_FIELD, ZIP } from '../../../../../../../@util/dynamic-form/dynamic-controlls';
import { FormValueBuilder } from '../../../../../partial/forms/form-tamplate';
import { MedicalService } from '../../../../../../@core/medical.service';
import { DoctorType } from '../../../../../../@models/medical/doctor-item';
import { ActivatedRoute, Router } from '@angular/router';
import { MedicalTreatingSourcesComponent } from '../../medical-treating-sources.component';
import { debounceTime, map, startWith } from 'rxjs/operators';
import { CatalogItemType } from '../../../../../../@models/medical/catalog-item-view';
import { NavigationService } from "../../../../../../@core/navigation.service";
import { AddressService } from "../../../../../../@core/address.service";
import { State } from "../../../../../../test-new-stepper-form/test-form/on-changes";
import { Functions } from "../../../../../../../@util/functions";
import { DynamicFormArrayModel as DunamicFormArray, DynamicFormGroupModel, DynamicRadioGroupModel } from "../../../../../../../@util/ui-material/form-controls";
import { UserInfoService } from "../../../../../../@core/user-info.service";
import { DateService } from "../../../../../../@core/functions/date.service";
import { MedicalRequestsComponent } from "../../medical-requests/medical-requests.component";
import { MedicalPaymentsComponent } from "../../medical-payments/medical-payments.component";
import { DialogService } from "../../../../../../../@util/dialog/dialog.service";
import { GlobalSaveService } from "../../../../../../@core/global-save.service";
import { PERMISSIONS } from 'src/app/@config/permissions';
var DoctorFormComponent = /** @class */ (function () {
    function DoctorFormComponent(medicalService, formService, fb, addressService, _parent, route, userInfo, user, userInfoService, dateService, dialog, globalSaveService, router, navService) {
        var _this = this;
        this.medicalService = medicalService;
        this.formService = formService;
        this.fb = fb;
        this.addressService = addressService;
        this._parent = _parent;
        this.route = route;
        this.userInfo = userInfo;
        this.user = user;
        this.userInfoService = userInfoService;
        this.dateService = dateService;
        this.dialog = dialog;
        this.globalSaveService = globalSaveService;
        this.router = router;
        this.navService = navService;
        this.title = 'New Doctor';
        this.formLayout = {
            treat: {
                element: {
                    control: 'form-row'
                }
            },
            treats: {
                element: {
                    host: 'form-element'
                }
            },
            speciality: {
                element: {
                    host: 'form-element'
                }
            },
            type: {
                element: {
                    label: 'form-label'
                }
            },
            visits: {
                element: {
                    container: 'row-mb',
                    control: 'form-row',
                }
            },
            firstSeen: {
                element: {
                    host: 'form-element form-element_x2'
                }
            },
            lastSeen: {
                element: {
                    host: 'form-element form-element_x2'
                }
            },
            nextVisit: {
                element: {
                    host: 'form-element form-element_x2'
                }
            },
            phones: {
                element: {
                    container: 'row-mb',
                    control: 'form-row',
                }
            },
            contact_phone: {
                element: {
                    host: 'form-element form-element_x2'
                }
            },
            contact_press: {
                element: {
                    host: 'form-element'
                }
            },
            contact_ext: {
                element: {
                    host: 'form-element'
                }
            },
            contact_fax: {
                element: {
                    container: 'row-mb'
                }
            },
            contact_address: {
                element: {
                    container: 'row-mb'
                }
            },
            contact_address2: {
                element: {
                    container: 'row-mb'
                }
            }, contact_cyti: {
                element: {
                    container: 'row-mb'
                }
            },
            state_zip: {
                element: {
                    control: 'form-row',
                    container: 'row-mb'
                }
            },
            contact_state: {
                element: {
                    host: 'form-element'
                }
            },
            contact_zip: {
                element: {
                    host: 'form-element'
                }
            },
            callerPhones: {
                element: {
                    control: 'form-row',
                }
            },
            medicalCondition: {
                element: {
                    host: 'col-lg-12 row-mb-half',
                    label: 'form-label form-label_full-width row-mb',
                    group: 'row row_conditions'
                },
            },
            callerPhone: {
                element: {
                    host: 'form-element form-element_x2'
                }
            },
            callerPress: {
                element: {
                    host: 'form-element'
                }
            },
            callerExt: {
                element: {
                    host: 'form-element'
                }
            },
        };
        this.formTemplate = {
            name: STRING_FIELD({ id: 'name', label: 'Name', hidden: true }),
            googlePlaceId: STRING_FIELD({ id: 'googlePlaceId', label: 'Name', hidden: true }),
            // recipient: {
            // 	recipientName: STRING_FIELD({
            // 		id: 'recipientName',
            // 		label: 'Person To Contact',
            // 		maxLength: 128,
            // 		required: true,
            // 		validators: {required: null}
            // 	}),
            // },
            practiceName: STRING_FIELD({ id: 'practiceName', label: 'Practice', maxLength: 256 }),
            treats: STRING_FIELD({ id: 'treats', label: 'Treats For', maxLength: 128 }),
            specialty: STRING_FIELD({ id: 'speciality', label: 'Specialty', maxLength: 128 }),
            type: DynamicRadioGroupModel({
                id: 'type',
                label: 'Type',
                options: [
                    {
                        value: DoctorType.PCP,
                        label: DoctorType.PCP.toString()
                    },
                    {
                        value: DoctorType.SPECIALIST,
                        label: DoctorType.SPECIALIST.toString()
                    }
                ],
                value: DoctorType.PCP
            }),
            visits: {
                firstSeen: DATE_PICKER({ id: 'firstSeen', label: 'First Seen', editableFor: PERMISSIONS.LOWER_EXEC }),
                lastSeen: DATE_PICKER({ id: 'lastSeen', label: 'Last Seen', editableFor: PERMISSIONS.LOWER_EXEC }),
                nextVisit: DATE_PICKER({ id: 'nextVisit', label: 'Next Visit', editableFor: PERMISSIONS.LOWER_EXEC })
            },
            contact: {
                phone: PHONE({ id: 'contact_phone', label: 'Phone' }),
                press: PRESS({ id: 'contact_press', label: '"Press"' }),
                ext: EXT({ id: 'contact_ext', label: 'Ext.' }),
                fax: PHONE({ id: 'contact_fax', label: 'Business Fax' }),
                street: ADDRESS({ id: 'contact_address' }),
                street2: ADDRESS({ id: 'contact_address2', label: 'Address 2' }),
                city: CYTY({ id: 'contact_cyti' }),
                state: STATE_SELECT({ id: 'contact_state' }),
                zip: ZIP({ id: 'contact_zip' }),
            },
            callerName: STRING_FIELD({ id: 'callerName', label: 'Name', maxLength: 128 }),
            callerPhone: PHONE({ id: 'callerPhone', label: 'Phone' }),
            callerPress: PRESS({ id: 'callerPress', label: '"Press"' }),
            callerExt: EXT({ id: 'callerExt', label: 'Ext.' }),
            medicalCondition: DunamicFormArray({
                id: 'medicalCondition',
                label: 'What medical conditions were treated?',
                groupFactory: function () {
                    return [
                        STRING_FIELD({ id: 'value', label: 'Condition', disabled: _this.isDisabled(), maxLength: 64, editableFor: PERMISSIONS.LOWER_EXEC })
                    ];
                },
                editableFor: PERMISSIONS.LOWER_EXEC,
            }),
        };
        this.form = {
            name: this.formTemplate.name,
            // recipientName: this.formTemplate.recipient.recipientName,
            googlePlaceId: this.formTemplate.googlePlaceId,
            practiceName: this.formTemplate.practiceName,
            treat: DynamicFormGroupModel({
                id: 'treat',
                group: [
                    // this.formTemplate.treats,
                    this.formTemplate.specialty,
                ]
            }),
            type: this.formTemplate.type,
            contact: DynamicFormGroupModel({
                id: 'contact',
                group: [
                    DynamicFormGroupModel({
                        id: 'phones',
                        group: [
                            this.formTemplate.contact.phone,
                            this.formTemplate.contact.press,
                            this.formTemplate.contact.ext
                        ]
                    }),
                    this.formTemplate.contact.fax,
                    DynamicFormGroupModel({
                        id: 'state_zip',
                        group: [
                            this.formTemplate.contact.zip,
                            this.formTemplate.contact.state,
                        ]
                    }),
                    this.formTemplate.contact.city,
                    this.formTemplate.contact.street,
                    this.formTemplate.contact.street2,
                ]
            }),
            doctor: DynamicFormGroupModel({
                id: 'doctor',
                group: [
                    this.formTemplate.medicalCondition
                ], editableFor: PERMISSIONS.LOWER_EXEC,
            }),
            // callerName: this.formTemplate.callerName,
            // callerPhones: DynamicFormGroupModel({
            // 	id: 'callerPhones',
            // 	group: [
            // 		this.formTemplate.callerPhone,
            // 		this.formTemplate.callerPress,
            // 		this.formTemplate.callerExt,
            // 	]
            // }),
            visit: DynamicFormGroupModel({
                id: 'visit',
                editableFor: PERMISSIONS.LOWER_EXEC,
                group: [
                    DynamicFormGroupModel({
                        id: 'visits',
                        editableFor: PERMISSIONS.LOWER_EXEC,
                        group: [
                            this.formTemplate.visits.firstSeen,
                            this.formTemplate.visits.lastSeen,
                            this.formTemplate.visits.nextVisit
                        ]
                    }),
                ]
            }),
        };
        this.subscribed = true;
        this.state = State.before;
        this.allFormState = State.before;
        this.disabledBtn = false;
        this.condition = "";
        this.nameCtrl = new FormControl();
        this.names = [];
        this.catalogItemId = null;
        this.tabNavigationActive = 0;
        this.formModel = FormValueBuilder.buildFormModel(this.form);
        this.formGroup = this.formService.createFormGroup(this.formModel);
        // this.formGroup.addControl('name', this.nameCtrl);
        this.nameCtrl.setValidators(Validators.required);
        this.sub = navService.back().subscribe(function (j) { return _this.closeDoctorForm(); });
    }
    Object.defineProperty(DoctorFormComponent.prototype, "selectedType", {
        set: function (value) {
            if (!value)
                return;
            if (this._selectedType != value) {
                this._selectedType = value;
                this.typeChanged(value);
            }
        },
        enumerable: true,
        configurable: true
    });
    ;
    Object.defineProperty(DoctorFormComponent.prototype, "visitId", {
        set: function (value) {
            if (!value)
                return;
            if (this._visitId != value) {
                this._visitId = value;
                //this.initMedicalCondition();
            }
        },
        enumerable: true,
        configurable: true
    });
    ;
    DoctorFormComponent.prototype.ngOnDestroy = function () {
        this._parent.hideHeader = false;
        this.sub.unsubscribe();
    };
    DoctorFormComponent.prototype.isDisabled = function () {
        if (this.isCreationPage() || !this.isClaimant()) {
            return false;
        }
        return true;
    };
    DoctorFormComponent.prototype._filterNames = function (value) {
        ////console.log(value);
        var filterValue = typeof value === 'string' ? value.toLowerCase() : value.name ? value.name.toLowerCase() : '';
        ////console.log(filterValue);
        return this.names.filter(function (state) { return state.name.toLowerCase().indexOf(filterValue) === 0; });
    };
    DoctorFormComponent.prototype.displayFn = function (item) {
        return item ? item.name : undefined;
    };
    DoctorFormComponent.prototype.ngAfterViewInit = function () {
        if (this.isCreationPage()) {
            //this.medicalRequestsPaymentsComponent.requestsPayments(null,null);
        }
    };
    DoctorFormComponent.prototype.showBlock = function (i) {
        this.tabNavigationActive = i;
        document.querySelectorAll('.blocks-tab').forEach(function (el) {
            el.style.display = 'none';
        });
        document.getElementById('block-tab-' + this.navLinks[this.tabNavigationActive].id).style.display = '';
    };
    DoctorFormComponent.prototype.blockTabActive = function (i) {
        if (i == this.tabNavigationActive) {
            return true;
        }
        return false;
    };
    DoctorFormComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, i, element, result, firstSeen, lastSeen, nextVisit;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (this.userInfoService.isStaff() || this.userInfoService.isSslgStaff()) {
                            this.navLinks = [
                                {
                                    id: 'info',
                                    label: 'Info'
                                },
                                {
                                    id: 'requests',
                                    label: 'Requests & Submissions'
                                },
                            ];
                        }
                        else {
                            this.navLinks = [
                                {
                                    id: 'info',
                                    label: 'Info'
                                }
                            ];
                        }
                        this.checkDisabledBtn();
                        setTimeout(function () {
                            _this._parent.hideHeader = true;
                        });
                        this.sslgId = this.getSslgId();
                        if (!this.isCreationPage()) return [3 /*break*/, 2];
                        this.medicalService.setClaimantUserName(this.sslgId);
                        this.condition = this.route.snapshot.url[1].path;
                        _a = this;
                        return [4 /*yield*/, this.medicalService.getCatalog(CatalogItemType.DOCTOR).toPromise()];
                    case 1:
                        _a.names = _b.sent();
                        for (i in this.names) {
                            element = this.names[i];
                            this.names[i].label = '';
                            if (element.address != null) {
                                this.names[i].label += element.address;
                            }
                            if (element.city != null) {
                                if (this.names[i].label != '') {
                                    this.names[i].label += ', ';
                                }
                                this.names[i].label += element.city;
                            }
                            if (element.state != null) {
                                if (this.names[i].label != '') {
                                    this.names[i].label += ', ';
                                }
                                this.names[i].label += element.state;
                            }
                            if (element.zip != null) {
                                this.names[i].label += ' ' + element.zip;
                            }
                        }
                        this.filteredNames = this.nameCtrl.valueChanges
                            .pipe(map(function (value) {
                            _this.doctor = null;
                            _this.formTemplate.name = _this.nameCtrl.value;
                            _this.visitsSwitchedOn();
                            if (value.id) {
                                //this.formGroup.reset();
                                _this.formGroup.enable({ onlySelf: true });
                                _this.subscription.unsubscribe();
                                if (value.id != -1) {
                                    if (_this.user.isClaimant()) {
                                        _this.formGroup.get("practiceName").disable({ onlySelf: true });
                                        _this.formGroup.get("type").disable({ onlySelf: true });
                                        _this.formGroup.get("contact").disable({ onlySelf: true });
                                        _this.formGroup.get("callerName").disable({ onlySelf: true });
                                        _this.formGroup.get("callerPhones").disable({ onlySelf: true });
                                        // this.formGroup.get("recipientName").disable({onlySelf: true});
                                    }
                                    setTimeout(function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                        var _a;
                                        return tslib_1.__generator(this, function (_b) {
                                            switch (_b.label) {
                                                case 0:
                                                    _a = this;
                                                    return [4 /*yield*/, this.medicalService.getCatalogItem(CatalogItemType.DOCTOR, value.id).toPromise()];
                                                case 1:
                                                    _a.doctor = _b.sent();
                                                    return [4 /*yield*/, this.setFormData()];
                                                case 2:
                                                    _b.sent();
                                                    return [2 /*return*/];
                                            }
                                        });
                                    }); });
                                }
                                else {
                                    //this.formGroup.enable({onlySelf: true});
                                    _this.addressService.getPlaceInfo(value.placeId).subscribe(function (j) {
                                        _this.formTemplate.name.autocompleteSelectedValue = null;
                                        if (j.zip)
                                            _this.formTemplate.contact.zip.valueUpdates.next(j.zip);
                                        if (j.state)
                                            _this.formTemplate.contact.state.valueUpdates.next(j.state);
                                        if (j.city)
                                            _this.formTemplate.contact.city.valueUpdates.next(j.city);
                                        if (j.phone)
                                            _this.formTemplate.contact.phone.valueUpdates.next(j.phone);
                                        if (j.locations)
                                            _this.formTemplate.contact.street.valueUpdates.next((j.streetNumber ? j.streetNumber + ' ' : '') + (j.locations ? j.locations : ''));
                                    });
                                }
                            }
                            else {
                                if (!_this.subscribed) {
                                    _this.medicalService.setMedicalConditionsTemplate([]);
                                    _this.subscribeZipCode();
                                }
                                _this.formGroup.enable({ onlySelf: true });
                                setTimeout(function () {
                                    _this.formGroup.reset();
                                    _this.form.type.valueUpdates.next(DoctorType.PCP);
                                });
                            }
                            return value;
                        }), startWith(''), debounceTime(300), map(function (state) {
                            _this.onStateChanges();
                            if (state) {
                                var names_1 = _this._filterNames(state);
                                if (typeof state === 'string' && state.length > 3 || state.name) {
                                    var query = typeof state === 'string' ? state : state.name;
                                    _this.addressService.findPlace(query, "DOCTOR").pipe(map(function (place) {
                                        return place.map(function (j) {
                                            return tslib_1.__assign({ id: -1, name: j.title, label: j.description }, j);
                                        });
                                    })).subscribe(function (j) {
                                        names_1.push.apply(names_1, tslib_1.__spread(j));
                                    });
                                }
                                return names_1;
                            }
                            return _this.names.slice();
                        }));
                        this.subscribeZipCode();
                        return [3 /*break*/, 5];
                    case 2:
                        this.title = 'Info Doctor';
                        return [4 /*yield*/, this.medicalService.getCatalogItemInfo(this.sslgId, this.route.snapshot.paramMap.get('id')).toPromise()];
                    case 3:
                        result = _b.sent();
                        this.doctor = result.catalog;
                        this.requests = result.requests;
                        //this.medicalRequestsComponent.init(result.requests);
                        //this.medicalPaymentsComponent.init(result.payments);
                        //this.medicalRequestsPaymentsComponent.requestsPayments(result.requests,result.payments);
                        if (this.doctor.visits) {
                            firstSeen = !this.doctor.visits.firstSeen || (new Date(this.doctor.visits.firstSeen).toUTCString() === new Date('1969-12-31 00:00:00').toUTCString()) ? null : new Date(this.doctor.visits.firstSeen);
                            lastSeen = !this.doctor.visits.lastSeen || (new Date(this.doctor.visits.lastSeen).toUTCString() === new Date('1969-12-31 00:00:00').toUTCString()) ? null : new Date(this.doctor.visits.lastSeen);
                            nextVisit = !this.doctor.visits.nextVisit || (new Date(this.doctor.visits.nextVisit).toUTCString() === new Date('1969-12-31 00:00:00').toUTCString()) ? null : new Date(this.doctor.visits.nextVisit);
                            // this.doctor.visits.firstSeen = this.doctor.visits.firstSeen ? new Date(this.doctor.visits.firstSeen) : this.doctor.visits.firstSeen;
                            // this.doctor.visits.firstSeen = new Date(this.doctor.visits.firstSeen);
                            // this.doctor.visits.lastSeen = new Date(this.doctor.visits.lastSeen);
                            // this.doctor.visits.nextVisit = new Date(this.doctor.visits.nextVisit);
                            this.doctor.visits.firstSeen = firstSeen;
                            this.doctor.visits.lastSeen = lastSeen;
                            this.doctor.visits.nextVisit = nextVisit;
                            // this.doctor.visits.nextVisit = new Date('1969-12-31 00:00:00');
                        }
                        // } catch (e) {
                        // 	 this.router.navigate([this.route.snapshot.queryParams['returnUrl']],
                        // 	 	{queryParams: {page: this.route.snapshot.queryParams['page']}});
                        // 	// this._parent.hideHeader = false;
                        // }
                        return [4 /*yield*/, this.setFormData()];
                    case 4:
                        // } catch (e) {
                        // 	 this.router.navigate([this.route.snapshot.queryParams['returnUrl']],
                        // 	 	{queryParams: {page: this.route.snapshot.queryParams['page']}});
                        // 	// this._parent.hideHeader = false;
                        // }
                        _b.sent();
                        setTimeout(function () {
                            _this.formTemplate.name.hidden = false;
                            _this.initMedicalCondition(_this.route.snapshot.paramMap.get('id'));
                            _this.nameCtrl.disable({ onlySelf: false });
                        });
                        _b.label = 5;
                    case 5:
                        this.oldFormData = FormValueBuilder.getData(this.form);
                        this.oldSourceNameData = this.nameCtrl.value ? this.nameCtrl.value : '';
                        return [2 /*return*/];
                }
            });
        });
    };
    DoctorFormComponent.prototype.getSourceId = function () {
        return this.route.snapshot.paramMap.get('id');
    };
    DoctorFormComponent.prototype.getSslgId = function () {
        return this.route.snapshot.parent.params['sslgId'];
    };
    DoctorFormComponent.prototype.getReturnUrl = function () {
        return this.route.snapshot.queryParams['returnUrl'];
    };
    DoctorFormComponent.prototype.onChange = function ($event) {
        if ($event.model) {
            if ($event.model.id === 'type') {
                this.hideSpecialty($event.model.value);
            }
        }
    };
    DoctorFormComponent.prototype.isCreationPage = function () {
        if (this.route.snapshot.url[1].path === 'new') {
            return true;
        }
        return false;
    };
    DoctorFormComponent.prototype.visitsDisabled = function () {
        // if(this.formTemplate.visits.firstSeen != null ||
        // 	this.formTemplate.visits.lastSeen != null ||
        // 	this.formTemplate.visits.nextVisit != null){
        //
        // 	this.formTemplate.visits.firstSeen.disabled = true;
        // 	this.formTemplate.visits.lastSeen.disabled = true;
        // 	this.formTemplate.visits.nextVisit.disabled = true;
        // }
    };
    DoctorFormComponent.prototype.visitsSwitchedOn = function () {
        this.formTemplate.visits.firstSeen.disabled = false;
        this.formTemplate.visits.lastSeen.disabled = false;
        this.formTemplate.visits.nextVisit.disabled = false;
        //this.formTemplate.practiceName.disabled = false;
    };
    DoctorFormComponent.prototype.visitsSwitchedOff = function () {
        this.formTemplate.visits.firstSeen.disabled = true;
        this.formTemplate.visits.lastSeen.disabled = true;
        this.formTemplate.visits.nextVisit.disabled = true;
    };
    DoctorFormComponent.prototype.subscribeZipCode = function () {
        var _this = this;
        this.subscription = this.formTemplate.contact.zip.valueUpdates.subscribe(function (j) {
            if (j && j.length == 5) {
                _this.addressService.findAddress(j).subscribe(function (addr) {
                    if (addr && addr.state) {
                        _this.formTemplate.contact.state.valueUpdates.next(addr.state);
                        _this.formTemplate.contact.city.valueUpdates.next(addr.city);
                    }
                });
            }
            _this.subscribed = true;
        });
    };
    DoctorFormComponent.prototype.setFormData = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                setTimeout(function () {
                    if (_this.doctor !== null) {
                        try {
                            var street = _this.doctor.contact.street ? _this.doctor.contact.street : '';
                            _this.doctor.contact.street = street.replace(/,/g, '');
                            var name_1 = _this.doctor.name ? _this.doctor.name : '';
                            var fax = (_this.doctor.contact && _this.doctor.contact.fax) ? _this.doctor.contact.fax : '';
                            _this.doctorContacts = {
                                name: name_1,
                                fax: fax
                            };
                        }
                        catch (e) {
                        }
                        FormValueBuilder.setData(_this.doctor, _this.formTemplate);
                        _this.hideSpecialty(_this.formTemplate.type.value);
                    }
                });
                return [2 /*return*/];
            });
        });
    };
    DoctorFormComponent.prototype.checkDisabledBtn = function () {
        if (!this.userInfo.getCurrentUserNameIsSystem()) {
            this.disabledBtn = true;
        }
        else {
            this.disabledBtn = false;
        }
    };
    DoctorFormComponent.prototype.getBtnTitle = function () {
        var title = 'Add a New Medical Source';
        if (this.route.snapshot.url[1].path !== 'new') {
            title = 'Update a Medical Source';
        }
        // if(!this.userInfo.getCurrentUserNameIsSystem()){
        // 	title = 'You do not have permissions to use this functionality.';
        // }
        return title;
    };
    DoctorFormComponent.prototype.getBtnName = function () {
        //this.userInfo.getCurrentUserNameIsSystem()
        if (this.route.snapshot.url[1].path !== 'new') {
            return 'Update';
        }
        return 'Add';
    };
    DoctorFormComponent.prototype.showName = function () {
        return this.route.snapshot.url[1].path === 'new';
    };
    DoctorFormComponent.prototype.onBlur = function ($event) {
    };
    DoctorFormComponent.prototype.hideSpecialty = function (typeValue) {
        var _this = this;
        setTimeout(function () {
            _this.formTemplate.specialty.hidden = typeValue === DoctorType.PCP;
            _this.formLayout.treat.element.control = typeValue === DoctorType.PCP ? '' : 'form-row';
            _this.formLayout.treats.element.host = typeValue === DoctorType.PCP ? '' : 'form-element';
        });
    };
    DoctorFormComponent.prototype.onFocus = function ($event) {
    };
    DoctorFormComponent.prototype.closeDoctorForm = function () {
        this.router.navigate([this.route.snapshot.queryParams['returnUrl']], { queryParams: { page: this.route.snapshot.queryParams['page'] } });
        // this._parent.hideHeader = false;
        // this.dialogRef.close();
    };
    DoctorFormComponent.prototype.saveDoctor = function (cmd) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data, arrayModel, cond, i, value, nextVisit, firstSeen, lastSeen, doc;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.isCreationPage()) {
                            if (!this.nameCtrl.value) {
                                return [2 /*return*/, false];
                            }
                            this.formTemplate.name = this.nameCtrl.value.name ? this.nameCtrl.value.name : this.nameCtrl.value;
                            data = FormValueBuilder.getData(this.formTemplate);
                            data.name = typeof this.nameCtrl.value === 'string' ? this.nameCtrl.value : this.nameCtrl.value.name;
                            if (typeof this.nameCtrl.value !== 'string') {
                                data.id = this.nameCtrl.value.id;
                            }
                        }
                        else {
                            if (!this.formTemplate.name.value) {
                                return [2 /*return*/, false];
                            }
                            data = FormValueBuilder.getData(this.formTemplate);
                            data.name = this.formTemplate.name.value;
                        }
                        if (!this.doctor) {
                            data.googlePlaceId = this.nameCtrl.value.placeId ? this.nameCtrl.value.placeId : null;
                        }
                        data.sslgId = this.sslgId;
                        data.pageType = "new";
                        if (this.route.snapshot.url[1].path !== 'new') {
                            data.id = +this.route.snapshot.paramMap.get('id');
                            data.pageType = "old";
                        }
                        arrayModel = this.formGroup.controls['doctor'].controls['medicalCondition'];
                        cond = [];
                        for (i = 0; i < arrayModel.controls.length; i++) {
                            value = arrayModel.controls[i].value.value;
                            if (value != "null" && value) {
                                cond.push(value);
                            }
                        }
                        data.medicalConditions = cond.join('-||-');
                        nextVisit = data.visits.nextVisit;
                        data.visits.nextVisit = this.dateService.getDateWithoutHours(nextVisit);
                        firstSeen = data.visits.firstSeen;
                        data.visits.firstSeen = this.dateService.getDateWithoutHours(firstSeen);
                        lastSeen = data.visits.lastSeen;
                        data.visits.lastSeen = this.dateService.getDateWithoutHours(lastSeen);
                        return [4 /*yield*/, this.medicalService.saveTreatingSourcesDoctor(this.sslgId, data, this.doctor)];
                    case 1:
                        doc = _a.sent();
                        if (doc) {
                            if (cmd == 'addVisit') {
                                this.router.navigate(['/claimants/medical/' + this.sslgId + '/visit-summaries/new'], {
                                    queryParams: {
                                        returnUrl: this.route.snapshot.queryParams['returnUrl'],
                                        catalogItemId: doc.id,
                                        type: doc.type,
                                    }
                                });
                            }
                            else {
                                //this.dialog.show("Information was updated successfully!", ["ok"]);
                                this.state = State.before;
                                this.router.navigate([this.route.snapshot.queryParams['returnUrl']], { queryParams: { page: this.route.snapshot.queryParams['page'] } });
                            }
                            // if(cmd == 'new'){
                            // 	this.state = State.before;
                            // 	this.router.navigate([this.route.snapshot.queryParams['returnUrl']],
                            // 		{queryParams: {page: this.route.snapshot.queryParams['page']}});
                            // }else{
                            // 	this.state = State.before;
                            // 	this.router.navigate(['/claimants/medical/'+this.sslgId+'/visit-summaries/new'],
                            // 		{queryParams: {
                            // 			returnUrl: this.route.snapshot.queryParams['returnUrl'],
                            // 			catalogItemId: doc.id,
                            // 			type: doc.type,
                            // 		}});
                            // }
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    DoctorFormComponent.prototype.onStateChanges = function () {
        var dataNew = FormValueBuilder.getData(this.form);
        var sourceNew = this.nameCtrl.value ? this.nameCtrl.value : '';
        if (this.oldFormData != '' && this.oldSourceNameData != '' && dataNew && sourceNew) {
            var isChanges = Functions.equals(this.oldFormData, dataNew);
            var isChangesSource = Functions.equals(this.oldSourceNameData, sourceNew);
            if (!isChanges || !isChangesSource) {
                this.state = State.after;
            }
            else {
                this.state = State.before;
            }
        }
    };
    DoctorFormComponent.prototype.isInputDirty = function () {
        return this.state == State.after;
    };
    DoctorFormComponent.prototype.isClaimant = function () {
        return this.userInfoService.isClaimant();
    };
    DoctorFormComponent.prototype.showSave = function () {
        if (this.isClaimant() && this.condition != 'new') {
            this.visitsSwitchedOff();
            return false;
        }
        if (!this.globalSaveService.getStatusTrue() && !this.isClaimant()) {
            return false;
        }
        return true;
    };
    DoctorFormComponent.prototype.isApp = function () {
        var w = window.innerWidth;
        if (w < 1100) {
            return true;
        }
        return false;
    };
    DoctorFormComponent.prototype.initMedicalCondition = function (catalogItemId) {
        var medicalCondition = this.doctor.medicalConditions;
        var medicalConditions = medicalCondition.split("-||-");
        for (var i = 0; i < this.formTemplate.medicalCondition.groups.length; i++) {
            this.formTemplate.medicalCondition.removeGroup(i);
        }
        this.insertMedicalCondition();
        this.initMedicalConditions(medicalConditions);
    };
    DoctorFormComponent.prototype.initMedicalConditions = function (medicalConditions) {
        if (!medicalConditions)
            return;
        if (medicalConditions.length > 1) {
            for (var i = 1; i < medicalConditions.length; i++) {
                this.insertMedicalCondition();
            }
        }
        if (medicalConditions.length > 0)
            this.formGroup.controls['doctor'].controls['medicalCondition'].patchValue(medicalConditions.map(function (j) {
                return { value: j };
            }));
    };
    // Medical conditions
    DoctorFormComponent.prototype.insertMedicalCondition = function () {
        var arrayModel = this.formService.findById('medicalCondition', this.formModel);
        this.formService.insertFormArrayGroup(arrayModel.groups.length, this.formGroup.controls['doctor']
            .controls['medicalCondition'], arrayModel);
    };
    DoctorFormComponent.prototype.remove = function (context, index) {
        this.formService.removeFormArrayGroup(index, this.formGroup.controls['doctor'].controls['medicalCondition'], context);
    };
    DoctorFormComponent.prototype.showAddBtn = function (context, index) {
        if (!this.globalSaveService.getStatusTrue() && !this.isClaimant()) {
            return false;
        }
        if (this.isCreationPage() || !this.isClaimant()) {
            return context.groups.length - 1 === index;
        }
        return false;
    };
    DoctorFormComponent.prototype.showRemoveBtn = function (context, index) {
        if (!this.globalSaveService.getStatusTrue() && !this.isClaimant()) {
            return false;
        }
        if (this.isCreationPage() || !this.isClaimant()) {
            return context.groups.length > 1;
        }
        return false;
    };
    DoctorFormComponent.prototype.typeChanged = function (type) {
        if (type === 'DOCTOR') {
            this.form.doctor.hidden = false;
        }
        else {
            this.form.doctor.hidden = true;
        }
    };
    DoctorFormComponent.prototype.addVisit = function () {
        this.saveDoctor('addVisit');
    };
    DoctorFormComponent.prototype.canAccess = function () {
        if (this.userInfoService.isClaimant() || this.userInfoService.isSslgStaff() || this.userInfoService.isStaff()) {
            return true;
        }
        return false;
    };
    return DoctorFormComponent;
}());
export { DoctorFormComponent };
