import * as tslib_1 from "tslib";
import { HttpClient, HttpParams, HttpRequest } from '@angular/common/http';
import { MEDICAL_CONFIG, REFERRAL_CONFIG, NOTIFICATIONS_CONFIG } from '../@config/api';
import { CatalogItemType } from '../@models/medical/catalog-item-view';
import { MedicalSourcesService } from './medical-sources.service';
import { Subject } from "rxjs";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./medical-sources.service";
var MedicalService = /** @class */ (function () {
    function MedicalService(http, medicalSourcesService) {
        this.http = http;
        this.medicalSourcesService = medicalSourcesService;
        this.config = MEDICAL_CONFIG;
        this.configNotification = NOTIFICATIONS_CONFIG;
    }
    MedicalService.prototype.getClaimantUserName = function () {
        return this.claimantUserName;
    };
    MedicalService.prototype.setClaimantUserName = function (value) {
        this.claimantUserName = value;
    };
    MedicalService.prototype.getVisits = function (sslgId) {
        return this.http.get(this.config.visit.getVisits(sslgId));
    };
    MedicalService.prototype.getVisit = function (sslgId, id) {
        return this.http.get(this.config.visit.getVisit(sslgId, id));
    };
    MedicalService.prototype.saveVisit = function (sslgId, type, data) {
        var url;
        if (type === 'DOCTOR') {
            url = this.config.visit.saveDoctorVisit(sslgId);
        }
        else if (type === 'HOSPITAL') {
            url = this.config.visit.saveHospitalVisit(sslgId);
        }
        else {
            url = this.config.visit.saveOtherVisit(sslgId);
        }
        //console.log("jjjjj");
        //console.log(data);
        return this.http.post(url, data);
    };
    MedicalService.prototype.getMedicalTest = function (sslgId, visitId) {
        return this.http.get(this.config.visit.getMedicalTests(sslgId, visitId));
    };
    MedicalService.prototype.setMedicalTest = function (sslgId, visitId, $event) {
        return this.http.post(this.config.visit.setMedicalTests(sslgId, visitId), $event);
    };
    MedicalService.prototype.getMedications = function (sslgId, visitId) {
        return this.http.get(this.config.visit.getMedications(sslgId, visitId));
    };
    MedicalService.prototype.setMedication = function (sslgId, visitId, value) {
        return this.http.post(this.config.visit.setMedications(sslgId, visitId), value);
    };
    MedicalService.prototype.getAllMedicationForClaim = function (sslgId) {
        return this.http.get(this.config.getAllMedicationForClaim(sslgId));
    };
    MedicalService.prototype.getVisitSummaries = function (sslgId, startDate, endDate, source) {
        var param = new HttpParams();
        if (startDate) {
            param = param
                .append('startDate', startDate.getTime() + '');
        }
        if (endDate) {
            param = param
                .append('endDate', endDate.getTime() + '');
        }
        return this.http.get(this.config.visit.getVisitsSummaries(sslgId), { params: param });
    };
    MedicalService.prototype.getVisitNotifications = function (sslgId) {
        return this.http.get(this.config.visit.getVisitNotifications(sslgId));
    };
    MedicalService.prototype.getAlertNotifications = function (sslgId) {
        return this.http.get(this.configNotification.getAlerts(sslgId));
    };
    MedicalService.prototype.setVisitReaded = function (sslgId) {
        return this.http.post(this.config.visit.setVisitReaded(sslgId), {});
    };
    MedicalService.prototype.getVisitSummariesById = function (sslgId, ids) {
        return this.http.post('/api/claimant/' + sslgId + '/findByIds', { sslgId: sslgId, ids: ids });
    };
    MedicalService.prototype.saveStepTwo = function (sslgId, _visitId, rez) {
        return this.http.post(this.config.visit.saveStepTwo(sslgId, _visitId), rez);
    };
    MedicalService.prototype.getCatalogItemInfo = function (sslgId, id) {
        return this.http.get(this.config.catalog_items.getSourceInfo(sslgId, id));
    };
    MedicalService.prototype.getCatalogVisitItem = function (sslgId) {
        return this.http.get(REFERRAL_CONFIG.CLAIMANT.getAllCatalogItems(sslgId));
    };
    MedicalService.prototype.getAllDoctors = function (sslgId) {
        return this.http.get(this.config.catalog_items.getAllDoctors(sslgId));
    };
    MedicalService.prototype.getDoctorById = function (sslgId, doctorId) {
        return this.http.get(this.config.catalog_items.getDoctorById(sslgId, doctorId));
    };
    MedicalService.prototype.setDoctor = function (sslgId, result, current) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var param;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.medicalSourcesService.setDoctor(result).toPromise()];
                    case 1:
                        //if (!current) {
                        current = _a.sent();
                        param = new HttpParams().append("name", result.name);
                        return [4 /*yield*/, this.http.post(this.config.catalog_items.setDoctor(sslgId), {
                                sslgId: result.sslgId,
                                catalogItemId: current.id,
                                type: CatalogItemType.DOCTOR,
                                phone: current.contact ? current.contact.phone : '',
                                fax: current.contact ? current.contact.fax : '',
                            }, { params: param }).toPromise()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    MedicalService.prototype.saveTreatingSourcesDoctor = function (sslgId, result, current) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var param;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.medicalSourcesService.saveTreatingSourcesDoctor(sslgId, result).toPromise()];
                    case 1:
                        //if (!current) {
                        current = _a.sent();
                        param = new HttpParams().append("name", result.name);
                        return [4 /*yield*/, this.http.post(this.config.catalog_items.setDoctor(sslgId), {
                                sslgId: result.sslgId,
                                catalogItemId: current.id,
                                type: CatalogItemType.DOCTOR,
                                phone: current.contact ? current.contact.phone : '',
                                fax: current.contact ? current.contact.fax : '',
                            }, { params: param }).toPromise()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    MedicalService.prototype.getHospital = function (sslgId, hospitalId) {
        return this.http.get(this.config.catalog_items.getHospitalById(sslgId, hospitalId));
    };
    MedicalService.prototype.getAllHospitals = function (sslgId) {
        return this.http.get(this.config.catalog_items.getAllHospitals(sslgId));
    };
    MedicalService.prototype.setHospital = function (sslgId, result, current) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var param;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!!current) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.medicalSourcesService.setHospital(result).toPromise()];
                    case 1:
                        current = _a.sent();
                        _a.label = 2;
                    case 2:
                        param = new HttpParams().append("name", result.name);
                        return [2 /*return*/, this.http.post(this.config.catalog_items.setHospital(sslgId), {
                                sslgId: result.sslgId,
                                catalogItemId: current.id,
                                type: CatalogItemType.HOSPITAL
                            }, { params: param }).toPromise()];
                }
            });
        });
    };
    MedicalService.prototype.saveTreatingSourcesHospital = function (sslgId, result, current) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var param;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.medicalSourcesService.saveTreatingSourcesHospital(sslgId, result).toPromise()];
                    case 1:
                        current = _a.sent();
                        param = new HttpParams().append("name", result.name);
                        return [2 /*return*/, this.http.post(this.config.catalog_items.setHospital(sslgId), {
                                sslgId: result.sslgId,
                                catalogItemId: current.id,
                                type: CatalogItemType.HOSPITAL
                            }, { params: param }).toPromise()];
                }
            });
        });
    };
    MedicalService.prototype.getOther = function (sslgId, id) {
        return this.http.get(this.config.catalog_items.getOtherById(sslgId, id));
    };
    MedicalService.prototype.getAllOthers = function (sslgId) {
        return this.http.get(this.config.catalog_items.getAllOthers(sslgId));
    };
    MedicalService.prototype.setOther = function (sslgId, result, current) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var param;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!!current) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.medicalSourcesService.setOther(result).toPromise()];
                    case 1:
                        current = _a.sent();
                        _a.label = 2;
                    case 2:
                        param = new HttpParams().append("name", result.name);
                        return [2 /*return*/, this.http.post(this.config.catalog_items.setOther(sslgId), {
                                sslgId: result.sslgId,
                                catalogItemId: current.id,
                                type: CatalogItemType.OTHER
                            }, { params: param }).toPromise()];
                }
            });
        });
    };
    MedicalService.prototype.saveTreatingSourcesOther = function (sslgId, result, current) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var param;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.medicalSourcesService.saveTreatingSourcesOther(sslgId, result).toPromise()];
                    case 1:
                        //if (!current) {
                        current = _a.sent();
                        param = new HttpParams().append("name", result.name);
                        return [2 /*return*/, this.http.post(this.config.catalog_items.setOther(sslgId), {
                                sslgId: result.sslgId,
                                catalogItemId: current.id,
                                type: CatalogItemType.OTHER
                            }, { params: param }).toPromise()];
                }
            });
        });
    };
    MedicalService.prototype.getCatalogItemBySourceIdAndSslgId = function (catalogItemId, sslgId) {
        return this.http.get(this.config.catalog_items.getCatalogItemBySourceId(catalogItemId, sslgId));
    };
    MedicalService.prototype.getCatalogItemById = function (catalogItemId, sslgId) {
        return this.http.get(this.config.catalog_items.getCatalogItemById(catalogItemId, sslgId));
    };
    MedicalService.prototype.getCatalog = function (type) {
        switch (type) {
            case CatalogItemType.DOCTOR:
                return this.medicalSourcesService.getClaimantDoctors(this.getClaimantUserName());
                //return this.medicalSourcesService.getAllDoctors();
                break;
            case CatalogItemType.HOSPITAL:
                return this.medicalSourcesService.getAllHospitals();
                break;
            case CatalogItemType.OTHER:
                return this.medicalSourcesService.getAllOthers();
                break;
            default:
                throw Error('Not Implemented');
        }
    };
    MedicalService.prototype.getCatalogItem = function (type, id) {
        switch (type) {
            case CatalogItemType.DOCTOR:
                return this.medicalSourcesService.getDoctorById(id);
                break;
            case CatalogItemType.HOSPITAL:
                return this.medicalSourcesService.getHospital(id);
                break;
            case CatalogItemType.OTHER:
                return this.medicalSourcesService.getOther(id);
                break;
            default:
                throw Error("Type needed");
        }
    };
    MedicalService.prototype.getLastMedicalConditions = function (sslgId, id) {
        return this.http.get(this.config.visit.getLastMedicalConditions(sslgId, id));
    };
    //@Deprecated
    // getSourceRecipients(id: any) {
    // 	return this.http.get<any[]>(this.config.catalog_items.getRecipients(id));
    // }
    // saveVisitSummaries(sslgId, data) {
    // 	return this.request(`/api/medical-visits/${sslgId}/visit-summaries/save`, data);
    // }
    MedicalService.prototype.sentVisitSummaries = function (sslgId, data) {
        return this.request("/api/medical-visits/" + sslgId + "/visit-summaries/email", data);
    };
    MedicalService.prototype.printVisitSummaries = function (sslgId, data) {
        return this.request("/api/medical-visits/" + sslgId + "/visit-summaries/print", data);
    };
    MedicalService.prototype.request = function (url, data) {
        var req = new HttpRequest('POST', url, data, { responseType: 'arraybuffer' });
        return this.http.request(req);
    };
    MedicalService.prototype.setMedicalConditions = function (sslgId, _visitId, medicalConditions) {
        return this.http.post(this.config.visit.setMedicalCondition(sslgId, _visitId), medicalConditions);
    };
    MedicalService.prototype.saveDoctorNextVisit = function (sslgId, visitId, data) {
        return this.http.post(this.config.visit.saveDoctorNextVisit(sslgId, visitId), data);
    };
    MedicalService.prototype.setMedicalConditionsTemplate = function (conditions) {
        this.medicalConditions = conditions;
    };
    MedicalService.prototype.getMedicationsTemplate = function () {
        //console.log(this.medicalConditions);
        return this.medicalConditions;
    };
    MedicalService.prototype.saveVisits = function (type, visitSourceId, sslgId, visits, nextVisit) {
        var _this = this;
        var subscr = new Subject();
        visits.forEach(function (visit, index) {
            if (visit.visitParameters && visit.visitParameters.notes) {
                if (type == 'DOCTOR') {
                    visit.visit.treatments = visit.visitParameters.notes.treatments;
                    visit.visit.discussion = visit.visitParameters.notes.discussion;
                    visit.visit.docResponse = visit.visitParameters.notes.docResponse;
                    visit.visit.discusSSDI = visit.visitParameters.notes.discusSSDI;
                }
                else {
                    visit.visit.treatmentDetails = visit.visitParameters.notes.treatmentDetails;
                    if (type == 'HOSPITAL') {
                        visit.visit.hospitalizationReason = visit.visitParameters.notes.hospitalizationReason;
                        visit.visit.medicalConditions = visit.visitParameters.notes.medicalConditions;
                    }
                    if (type == 'OTHER') {
                        visit.visit.visitReason = visit.visitParameters.notes.visitReason;
                    }
                }
            }
            if (nextVisit && index === visits.length - 1)
                visit.nextVisit = nextVisit;
            visit.visit.sslgId = sslgId;
            visit.visit.catalogItemId = visitSourceId;
            if (!visit.visit.visitReason && type == 'DOCTOR')
                visit.visit.visitReason = {};
            _this.saveVisit(sslgId, type, visit.visit).subscribe(function (rez) {
                if (visit.visitParameters) {
                    if (visit.visitParameters.tests)
                        _this.setMedicalTest(sslgId, rez.id, visit.visitParameters.tests).subscribe();
                    if (visit.visitParameters.medications)
                        _this.setMedication(sslgId, rez.id, visit.visitParameters.medications).subscribe();
                }
                if (_this.medicalConditions && _this.medicalConditions.length)
                    _this.setMedicalConditions(sslgId, rez.id, _this.medicalConditions).subscribe();
                if (index === visits.length - 1) {
                    subscr.next(true);
                    _this.medicalConditions = [];
                }
            });
        });
        return subscr;
    };
    MedicalService.prototype.sentVisitSummariesByEmail = function (report) {
        return this.request("/api/claimant/" + report.sslgId + "/visit-summaries/email-report", report);
    };
    MedicalService.prototype.sendVisitSummariesByEmail = function (sslgId, email, individual, ids) {
        return this.request('/api/claimant/' + sslgId + '/visit-summaries/email-pdf-report', { sslgId: sslgId, email: email, individual: individual, ids: ids });
    };
    MedicalService.prototype.saveVisitSummariesReport = function (report) {
        return this.request("/api/claimant/" + report.sslgId + "/visit-summaries/save-report", report);
    };
    MedicalService.prototype.visitWatched = function (id, sslgId) {
        return this.request("/api/claimant/" + sslgId + "/visit-watched", { visitId: id });
    };
    MedicalService.prototype.saveAllMedications = function (sslgId, data) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.http.post(this.config.changeMedicationStatus(sslgId), data).toPromise()];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    MedicalService.prototype.addMedication = function (sslgId, data) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.http.post(this.config.addMedication(sslgId), data).toPromise()];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    MedicalService.prototype.getMedicalTreatmentSourcesReport = function (sslgId, type) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http.post(this.config.catalog_items.getMedicalTreatmentSourcesReport(sslgId), { type: type }).toPromise()];
            });
        });
    };
    MedicalService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MedicalService_Factory() { return new MedicalService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.MedicalSourcesService)); }, token: MedicalService, providedIn: "root" });
    return MedicalService;
}());
export { MedicalService };
