import { BehaviorSubject } from "rxjs";
import { PromotionsService } from "./promotions.service";
import * as i0 from "@angular/core";
import * as i1 from "./promotions.service";
var IrisTableTabsService = /** @class */ (function () {
    function IrisTableTabsService(promotionsService) {
        this.promotionsService = promotionsService;
        this.content = new BehaviorSubject(null);
    }
    IrisTableTabsService.prototype.updateContent = function (data) {
        this.content.next(data);
    };
    IrisTableTabsService.prototype.requestContent = function (userId, categoryId) {
        var _this = this;
        this.updateContent('reset');
        this.promotionsService.getPromotionsCategory(userId, categoryId).subscribe(function (val) {
            _this.updateContent(val);
        });
    };
    IrisTableTabsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function IrisTableTabsService_Factory() { return new IrisTableTabsService(i0.ɵɵinject(i1.PromotionsService)); }, token: IrisTableTabsService, providedIn: "root" });
    return IrisTableTabsService;
}());
export { IrisTableTabsService };
