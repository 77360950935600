import * as tslib_1 from "tslib";
import { EventEmitter } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { MatCheckbox } from "@angular/material/checkbox";
import { RippleGlobalOptions } from "@angular/material/core";
import { DynamicCheckboxModel, DynamicFormControlComponent, DynamicFormLayoutService, DynamicFormValidationService, } from "@ng-dynamic-forms/core";
var DynamicMaterialCheckboxComponent = /** @class */ (function (_super) {
    tslib_1.__extends(DynamicMaterialCheckboxComponent, _super);
    function DynamicMaterialCheckboxComponent(layoutService, validationService, RIPPLE_OPTIONS) {
        var _this = _super.call(this, layoutService, validationService) || this;
        _this.layoutService = layoutService;
        _this.validationService = validationService;
        _this.RIPPLE_OPTIONS = RIPPLE_OPTIONS;
        _this.blur = new EventEmitter();
        _this.change = new EventEmitter();
        _this.customEvent = new EventEmitter();
        _this.focus = new EventEmitter();
        return _this;
    }
    return DynamicMaterialCheckboxComponent;
}(DynamicFormControlComponent));
export { DynamicMaterialCheckboxComponent };
