import { EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import * as Stomp from '@stomp/stompjs';
import * as SockJS from 'sockjs-client';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { NOTIFICATIONS_CONFIG } from "../@config/api";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var NotificationService = /** @class */ (function () {
    function NotificationService(http) {
        this.http = http;
        this.notifiRecived = new EventEmitter();
        this.alertsRecived = new EventEmitter();
        this.alertsQtyChanged = new BehaviorSubject(null);
        this.config = NOTIFICATIONS_CONFIG;
    }
    NotificationService.prototype.connect = function () {
        // this.disconnect();
        // this.connection = setInterval(() => {
        var _this = this;
        // }, 3000);
        var currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
        var url = window.location.protocol + "//" + window.location.hostname + (window.location.port ? environment.production ? ":" + window.location.port : ':8191' : '');
        var socket = new SockJS(url + '/ws?token=' + currentUser.token);
        var stompClient = Stomp.over(socket);
        // Subscribe the '/notify' channell
        stompClient.connect({}, function (frame) {
            stompClient.subscribe('/user/notifications', function (notification) {
                _this.notifiRecived.emit(JSON.parse(notification.body));
            });
            stompClient.subscribe('/user/notifications/alerts', function (notification) {
                // Call the notify function when receive a notification
                _this.alertsRecived.emit(JSON.parse(notification.body));
            });
        });
        setTimeout(function () {
            _this.http.get('/api/notifications').subscribe(function (j) {
                _this.notifiRecived.emit(j);
            });
            _this.http.get('/api/notifications/alerts').subscribe(function (j) {
                _this.alertsRecived.emit(j);
            });
        }, 300);
    };
    NotificationService.prototype.disconnect = function () {
        clearInterval(this.connection);
        this.connection = undefined;
    };
    NotificationService.prototype.getNotifications = function () {
        return this.notifiRecived;
    };
    NotificationService.prototype.getAlerts = function () {
        return this.alertsRecived;
    };
    NotificationService.prototype.getNotificationsQuantity = function (sslgId) {
        return this.http.get(this.config.quantity(sslgId));
    };
    NotificationService.prototype.alertNotificationsWatched = function (id) {
        return this.http.post(this.config.setWatched, { id: id });
    };
    NotificationService.prototype.getNotification = function (sslgId, notificationId) {
        /*return this.http.get<Alert>('/api/audit-log/' + sslgId + '/read/' + notificationId).pipe(map(j => {
            if (j.content) {
                Object.keys(j.content).forEach(i => {
                    if (j.content[i]) {
                        j.content[i] = JSON.parse(j.content[i]);
                    }
                });
            }
            return j;
        }));*/
        return this.http.get('/api/claimant/' + sslgId + '/audit-log/read/' + notificationId).pipe(map(function (j) {
            if (j.content) {
                Object.keys(j.content).forEach(function (i) {
                    if (j.content[i]) {
                        j.content[i] = JSON.parse(j.content[i]);
                    }
                });
            }
            return j;
        }));
    };
    NotificationService.prototype.setAlertsQtyChanged = function () {
        this.alertsQtyChanged.next(Math.round(+new Date() / 1000) + Math.random());
    };
    NotificationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NotificationService_Factory() { return new NotificationService(i0.ɵɵinject(i1.HttpClient)); }, token: NotificationService, providedIn: "root" });
    return NotificationService;
}());
export { NotificationService };
