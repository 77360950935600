import * as i0 from "@angular/core";
var UserTempService = /** @class */ (function () {
    function UserTempService() {
        this.storage = sessionStorage;
        this.userTempKey = 'currentUserTemp';
    }
    UserTempService.prototype.isTempUser = function () {
        // return true;
        return this.storage.getItem(this.userTempKey) === 'true';
    };
    UserTempService.prototype.resetUserTempState = function () {
        this.storage.setItem(this.userTempKey, 'false');
    };
    UserTempService.prototype.setUserTempState = function (res) {
        this.storage.setItem(this.userTempKey, res.currentUserTemp);
        // this.storage.setItem(this.userTempKey, '1');
    };
    UserTempService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserTempService_Factory() { return new UserTempService(); }, token: UserTempService, providedIn: "root" });
    return UserTempService;
}());
export { UserTempService };
