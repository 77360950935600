import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { FormGroup } from "@angular/forms";
import { DynamicFormControlComponent, DynamicFormControlCustomEvent, DynamicFormLayout, DynamicFormLayoutService, DynamicFormValidationService } from "@ng-dynamic-forms/core";
import { LabelOptions } from "@angular/material/core";
import { MatDatepicker } from "@angular/material/datepicker";
import { MatInput } from "@angular/material/input";
import { DatepickerCustompropsModel } from "./datepicker-customprops-model";
var DatepickerCustompropsComponent = /** @class */ (function (_super) {
    tslib_1.__extends(DatepickerCustompropsComponent, _super);
    function DatepickerCustompropsComponent(layoutService, validationService, LABEL_OPTIONS) {
        var _this = _super.call(this, layoutService, validationService) || this;
        _this.layoutService = layoutService;
        _this.validationService = validationService;
        _this.LABEL_OPTIONS = LABEL_OPTIONS;
        _this.blur = new EventEmitter();
        _this.change = new EventEmitter();
        _this.customEvent = new EventEmitter();
        _this.focus = new EventEmitter();
        _this.minDate = new Date(2020, 5, 10);
        return _this;
    }
    DatepickerCustompropsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.model.valueUpdates.subscribe(function (j) {
            if (j) {
                _this.selectedValue = j;
            }
        });
        // if (this.model) {
        //     this.newDatepickerOptions = {
        //         autofocus: this.model.autoFocus,
        //         formControlName: this.model.id,
        //         id: this.elementId,
        //         maxDate: this.model.max,
        //         minDate: this.model.min,
        //         name: this.model.name,
        //         ngClass: this.getClass('element', 'control'),
        //         placeholder: this.model.placeholder,
        //         readonly: this.model.readOnly,
        //         required: this.model.required,
        //         tabIndex: this.model.tabIndex,
        //         displayFormat: 'M[/]D[/]YYYY'
        //     };
        // }
    };
    DatepickerCustompropsComponent.prototype.clearDate = function () {
        if (!this.model.disabled) {
            this.model.valueUpdates.next(null);
            _super.prototype.onChange.call(this, {});
        }
    };
    DatepickerCustompropsComponent.prototype.datePick = function (event) {
        this.model.valueUpdates.next(event);
    };
    DatepickerCustompropsComponent.prototype.onChange = function (event) {
        _super.prototype.onChange.call(this, event);
    };
    return DatepickerCustompropsComponent;
}(DynamicFormControlComponent));
export { DatepickerCustompropsComponent };
