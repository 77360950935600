import * as i0 from "@angular/core";
var DateService = /** @class */ (function () {
    function DateService() {
    }
    DateService.prototype.convertDateWithTimezone = function (date) {
        var res = new Date(date);
        //console.log(today.toISOString());
        return res.toISOString();
    };
    DateService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DateService_Factory() { return new DateService(); }, token: DateService, providedIn: "root" });
    return DateService;
}());
export { DateService };
