import * as tslib_1 from "tslib";
import { AfterViewInit, OnInit, QueryList } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { LoadingService } from "../../@util/loading/loading.service";
import { MedicalService } from "../@core/medical.service";
var PdfViewComponent = /** @class */ (function () {
    function PdfViewComponent(route, router, loading, medicalService) {
        this.route = route;
        this.router = router;
        this.loading = loading;
        this.medicalService = medicalService;
        this.scale = 0.95;
        this.showContent = true;
    }
    PdfViewComponent.prototype.ngOnInit = function () {
        var _this = this;
        var data = localStorage.getItem('reportData');
        if (!data) {
            this.router.navigate(['/']);
        }
        this.pdfSrc = JSON.parse(data);
        if (this.pdfSrc.type == "DOWNLOAD") {
            this.showContent = false;
        }
        var ids = this.getVisitsIds();
        this.medicalService.getVisitSummariesById(this.pdfSrc.sslgId, ids).subscribe(function (data) {
            _this.pdfData = data.data;
        });
    };
    PdfViewComponent.prototype.getVisitsIds = function () {
        var ids = [];
        for (var i = 0; i < this.pdfSrc.data.length; i++) {
            ids.push(this.pdfSrc.data[i].id);
        }
        return ids;
    };
    PdfViewComponent.prototype.ngAfterViewInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                // let loader = this.loading.loading();
                // setTimeout(()=>{
                // 	let doc = PdfDocument.createDocument({
                // 		format: "a4",
                // 		compressPdf: true,
                // 		nativeElements: null,
                // 		orientation: "portrait",
                // 		unit: "mm"
                // 	});
                // 	try {
                // 		if (!this.pdfSrc.isIndividual) {
                // 			doc.build(() => {
                // 				doc.setHeaders(() => {
                //
                // 				}, this.head.nativeElement, this.footer.nativeElement);
                // 			}, [this.report.nativeElement])
                // 		} else {
                // 			let elements = <any[]>(<any>this.body)._results.map(j => j.nativeElement);
                // 			doc.build(() => {
                // 				doc.setHeaders(() => {
                // 					this.saveAndSendEmail(doc, loader);
                // 				}, this.head.nativeElement, this.footer.nativeElement);
                // 			}, elements);
                // 		}
                // 	} catch (e) {
                // 		this.loading.loaded(loader);
                // 		console.error(e)
                // 	}
                // },100)
                //
                if (this.pdfSrc.type == "EMAIL") {
                    this.sendEmail();
                }
                else if (this.pdfSrc.type == "DOWNLOAD") {
                    this.save();
                }
                else {
                    //this.save();
                }
                return [2 /*return*/];
            });
        });
    };
    PdfViewComponent.prototype.saveAndSendEmail = function (doc, loader) {
        // this.loading.loaded(loader);
        this.loading.loaded();
        if (this.pdfSrc.type == "EMAIL") {
            var data = doc.output();
            this.medicalService.sentVisitSummaries(this.pdfSrc.sslgId, {
                content: data,
                email: this.pdfSrc.email
            }).subscribe();
        }
        doc.save();
    };
    PdfViewComponent.prototype.sendEmail = function () {
        // let {data, report} =  <any>{...this.pdfSrc};
        /*let rez = {...this.pdfSrc};
        rez.data = rez.data.map(j=>j.id);
        (<any>rez).individual = rez.isIndividual;
        this.medicalService.sentVisitSummariesByEmail(rez).subscribe(rez=>{
            if (rez.type == 4)
                this.downloadFile(rez, "report.pdf");
        });*/
        var _this = this;
        var ids = this.getVisitsIds();
        this.medicalService.sendVisitSummariesByEmail(this.pdfSrc.sslgId, this.pdfSrc.email, this.pdfSrc.isIndividual, ids).subscribe(function (rez) {
            if (rez.type == 4)
                _this.downloadFile(rez, "report.pdf");
        });
    };
    // private download(){
    // 	let rez = {...this.pdfSrc};
    // 	rez.data = rez.data.map(j=>j.id);
    // 	(<any>rez).individual = rez.isIndividual;
    // 	this.medicalService.saveVisitSummariesReport(rez).subscribe(rez=>{
    // 		if (rez.type == 4){
    // 			this.downloadFile(rez, "report.pdf");
    // 			window.close();
    // 		}
    // 	});
    // }
    PdfViewComponent.prototype.save = function () {
        var _this = this;
        // let {data, report} =  <any>{...this.pdfSrc};
        //console.log(this.pdfSrc);
        var rez = tslib_1.__assign({}, this.pdfSrc);
        rez.data = rez.data.map(function (j) { return j.id; });
        rez.individual = rez.isIndividual;
        this.medicalService.saveVisitSummariesReport(rez).subscribe(function (rez) {
            if (rez.type == 4) {
                _this.downloadFile(rez, "report.pdf");
                window.close();
            }
        });
    };
    PdfViewComponent.prototype.downloadFile = function (data, fileName) {
        var ieEDGE = navigator.userAgent.match(/Edge/g);
        var ie = navigator.userAgent.match(/.NET/g); // IE 11+
        var oldIE = navigator.userAgent.match(/MSIE/g);
        var blob = new Blob([data.body], { type: 'application/pdf' });
        if (ie || oldIE || ieEDGE) {
            window.navigator.msSaveBlob(blob, atob(fileName));
        }
        else {
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = fileName;
            link.click();
        }
        return blob;
    };
    return PdfViewComponent;
}());
export { PdfViewComponent };
