import { BehaviorSubject } from "rxjs";
import * as i0 from "@angular/core";
var LoginService = /** @class */ (function () {
    function LoginService() {
        this.changePassword = new BehaviorSubject(false);
        this.ignorePasswordChanging = new BehaviorSubject(false);
    }
    LoginService.prototype.showChangePassword = function () {
        this.changePassword.next(true);
    };
    LoginService.prototype.loginWithIgnorePasswordChanging = function () {
        this.ignorePasswordChanging.next(true);
    };
    LoginService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LoginService_Factory() { return new LoginService(); }, token: LoginService, providedIn: "root" });
    return LoginService;
}());
export { LoginService };
