import * as tslib_1 from "tslib";
import { EventEmitter } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { MatChipInputEvent, MatChipList, MatChipsDefaultOptions } from "@angular/material/chips";
import { LabelOptions } from "@angular/material/core";
import { MatInput } from "@angular/material/input";
import { DynamicFormControlComponent, DynamicFormLayoutService, DynamicFormValidationService, DynamicInputModel } from "@ng-dynamic-forms/core";
var DynamicMaterialChipsComponent = /** @class */ (function (_super) {
    tslib_1.__extends(DynamicMaterialChipsComponent, _super);
    function DynamicMaterialChipsComponent(layoutService, validationService, CHIPS_OPTIONS, LABEL_OPTIONS) {
        var _this = _super.call(this, layoutService, validationService) || this;
        _this.layoutService = layoutService;
        _this.validationService = validationService;
        _this.CHIPS_OPTIONS = CHIPS_OPTIONS;
        _this.LABEL_OPTIONS = LABEL_OPTIONS;
        _this.blur = new EventEmitter();
        _this.change = new EventEmitter();
        _this.customEvent = new EventEmitter();
        _this.focus = new EventEmitter();
        return _this;
    }
    Object.defineProperty(DynamicMaterialChipsComponent.prototype, "chips", {
        get: function () {
            return Array.isArray(this.model.value) ? this.model.value : [];
        },
        enumerable: true,
        configurable: true
    });
    DynamicMaterialChipsComponent.prototype.onChipInputTokenEnd = function ($event) {
        var inputElement = $event.input, inputValue = $event.value.trim();
        if (inputValue.length > 0) {
            this.control.patchValue(tslib_1.__spread(this.chips, [inputValue]));
        }
        if (inputElement instanceof HTMLInputElement) {
            inputElement.value = "";
        }
    };
    DynamicMaterialChipsComponent.prototype.onChipRemoved = function (chip, index) {
        var chips = this.chips;
        if (chips[index] === chip) {
            this.control.patchValue(chips.splice(index, 1));
        }
    };
    return DynamicMaterialChipsComponent;
}(DynamicFormControlComponent));
export { DynamicMaterialChipsComponent };
