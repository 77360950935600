import * as tslib_1 from "tslib";
import { ComponentFactoryResolver, EventEmitter, OnInit, QueryList, Type, ViewContainerRef } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { DYNAMIC_FORM_CONTROL_TYPE_ARRAY, DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX, DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX_GROUP, DYNAMIC_FORM_CONTROL_TYPE_DATEPICKER, DYNAMIC_FORM_CONTROL_TYPE_GROUP, DYNAMIC_FORM_CONTROL_TYPE_INPUT, DYNAMIC_FORM_CONTROL_TYPE_RADIO_GROUP, DYNAMIC_FORM_CONTROL_TYPE_SELECT, DYNAMIC_FORM_CONTROL_TYPE_SLIDER, DYNAMIC_FORM_CONTROL_TYPE_SWITCH, DYNAMIC_FORM_CONTROL_TYPE_TEXTAREA, DynamicFormArrayGroupModel, DynamicFormComponentService, DynamicFormControlContainerComponent, DynamicFormControlModel, DynamicFormLayoutService, DynamicFormRelationService, DynamicFormValidationService, DynamicFormValueControlModel, DynamicTemplateDirective, } from "@ng-dynamic-forms/core";
import { DynamicMaterialDatePickerComponent } from "./datepicker/dynamic-material-datepicker.component";
import { DynamicMaterialInputComponent } from "./input/dynamic-material-input.component";
import { DynamicMaterialTextAreaComponent } from "./textarea/dynamic-material-textarea.component";
import { DynamicMaterialSlideToggleComponent } from "./slide-toggle/dynamic-material-slide-toggle.component";
import { DynamicMaterialCheckboxComponent } from "./checkbox/dynamic-material-checkbox.component";
import { DynamicMaterialSliderComponent } from "./slider/dynamic-material-slider.component";
import { DynamicMaterialRadioGroupComponent } from "./radio-group/dynamic-material-radio-group.component";
import { DynamicMaterialChipsComponent } from "./chips/dynamic-material-chips.component";
import { DynamicMaterialSelectComponent } from "./select/dynamic-material-select.component";
import { DynamicMaterialFormArrayComponent } from "./form-array/dynamic-material-form-array.component";
import { DynamicMaterialFormGroupComponent } from "./form-group/dynamic-material-form-group.component";
import { DynamicMoneyComponent } from "./money/dynamic-money.component";
import { DatepickerCustompropsComponent } from "./datepicker-customprops/datepicker-customprops.component";
import { DatepickerCustomComponent } from "./datepicker-custom/datepicker-custom.component";
import { DatepickerCheckboxComponent } from "./datepicker-checkbox/datepicker-checkbox.component";
import { DynamicMaterialTextEditorComponent } from "./text-editor/dynamic-material-text-editor.component";
import { AuthenticationService } from "../../app/auth/_services/authentication.service";
var DynamicMaterialFormControlContainerComponent = /** @class */ (function (_super) {
    tslib_1.__extends(DynamicMaterialFormControlContainerComponent, _super);
    function DynamicMaterialFormControlContainerComponent(componentFactoryResolver, layoutService, validationService, componentService, relationService, authService) {
        var _this = _super.call(this, componentFactoryResolver, layoutService, validationService, componentService, relationService) || this;
        _this.componentFactoryResolver = componentFactoryResolver;
        _this.layoutService = layoutService;
        _this.validationService = validationService;
        _this.componentService = componentService;
        _this.relationService = relationService;
        _this.authService = authService;
        _this.context = null;
        _this.blur = new EventEmitter();
        _this.change = new EventEmitter();
        _this.focus = new EventEmitter();
        _this.customEvent = new EventEmitter();
        return _this;
    }
    Object.defineProperty(DynamicMaterialFormControlContainerComponent.prototype, "componentType", {
        get: function () {
            return this.componentService.getCustomComponentType(this.model) || materialUIFormControlMapFn(this.model);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DynamicMaterialFormControlContainerComponent.prototype, "hasMatFormField", {
        get: function () {
            var _this = this;
            var matFormFieldTypes = [DYNAMIC_FORM_CONTROL_TYPE_DATEPICKER, DYNAMIC_FORM_CONTROL_TYPE_INPUT,
                DYNAMIC_FORM_CONTROL_TYPE_SELECT, DYNAMIC_FORM_CONTROL_TYPE_TEXTAREA, "MONEY", 'customDatePicker', 'customDatePickerV1', "DATE_CHECK_BOX"];
            return matFormFieldTypes.some(function (type) { return _this.model.type === type; }) || (this.model instanceof DynamicFormValueControlModel &&
                this.model.getAdditional("isFormFieldControl"));
        },
        enumerable: true,
        configurable: true
    });
    DynamicMaterialFormControlContainerComponent.prototype.isDirty = function () {
        if (this.model.isDirty != undefined)
            return this.model.isDirty();
    };
    DynamicMaterialFormControlContainerComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.model.valueUpdates !== undefined) {
            this.model.valueUpdates.subscribe(function (value) {
                if (value == null)
                    return;
                if (typeof value === 'string' && !value.trim().length) {
                    _this.model.valueUpdates.next(null);
                }
            });
        }
        if (!this.model["_config"])
            return;
        if (this.model["_config"].editableFor) {
            this.setReadOnly();
        }
        if (this.model["_config"].visibleFor) {
            this.setHidden();
        }
    };
    DynamicMaterialFormControlContainerComponent.prototype.getClass = function (context, place, model) {
        var classList = _super.prototype.getClass.call(this, context, place, model);
        if (this.model["_config"] && !this.hasAccess(this.model["_config"].visibleFor)) {
            classList += " visually-hidden";
        }
        return classList;
    };
    DynamicMaterialFormControlContainerComponent.prototype.setReadOnly = function () {
        var _this = this;
        var makeReadOnly = function () {
            if (!_this.hasAccess(_this.model["_config"].editableFor)) {
                _this.model.disabledUpdates.next(true);
                _this.model.disabled = true;
                _this.model._disabled = true;
                if (_this.model.hiddenDatePicker != undefined) {
                    _this.model.hiddenDatePicker = true;
                    _this.model.hiddenClearIco = true;
                }
                _this.model.disabledUpdates.subscribe(function (val) {
                    if (!val) {
                        _this.model.disabledUpdates.next(true);
                    }
                });
            }
        };
        if (this.authService.roles)
            makeReadOnly();
        this.componentSubscriptions.push(this.authService.rolesObservable.subscribe(function () {
            makeReadOnly();
        }));
    };
    DynamicMaterialFormControlContainerComponent.prototype.setHidden = function () {
        var _this = this;
        var removeControls = function () {
            if (!_this.hasAccess(_this.model["_config"].visibleFor)) {
                _this.componentViewContainerRef.clear();
            }
        };
        if (this.authService.roles)
            removeControls();
        this.componentSubscriptions.push(this.authService.rolesObservable.subscribe(function () {
            removeControls();
        }));
    };
    DynamicMaterialFormControlContainerComponent.prototype.hasAccess = function (roles) {
        return !roles ||
            (this.authService.roles && this.authService.roles.find(function (role) { return roles.indexOf(role) != -1; }));
    };
    return DynamicMaterialFormControlContainerComponent;
}(DynamicFormControlContainerComponent));
export { DynamicMaterialFormControlContainerComponent };
export function materialUIFormControlMapFn(model) {
    switch (model.type) {
        case DYNAMIC_FORM_CONTROL_TYPE_ARRAY:
            return DynamicMaterialFormArrayComponent;
        case DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX:
            return DynamicMaterialCheckboxComponent;
        case DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX_GROUP:
            return DynamicMaterialFormGroupComponent;
        case DYNAMIC_FORM_CONTROL_TYPE_DATEPICKER:
            return DynamicMaterialDatePickerComponent;
        case DYNAMIC_FORM_CONTROL_TYPE_GROUP:
            return DynamicMaterialFormGroupComponent;
        case DYNAMIC_FORM_CONTROL_TYPE_INPUT:
            var inputModel = model;
            return inputModel.multiple ? DynamicMaterialChipsComponent : DynamicMaterialInputComponent;
        case DYNAMIC_FORM_CONTROL_TYPE_RADIO_GROUP:
            return DynamicMaterialRadioGroupComponent;
        case DYNAMIC_FORM_CONTROL_TYPE_SELECT:
            return DynamicMaterialSelectComponent;
        case DYNAMIC_FORM_CONTROL_TYPE_SLIDER:
            return DynamicMaterialSliderComponent;
        case DYNAMIC_FORM_CONTROL_TYPE_SWITCH:
            return DynamicMaterialSlideToggleComponent;
        case DYNAMIC_FORM_CONTROL_TYPE_TEXTAREA:
            return DynamicMaterialTextAreaComponent;
        case 'MONEY':
            return DynamicMoneyComponent;
        case 'customDatePicker':
            return DatepickerCustompropsComponent;
        case 'customDatePickerV1':
            return DatepickerCustomComponent;
        case 'DATE_CHECK_BOX':
            return DatepickerCheckboxComponent;
        case 'TEXT_EDITOR':
            return DynamicMaterialTextEditorComponent;
        default:
            return null;
    }
}
