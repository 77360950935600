import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { DynamicFormService } from "@ng-dynamic-forms/core";
import { DATE_PICKER } from "../../../../../../../@util/dynamic-form/dynamic-controlls";
import { FormValueBuilder } from "../../../../../partial/forms/form-tamplate";
import { MedicalService } from "../../../../../../@core/medical.service";
import { DynamicForm } from "../../../../../../../@util/dynamic-form/dynamic-form";
import { DynamicFormGroupModel, DynamicTextAreaModel } from "../../../../../../../@util/ui-material/form-controls";
import { GlobalSaveService } from "../../../../../../@core/global-save.service";
import { UserInfoService } from "../../../../../../@core/user-info.service";
import { PERMISSIONS } from 'src/app/@config/permissions';
var OtherTreatmentComponent = /** @class */ (function (_super) {
    tslib_1.__extends(OtherTreatmentComponent, _super);
    function OtherTreatmentComponent(medicalService, globalSaveService, userInfoService, formService) {
        var _this = _super.call(this) || this;
        _this.medicalService = medicalService;
        _this.globalSaveService = globalSaveService;
        _this.userInfoService = userInfoService;
        _this.formService = formService;
        _this.frameTitle = 'Visit Notes';
        _this.backForm = new EventEmitter();
        _this.nextForm = new EventEmitter();
        _this.PERMISSIONS = PERMISSIONS;
        _this.formLayout = {
            other: {
                element: {
                    host: 'col-12',
                    control: 'row'
                }
            },
            treatments: {
                element: {
                    label: 'form-label form-label_full-width',
                    container: 'row-mb column-items',
                }
            },
            discussion: {
                element: {
                    label: 'form-label form-label_full-width',
                    container: 'row-mb column-items',
                }
            },
            docResponse: {
                element: {
                    label: 'form-label form-label_full-width',
                    container: 'row-mb column-items',
                }
            },
            hospitalizationReason: {
                element: {
                    label: 'form-label form-label_full-width',
                    container: 'row-mb column-items',
                }
            },
            medicalConditions: {
                element: {
                    label: 'form-label form-label_full-width',
                    container: 'row-mb column-items',
                }
            },
            treatmentDetails: {
                element: {
                    label: 'form-label form-label_full-width',
                    container: 'row-mb column-items',
                }
            },
            treatedDoctor: {
                element: {
                    label: 'form-label form-label_full-width',
                    container: 'row-mb column-items',
                }
            },
            visitReason3: {
                element: {
                    host: 'col-12',
                    label: 'form-label form-label_full-width',
                    container: 'row-mb column-items',
                }
            },
            treatmentDetails1: {
                element: {
                    host: 'col-12',
                    label: 'form-label form-label_full-width',
                    container: 'row-mb column-items',
                }
            },
            nextVisit1: {
                element: {
                    label: 'form-label',
                    host: 'col-12 col-lg-4'
                }
            },
        };
        _this.formOther = {
            treatmentReason: DynamicTextAreaModel({ id: 'visitReason3', label: 'Reason for this Visit or these services', maxLength: 512, editableFor: PERMISSIONS.LOWER_EXEC, }),
            treatmentDetails: DynamicTextAreaModel({
                id: 'treatmentDetails1',
                label: 'Details of Treatment or services provided',
                maxLength: 512, editableFor: PERMISSIONS.LOWER_EXEC,
            }),
            nextVisit: DATE_PICKER({ id: 'nextVisit1', label: 'Next Visit', editableFor: PERMISSIONS.LOWER_EXEC, }),
        };
        _this.form = {
            other: DynamicFormGroupModel({
                id: 'other',
                group: FormValueBuilder.buildFormModel(_this.formOther), editableFor: PERMISSIONS.LOWER_EXEC,
            }),
        };
        _this.formModel = FormValueBuilder.buildFormModel(_this.form);
        _this.formGroup = formService.createFormGroup(_this.formModel);
        return _this;
    }
    OtherTreatmentComponent.prototype.ngOnInit = function () {
        var _this = this;
        FormValueBuilder.resetToDefault(this.form);
        this.formGroup.valueChanges.subscribe(function (j) {
            _this.formStateChanged.emit({ key: _this.formKey, value: FormValueBuilder.isDirty(_this.form) });
        });
    };
    OtherTreatmentComponent.prototype.saveVisitNotes = function () {
        var rez = FormValueBuilder.getData(this.formOther);
        // await this.medicalService.saveStepTwo(this.sslgId, this._visitId, rez).toPromise();
        console.log(rez);
        return rez;
    };
    OtherTreatmentComponent.prototype.ngOnDestroy = function () {
        this.formStateChanged.next({ value: false, key: this.formKey });
    };
    OtherTreatmentComponent.prototype.isClaimant = function () {
        if (this.userInfoService.getCurrentUserRole() == 'CLIENT') {
            return true;
        }
        return false;
    };
    OtherTreatmentComponent.prototype.nextHospitalsVisitNotes = function () {
        this.nextForm.emit(this.saveVisitNotes());
    };
    OtherTreatmentComponent.prototype.backHospitalsVisitNotes = function () {
        this.backForm.emit(this.saveVisitNotes());
    };
    return OtherTreatmentComponent;
}(DynamicForm));
export { OtherTreatmentComponent };
