import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { DynamicFormService } from "@ng-dynamic-forms/core";
import { DATE_PICKER } from "../../../../../../../@util/dynamic-form/dynamic-controlls";
import { FormValueBuilder } from "../../../../../partial/forms/form-tamplate";
import { MedicalService } from "../../../../../../@core/medical.service";
import { DynamicForm } from "../../../../../../../@util/dynamic-form/dynamic-form";
import { DynamicFormGroupModel, DynamicTextAreaModel } from "../../../../../../../@util/ui-material/form-controls";
import { GlobalSaveService } from "../../../../../../@core/global-save.service";
import { UserInfoService } from "../../../../../../@core/user-info.service";
import { PERMISSIONS } from 'src/app/@config/permissions';
var HospitalsVisitNotesComponent = /** @class */ (function (_super) {
    tslib_1.__extends(HospitalsVisitNotesComponent, _super);
    function HospitalsVisitNotesComponent(medicalService, globalSaveService, userInfoService, formService) {
        var _this = _super.call(this) || this;
        _this.medicalService = medicalService;
        _this.globalSaveService = globalSaveService;
        _this.userInfoService = userInfoService;
        _this.formService = formService;
        _this.frameTitle = 'Visit Notes';
        _this.backForm = new EventEmitter();
        _this.nextForm = new EventEmitter();
        _this.isAnotherVisit = false;
        _this.PERMISSIONS = PERMISSIONS;
        _this.formLayout = {
            doctor: {
                element: {
                    host: 'col-12'
                }
            },
            hospital: {
                element: {
                    host: 'col-12',
                    control: 'row',
                    children: 'col-lg-6 col-12'
                }
            },
            other: {
                element: {
                    host: 'col-12',
                    control: 'row'
                }
            },
            treatments: {
                element: {
                    label: 'form-label form-label_full-width',
                    container: 'row-mb column-items',
                }
            },
            discussion: {
                element: {
                    label: 'form-label form-label_full-width',
                    container: 'row-mb column-items',
                }
            },
            docResponse: {
                element: {
                    label: 'form-label form-label_full-width',
                    container: 'row-mb column-items',
                }
            },
            hospitalizationReason: {
                element: {
                    label: 'form-label form-label_full-width',
                    container: 'row-mb column-items',
                }
            },
            medicalConditions: {
                element: {
                    label: 'form-label form-label_full-width',
                    container: 'row-mb column-items',
                }
            },
            treatmentDetails: {
                element: {
                    label: 'form-label form-label_full-width',
                    container: 'row-mb column-items',
                }
            },
            treatedDoctor: {
                element: {
                    label: 'form-label form-label_full-width',
                    container: 'row-mb column-items',
                }
            },
            visitReason3: {
                element: {
                    host: 'col-12',
                    label: 'form-label form-label_full-width',
                    container: 'row-mb column-items',
                }
            },
            treatmentDetails1: {
                element: {
                    host: 'col-12',
                    label: 'form-label form-label_full-width',
                    container: 'row-mb column-items',
                }
            },
            nextVisit1: {
                element: {
                    label: 'form-label',
                    host: 'col-12 col-lg-4'
                }
            },
        };
        _this.formDoctor = {
            treatments: DynamicTextAreaModel({
                id: 'treatments',
                label: 'What treatment did you receive on this visit?',
                maxLength: 512, editableFor: PERMISSIONS.LOWER_EXEC,
            }),
            discussion: DynamicTextAreaModel({
                id: 'discussion',
                label: 'What did you discuss with your doctor?',
                maxLength: 512, editableFor: PERMISSIONS.LOWER_EXEC,
            }),
            docResponse: DynamicTextAreaModel({ id: 'docResponse', label: "Doctor's Response?", maxLength: 512, editableFor: PERMISSIONS.LOWER_EXEC, }),
        };
        _this.formHospital = {
            hospitalizationReason: DynamicTextAreaModel({
                id: 'hospitalizationReason',
                label: 'Reason for Hospitalization',
                maxLength: 512, editableFor: PERMISSIONS.LOWER_EXEC,
            }),
            medicalConditions: DynamicTextAreaModel({
                id: 'medicalConditions',
                label: 'Medical Conditions or Diagnoses',
                maxLength: 512, editableFor: PERMISSIONS.LOWER_EXEC,
            }),
            treatmentDetails: DynamicTextAreaModel({
                id: 'treatmentDetails',
                label: 'Details of Treatment',
                maxLength: 512, editableFor: PERMISSIONS.LOWER_EXEC,
            }),
            treatedDoctor: DynamicTextAreaModel({ id: 'treatedDoctor', label: 'If you know the names of the doctors who treated you, list them here', maxLength: 512, editableFor: PERMISSIONS.LOWER_EXEC, }),
        };
        _this.formOther = {
            visitReason: DynamicTextAreaModel({ id: 'visitReason3', label: 'Reason for Visit', maxLength: 512, editableFor: PERMISSIONS.LOWER_EXEC, }),
            treatmentDetails: DynamicTextAreaModel({
                id: 'treatmentDetails1',
                label: 'Details of Treatment',
                maxLength: 512, editableFor: PERMISSIONS.LOWER_EXEC,
            }),
            nextVisit: DATE_PICKER({ id: 'nextVisit1', label: 'Next Visit', editableFor: PERMISSIONS.LOWER_EXEC, }),
        };
        _this.form = {
            hospital: DynamicFormGroupModel({
                id: 'hospital',
                group: FormValueBuilder.buildFormModel(_this.formHospital), editableFor: PERMISSIONS.LOWER_EXEC,
            })
        };
        _this.formModel = FormValueBuilder.buildFormModel(_this.form);
        _this.formGroup = formService.createFormGroup(_this.formModel);
        return _this;
    }
    HospitalsVisitNotesComponent.prototype.ngOnInit = function () {
        var _this = this;
        FormValueBuilder.resetToDefault(this.form);
        this.formGroup.valueChanges.subscribe(function (j) {
            _this.formStateChanged.emit({ key: _this.formKey, value: FormValueBuilder.isDirty(_this.form) });
        });
    };
    HospitalsVisitNotesComponent.prototype.saveVisitNotes = function () {
        var rez = FormValueBuilder.getData(this.formHospital);
        // await this.medicalService.saveStepTwo(this.sslgId, this._visitId, rez).toPromise();
        console.log(rez);
        return rez;
    };
    HospitalsVisitNotesComponent.prototype.ngOnDestroy = function () {
        this.formStateChanged.next({ value: false, key: this.formKey });
    };
    HospitalsVisitNotesComponent.prototype.isClaimant = function () {
        if (this.userInfoService.getCurrentUserRole() == 'CLIENT') {
            return true;
        }
        return false;
    };
    HospitalsVisitNotesComponent.prototype.nextHospitalsVisitNotes = function () {
        this.nextForm.emit(this.saveVisitNotes());
    };
    HospitalsVisitNotesComponent.prototype.backHospitalsVisitNotes = function () {
        this.backForm.emit(this.saveVisitNotes());
    };
    return HospitalsVisitNotesComponent;
}(DynamicForm));
export { HospitalsVisitNotesComponent };
