import * as i0 from "@angular/core";
var PhoneService = /** @class */ (function () {
    function PhoneService() {
    }
    PhoneService.prototype.format = function (phoneNumberString) {
        var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
        var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return '(' + match[1] + ') ' + match[2] + '-' + match[3];
        }
        return null;
    };
    ;
    PhoneService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PhoneService_Factory() { return new PhoneService(); }, token: PhoneService, providedIn: "root" });
    return PhoneService;
}());
export { PhoneService };
