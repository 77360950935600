import { DYNAMIC_FORM_CONTROL_INPUT_TYPE_PASSWORD, DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEXT, } from '@ng-dynamic-forms/core';
import { STATES } from '../../app/@config/state';
import "../ui-material/extensions";
import { DatepickerCustomModel, DatepickerCustompropsModel, DynamicCheckboxModel, DynamicInputAutocompleteModel, DynamicInputModel, DynamicMoneyInputModel, DynamicRadioGroupModel, DynamicSelectModel } from "../ui-material/form-controls";
export function RADIO(_a) {
    var id = _a.id, label = _a.label, layout = _a.layout, value = _a.value, visibleFor = _a.visibleFor, editableFor = _a.editableFor;
    return DynamicRadioGroupModel({
        id: id,
        // legend: "Active Web User",
        editableFor: editableFor,
        visibleFor: visibleFor,
        label: label,
        required: true,
        options: [
            {
                label: 'YES',
                value: true
            },
            {
                label: 'NO',
                value: false
            }
        ],
        validators: {
            required: null
        },
        errorMessages: {
            required: 'Required'
        },
        value: value !== undefined ? value : true,
    }, layout);
}
export function USER_NAME_CLAIMANT(_a) {
    var id = _a.id, label = _a.label, tabIndex = _a.tabIndex, layout = _a.layout, editableFor = _a.editableFor, visibleFor = _a.visibleFor;
    return DynamicInputModel({
        editableFor: editableFor,
        visibleFor: visibleFor,
        id: id,
        label: label ? label : 'Username',
        tabIndex: tabIndex ? tabIndex : 0,
        value: null,
        required: true,
        disabled: true,
        autoComplete: 'off',
        validators: {
            required: null,
            minLength: 3,
            maxLength: 16
        },
        errorMessages: {
            required: 'Required'
        }
    }, layout);
}
export function USER_NAME(_a) {
    var id = _a.id, label = _a.label, tabIndex = _a.tabIndex, layout = _a.layout, editableFor = _a.editableFor, visibleFor = _a.visibleFor;
    return DynamicInputModel({
        editableFor: editableFor,
        visibleFor: visibleFor,
        id: id,
        label: label ? label : 'Username',
        tabIndex: tabIndex ? tabIndex : 0,
        value: null,
        required: true,
        autoComplete: 'off',
        validators: {
            required: null,
            minLength: 3,
            maxLength: 16
        },
        errorMessages: {
            required: 'Required'
        }
    }, layout);
}
export function PASSWORD(_a) {
    var id = _a.id, label = _a.label, tabIndex = _a.tabIndex, layout = _a.layout, editableFor = _a.editableFor, visibleFor = _a.visibleFor;
    return DynamicInputModel({
        editableFor: editableFor,
        visibleFor: visibleFor,
        id: id,
        label: label ? label : 'Password hidden',
        tabIndex: tabIndex ? tabIndex : 0,
        inputType: DYNAMIC_FORM_CONTROL_INPUT_TYPE_PASSWORD,
        value: null,
        required: true,
        autoComplete: 'off',
        validators: {
            required: null,
            minLength: 6,
            maxLength: 16
        },
        errorMessages: {
            required: 'Required',
            minLength: 'Min Length is 6',
            maxLength: 'Max Length is 16',
        },
        placeholder: "******",
    }, layout);
}
export function ROLE(_a) {
    var id = _a.id, roles = _a.roles, label = _a.label, layout = _a.layout, editableFor = _a.editableFor, visibleFor = _a.visibleFor;
    return DynamicSelectModel({
        id: id,
        label: label ? label : 'Role',
        visibleFor: visibleFor,
        editableFor: editableFor,
        options: roles.map(function (j) {
            return {
                label: j,
                value: j
            };
        }),
        value: null
    }, layout);
}
export function GENDER(_a) {
    var editableFor = _a.editableFor, visibleFor = _a.visibleFor;
    return DynamicSelectModel({
        id: 'gender',
        label: 'Gender',
        editableFor: editableFor,
        visibleFor: visibleFor,
        options: [
            {
                label: 'Female',
                value: 'female',
            },
            {
                label: 'Male',
                value: 'male',
            }
        ],
        value: 'female'
    });
}
export function DATE_PICKER_CUSTOM(_a) {
    var id = _a.id, label = _a.label, value = _a.value, required = _a.required, playsholder = _a.playsholder, layout = _a.layout, min = _a.min, max = _a.max, tabIndex = _a.tabIndex, hiddenClearIco = _a.hiddenClearIco, hiddenDatePicker = _a.hiddenDatePicker, hidden = _a.hidden, editableFor = _a.editableFor, visibleFor = _a.visibleFor;
    return DatepickerCustomModel({
        id: id,
        editableFor: editableFor,
        visibleFor: visibleFor,
        inline: false,
        label: label,
        value: value,
        readOnly: true,
        min: min,
        max: max,
        format: 'long',
        //format: 'YYYY-MM-DD',
        tabIndex: tabIndex ? tabIndex : 0,
        required: required,
        placeholder: playsholder,
        validators: required ? { required: null } : undefined,
        hiddenDatePicker: hiddenDatePicker,
        hidden: hidden,
        errorMessages: {
            required: 'Required'
        },
        hiddenClearIco: hiddenClearIco ? hiddenClearIco : false,
    }, layout);
}
export function DATE_PICKER(_a) {
    var id = _a.id, label = _a.label, value = _a.value, required = _a.required, playsholder = _a.playsholder, layout = _a.layout, tabIndex = _a.tabIndex, hiddenClearIco = _a.hiddenClearIco, hiddenDatePicker = _a.hiddenDatePicker, hidden = _a.hidden, editableFor = _a.editableFor, visibleFor = _a.visibleFor, disabled = _a.disabled;
    return DatepickerCustompropsModel({
        id: id,
        editableFor: editableFor,
        visibleFor: visibleFor,
        inline: false,
        label: label,
        value: value,
        readOnly: true,
        format: 'long',
        //format: 'YYYY-MM-DD',
        tabIndex: tabIndex ? tabIndex : 0,
        required: required,
        placeholder: playsholder,
        validators: required ? { required: null } : undefined,
        hiddenDatePicker: hiddenDatePicker,
        hidden: hidden,
        errorMessages: {
            required: 'Required'
        },
        hiddenClearIco: hiddenClearIco ? hiddenClearIco : false,
        disabled: disabled ? disabled : false,
    }, layout);
}
export function FIRST_NAME(_a) {
    var id = _a.id, label = _a.label, tabIndex = _a.tabIndex, layout = _a.layout, editableFor = _a.editableFor, visibleFor = _a.visibleFor;
    return STRING_FIELD({
        id: 'firstName',
        editableFor: editableFor,
        visibleFor: visibleFor,
        label: label ? label : 'First Name',
        tabIndex: tabIndex ? tabIndex : 0,
        required: true,
        maxLength: 25,
        inputType: DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEXT,
        validators: {
            maxLength: 25,
            required: null
        }
    }, layout);
}
export function LAST_NAME(id, label, tabIndex, layout, editableFor, visibleFor) {
    var lastName = STRING_FIELD({
        id: id,
        editableFor: editableFor,
        visibleFor: visibleFor,
        label: label ? label : 'Last Name',
        tabIndex: tabIndex ? tabIndex : 0,
        required: true,
        maxLength: 25,
        inputType: DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEXT,
        validators: {
            maxLength: 25,
            required: null
        }
    }, layout);
    lastName.valueUpdates.subscribe(function (j) {
        if (j.toUpperCase() !== j) {
            setTimeout(function () {
                lastName.valueUpdates.next(j.toUpperCase());
            });
        }
    });
    return lastName;
}
export function MIDDLE_NAME(_a) {
    var editableFor = _a.editableFor, visibleFor = _a.visibleFor;
    return STRING_FIELD({
        id: 'middleName',
        label: 'Middle Name',
        editableFor: editableFor,
        visibleFor: visibleFor,
        maxLength: 16,
        inputType: DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEXT,
        validators: {
            maxLength: 16,
        }
    });
}
export function EMAIL(id, label, layout, editableFor, visibleFor) {
    return DynamicInputModel({
        id: id,
        label: label ? label : 'Email',
        autoComplete: 'off',
        editableFor: editableFor,
        visibleFor: visibleFor,
        maxLength: 128,
        validators: {
            emailValidator: null
        },
        errorMessages: {
            emailValidator: 'Field has no valid email'
        }
    }, layout);
}
export function EMAIL_REQUIRED(id, label, editableFor, visibleFor) {
    var input = EMAIL(id, label, null, editableFor, visibleFor);
    input.required = true;
    input.validators = {
        emailValidator: null,
        required: null
    };
    input.errorMessages = {
        required: 'Required',
        emailValidator: 'Field has no valid email'
    };
    return input;
}
export function PHONE(_a) {
    var id = _a.id, label = _a.label, layout = _a.layout, disabled = _a.disabled, visibleFor = _a.visibleFor, editableFor = _a.editableFor;
    var phone = STRING_FIELD({
        id: id,
        editableFor: editableFor,
        visibleFor: visibleFor,
        label: label,
        inputType: 'tel',
        disabled: disabled,
        mask: '(000)000-0000'
    }, layout);
    return phone;
}
export function DATE_MANUAL(_a) {
    var id = _a.id, label = _a.label, layout = _a.layout, disabled = _a.disabled, visibleFor = _a.visibleFor, editableFor = _a.editableFor;
    var date = STRING_FIELD({
        id: id,
        editableFor: editableFor,
        visibleFor: visibleFor,
        label: label,
        inputType: 'tel',
        disabled: disabled,
        placeholder: 'mm/dd/yyyy',
        mask: '00/00/0000',
    }, layout);
    return date;
}
export function PRESS(_a) {
    var id = _a.id, label = _a.label, layout = _a.layout, disabled = _a.disabled, visibleFor = _a.visibleFor, editableFor = _a.editableFor;
    var press = STRING_FIELD({
        id: id,
        editableFor: editableFor,
        visibleFor: visibleFor,
        label: label,
        inputType: 'tel',
        maxLength: 10,
        disabled: disabled,
        mask: '0000000000'
    }, layout);
    return press;
}
export function MONTH_YEAR(_a) {
    var id = _a.id, label = _a.label, layout = _a.layout, disabled = _a.disabled, visibleFor = _a.visibleFor, editableFor = _a.editableFor;
    var monthYear = STRING_FIELD({
        id: id,
        editableFor: editableFor,
        visibleFor: visibleFor,
        label: label,
        inputType: 'text',
        maxLength: 7,
        disabled: disabled,
        placeholder: 'mm/yyyy',
        mask: '00/0000'
    }, layout);
    return monthYear;
}
export function EXT(_a) {
    var id = _a.id, label = _a.label, layout = _a.layout, disabled = _a.disabled, visibleFor = _a.visibleFor, editableFor = _a.editableFor;
    var ext = STRING_FIELD({
        id: id,
        editableFor: editableFor,
        visibleFor: visibleFor,
        label: label,
        inputType: 'tel',
        maxLength: 10,
        disabled: disabled,
        mask: '0000000000'
    }, layout);
    return ext;
}
export function INSTAGRAM(layout, editableFor, visibleFor) {
    return STRING_FIELD({
        id: 'instagram',
        label: 'Instagram',
        editableFor: editableFor,
        visibleFor: visibleFor,
        maxLength: 254,
        inputType: DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEXT,
        validators: {
            maxLength: 254
        }
    }, layout);
}
export function TWITTER(layout, editableFor, visibleFor) {
    return STRING_FIELD({
        id: 'twitter',
        label: 'Twitter',
        editableFor: editableFor,
        visibleFor: visibleFor,
        maxLength: 254,
        inputType: DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEXT,
        validators: {
            maxLength: 254,
        }
    }, layout);
}
export function FACEBOOK(layout, editableFor, visibleFor) {
    return STRING_FIELD({
        id: 'facebook',
        label: 'Facebook',
        editableFor: editableFor,
        visibleFor: visibleFor,
        maxLength: 254,
        inputType: DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEXT,
        validators: {
            maxLength: 254,
        }
    }, layout);
}
export function ADDRESS(_a) {
    var id = _a.id, label = _a.label, layout = _a.layout, editableFor = _a.editableFor, visibleFor = _a.visibleFor;
    return STRING_FIELD({
        id: id,
        label: label ? label : 'Address',
        editableFor: editableFor,
        visibleFor: visibleFor,
        maxLength: 128,
        inputType: DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEXT,
    }, layout);
}
export function CYTY(_a) {
    var id = _a.id, label = _a.label, layout = _a.layout, editableFor = _a.editableFor, visibleFor = _a.visibleFor;
    return STRING_FIELD({
        id: id,
        editableFor: editableFor,
        visibleFor: visibleFor,
        label: label ? label : 'City',
        maxLength: 32,
        inputType: DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEXT
    }, layout);
}
export function ZIP(_a) {
    var id = _a.id, label = _a.label, layout = _a.layout, editableFor = _a.editableFor, visibleFor = _a.visibleFor;
    return STRING_FIELD({
        id: id,
        editableFor: editableFor,
        visibleFor: visibleFor,
        label: label ? label : 'Zip',
        maxLength: 5,
        inputType: DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEXT,
        mask: '00000'
    }, layout);
}
export function NUMERIC_FIELD(_a) {
    var id = _a.id, label = _a.label, maxLength = _a.maxLength, layout = _a.layout, editableFor = _a.editableFor, visibleFor = _a.visibleFor;
    var data = STRING_FIELD({
        id: id,
        editableFor: editableFor,
        visibleFor: visibleFor,
        label: label ? label : '',
        maxLength: maxLength,
        inputType: DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEXT,
    }, layout);
    data.valueUpdates.subscribe(function (val) {
        if (val === '')
            return;
        var newValue = checkIsNaN(val, 99999999999999999999999, 0, 0);
        if (newValue == val)
            return;
        setTimeout(function () {
            data.valueUpdates.next(newValue);
        });
    });
    return data;
}
export function STATE_SELECT(_a) {
    var id = _a.id, label = _a.label, layout = _a.layout, editableFor = _a.editableFor, visibleFor = _a.visibleFor, disabled = _a.disabled;
    var states = [{
            disabled: true,
            label: 'Choose State',
            value: null,
        }];
    STATES.forEach(function (j) {
        states.push({ disabled: undefined, label: j.name, value: j.abbreviation });
    });
    return DynamicSelectModel({
        id: id,
        label: label ? label : 'State',
        visibleFor: visibleFor,
        editableFor: editableFor,
        options: states,
        disabled: disabled,
        errorMessages: {
            required: 'Required',
        },
        value: null
    }, layout);
}
export function APT_UNIT(editableFor, visibleFor) {
    return STRING_FIELD({
        id: 'aptUnit',
        label: 'Apt/Unit',
        editableFor: editableFor,
        visibleFor: visibleFor,
        maxLength: 10,
        inputType: DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEXT,
        validators: {
            maxLength: 10,
        }
    });
}
export function CURRENCY_FILD(_a) {
    var id = _a.id, label = _a.label, layout = _a.layout, maxValue = _a.maxValue, fractionDigit = _a.fractionDigit, showFractionZero = _a.showFractionZero, suffix = _a.suffix, disabled = _a.disabled, editableFor = _a.editableFor, visibleFor = _a.visibleFor;
    return DynamicMoneyInputModel({
        id: id,
        editableFor: editableFor,
        visibleFor: visibleFor,
        label: label,
        inputMaxValue: maxValue,
        fractionDigit: fractionDigit,
        showFractionZero: showFractionZero,
        suffix: suffix,
        disabled: disabled
    }, layout);
}
export function NUMERIC_FILD(_a) {
    var id = _a.id, label = _a.label, layout = _a.layout, maxValue = _a.maxValue, type = _a.type, tabIndex = _a.tabIndex, disabled = _a.disabled, fractionDigit = _a.fractionDigit, showFractionZero = _a.showFractionZero, suffix = _a.suffix, editableFor = _a.editableFor, visibleFor = _a.visibleFor;
    var data = STRING_FIELD({
        id: id, label: label, suffix: suffix, tabIndex: tabIndex, disabled: disabled, editableFor: editableFor,
        visibleFor: visibleFor
    }, layout);
    data.valueUpdates.subscribe(function (val) {
        if (val === '')
            return;
        var newValue = checkIsNaN(val, maxValue, fractionDigit, showFractionZero);
        if (type === 'percent') {
            newValue = Number(newValue);
            if (String(newValue) === String(val) || newValue + '.' === String(val)) {
                return;
            }
        }
        else {
            if (newValue == val) {
                return;
            }
        }
        setTimeout(function () {
            data.valueUpdates.next(newValue);
        });
    });
    return data;
}
function checkIsNaN(val, maxValue, fractionDigit, showFractionZero) {
    var curValue = val;
    if (!isNaN(+val)) {
        if (maxValue && +val > maxValue) {
            curValue = maxValue;
        }
        if (curValue && curValue.indexOf && curValue.indexOf('.') > -1) {
            var dij = curValue.substr(curValue.indexOf('.'));
            if (fractionDigit && dij.length > fractionDigit) {
                dij = dij.substr(0, fractionDigit + 1);
                curValue = curValue.substr(0, curValue.indexOf('.')) + dij;
            }
            ////console.log(+dij);
            if (+dij <= 0 && fractionDigit && (dij.length == fractionDigit + 1) && !showFractionZero) {
                curValue = curValue.substr(0, curValue.indexOf('.'));
            }
        }
    }
    else {
        if (val.length > 1) {
            return checkIsNaN(val.substr(0, val.length - 1), maxValue, fractionDigit, showFractionZero);
        }
        else if (val.length === 1) {
            curValue = '';
        }
        else {
            return Error('Value length = 0, not implemented');
        }
    }
    return curValue;
}
export function STRING_FIELD(param, layout) {
    var input = DynamicInputAutocompleteModel({
        id: param.id,
        label: param.label,
        suffix: param.suffix,
        inputType: param.inputType,
        placeholder: param.placeholder,
        value: null,
        required: param.required,
        validators: param.validators,
        minLength: param.minLength,
        maxLength: param.maxLength,
        hidden: param.hidden,
        mask: param.mask,
        disabled: param.disabled,
        tabIndex: param.tabIndex ? param.tabIndex : 0,
        editableFor: param.editableFor,
        visibleFor: param.visibleFor,
        autoComplete: 'off',
        errorMessages: {
            required: 'Required',
            minLength: param.validators && param.validators.minLength ? 'Min Length is ' + param.validators.minLength : '',
            maxLength: param.validators && param.validators.maxLength ? 'Max Length is ' + param.validators.maxLength : '',
        }
    }, layout);
    return input;
}
export function CHECK_BOX(_a) {
    var id = _a.id, label = _a.label, visibleFor = _a.visibleFor, editableFor = _a.editableFor;
    return DynamicCheckboxModel({
        id: id,
        label: label,
        visibleFor: visibleFor,
        editableFor: editableFor
    });
}
export function SEARCH_CHECK_BOX(_a, layout) {
    var id = _a.id, label = _a.label, labelPosition = _a.labelPosition, visibleFor = _a.visibleFor, editableFor = _a.editableFor;
    return DynamicCheckboxModel({
        id: id,
        label: label,
        labelPosition: labelPosition,
        visibleFor: visibleFor,
        editableFor: editableFor
    }, layout);
}
export function RADIO_USER_STATE(_a) {
    var id = _a.id, label = _a.label, options = _a.options, layout = _a.layout, value = _a.value, visibleFor = _a.visibleFor, editableFor = _a.editableFor;
    return DynamicRadioGroupModel({
        id: id,
        // legend: "Active Web User",
        editableFor: editableFor,
        visibleFor: visibleFor,
        label: label,
        required: true,
        options: options,
        validators: {
            required: null
        },
        errorMessages: {
            required: 'Required'
        },
        value: value,
    }, layout);
}
