import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { MedicalService } from '../../../../../@core/medical.service';
import { ActivatedRoute } from '@angular/router';
import { NavigationService } from "../../../../../@core/navigation.service";
import { CdkDragDrop, transferArrayItem } from "@angular/cdk/drag-drop";
import { FormGroup } from '@angular/forms';
import { UserInfoService } from "../../../../../@core/user-info.service";
import { GlobalSaveService } from "../../../../../@core/global-save.service";
import { DateService } from "../../../../../@core/functions/date.service";
import { ClaimantMedicationsNewComponent } from "./claimant-medications-new/claimant-medications-new.component";
import { MatDialog } from "@angular/material";
import { PERMISSIONS } from 'src/app/@config/permissions';
var ClaimantMedicationsComponent = /** @class */ (function () {
    function ClaimantMedicationsComponent(medicalService, userInfoService, route, dialog, dateService, globalSaveService, navService) {
        var _this = this;
        this.medicalService = medicalService;
        this.userInfoService = userInfoService;
        this.route = route;
        this.dialog = dialog;
        this.dateService = dateService;
        this.globalSaveService = globalSaveService;
        this.navService = navService;
        this.displayRow = {
            Doctor: true,
            Hospital: true,
            Other: true
        };
        this.isEditMode = false;
        this.PERMISSIONS = PERMISSIONS;
        this.sub = navService.back().subscribe(function (j) { return _this.goBack(); });
        this.formGroup = new FormGroup({
        // firstName: new FormControl()
        });
    }
    ClaimantMedicationsComponent.prototype.ngOnDestroy = function () {
        this.sub.unsubscribe();
    };
    ClaimantMedicationsComponent.prototype.goBack = function () {
        this.navService.navigate(['/claimants'], ["" + this.route.snapshot.paramMap.get('sslgId')]);
    };
    ClaimantMedicationsComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.sslgId = this.route.snapshot.parent.paramMap.get('sslgId');
                        return [4 /*yield*/, this.getMedicationsData()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ClaimantMedicationsComponent.prototype.getMedicationsData = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.medicalService.getAllMedicationForClaim(this.sslgId).toPromise()];
                    case 1:
                        _a.medicationsData = _b.sent();
                        this.medicationsDataFormatted = tslib_1.__assign({}, this.medicationsData);
                        Object.keys(this.medicationsDataFormatted).forEach(function (type) {
                            Object.keys(_this.medicationsDataFormatted[type]).forEach(function (claim) {
                                var activeList = [];
                                var inactiveList = [];
                                _this.medicationsDataFormatted[type][claim].medicationsList = [];
                                _this.medicationsDataFormatted[type][claim].forEach(function (med) {
                                    med.activeList = [];
                                    med.inactiveList = [];
                                    if (med.status) {
                                        med.activeList.push(med);
                                    }
                                    else {
                                        med.inactiveList.push(med);
                                    }
                                    _this.medicationsDataFormatted[type][claim].medicationsList.push(med);
                                });
                            });
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    ClaimantMedicationsComponent.prototype.getTypes = function () {
        if (this.medicationsData) {
            return Object.getOwnPropertyNames(this.medicationsData).map(function (j) {
                return j.substr(0, 1) + j.toLocaleLowerCase().substr(1);
            }).reverse();
        }
        return [];
    };
    ClaimantMedicationsComponent.prototype.getMedications = function (type) {
        if (!type)
            return [];
        var values = this.medicationsData[type.toUpperCase()];
        return Object.values(values);
    };
    ClaimantMedicationsComponent.prototype.identify = function (index, item) {
        return item;
    };
    ClaimantMedicationsComponent.prototype.identify2 = function (index, item) {
        return item.catalogItemId;
    };
    ClaimantMedicationsComponent.prototype.identify3 = function (index, item) {
        return item.medicationName;
    };
    ClaimantMedicationsComponent.prototype.getDate = function (medication) {
        return medication.date ? new Date(medication.date).toLocaleDateString('en-Us') : null;
    };
    ClaimantMedicationsComponent.prototype.showRow = function (type) {
        this.displayRow[type] = !this.displayRow[type];
    };
    ClaimantMedicationsComponent.prototype.canShowRow = function (type) {
        return this.displayRow[type];
    };
    // compareMedicationsFn(a, b) {
    //   console.log(a, b);
    //   if (a < b) {
    //     return -1;
    //   } else if (a > b) {
    //     return 1;
    //   }
    //   // a must be equal to b
    //   return 0;
    // }
    ClaimantMedicationsComponent.prototype.dropSegments = function (event, el) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var status, date, data;
            return tslib_1.__generator(this, function (_a) {
                date = new Date();
                if (event.distance.x < 0) {
                    status = false;
                }
                else {
                    status = true;
                }
                data = {
                    id: el.medicationId,
                    status: status,
                    date: this.dateService.getDateWithoutHours(date)
                };
                el.status = status;
                el.date = date;
                transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
                return [2 /*return*/];
            });
        });
    };
    ClaimantMedicationsComponent.prototype.isDoctorType = function (type) {
        return type.toUpperCase() === 'DOCTOR';
    };
    ClaimantMedicationsComponent.prototype.isHospitalType = function (type) {
        return type.toUpperCase() === 'HOSPITAL';
    };
    ClaimantMedicationsComponent.prototype.isOtherType = function (type) {
        return type.toUpperCase() === 'OTHER';
    };
    ClaimantMedicationsComponent.prototype.isClaimant = function () {
        if (this.userInfoService.getCurrentUserRole() == 'CLIENT') {
            return true;
        }
        return false;
    };
    ClaimantMedicationsComponent.prototype.isExecutive = function () {
        if (PERMISSIONS.HIGHER_EXEC_LEVEL_4.includes(this.userInfoService.getCurrentUserRole())) {
            return true;
        }
        return false;
    };
    ClaimantMedicationsComponent.prototype.onEditMode = function () {
        this.isEditMode = true;
    };
    ClaimantMedicationsComponent.prototype.saveChanges = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        data = {
                            medications: []
                        };
                        Object.keys(this.medicationsDataFormatted).forEach(function (type) {
                            Object.keys(_this.medicationsDataFormatted[type]).forEach(function (claim) {
                                _this.medicationsDataFormatted[type][claim].forEach(function (med) {
                                    // console.log(med);
                                    data.medications.push({
                                        id: med.medicationId,
                                        status: med.status,
                                        date: med.date ? _this.dateService.getDateWithoutHours(med.date) : null
                                    });
                                });
                            });
                        });
                        return [4 /*yield*/, this.medicalService.saveAllMedications(this.sslgId, data).catch(function (data) {
                                if (data.error) {
                                    _this.getMedicationsData();
                                }
                            })];
                    case 1:
                        _a.sent();
                        this.isEditMode = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    ClaimantMedicationsComponent.prototype.changeActiveMedication = function (claimMedications, state, e) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                if (!this.isEditMode) {
                    return [2 /*return*/];
                }
                if (String(state) === String(claimMedications.status)) {
                    return [2 /*return*/];
                }
                // const oldDate = claimMedications.date;
                // const oldStatus = claimMedications.status;
                //
                // const oldActiveList = [...claimMedications.activeList];
                // const oldInactiveList = [...claimMedications.inactiveList];
                if (state) {
                    claimMedications.activeList = tslib_1.__spread(claimMedications.inactiveList);
                    claimMedications.inactiveList = [];
                }
                else {
                    claimMedications.inactiveList = tslib_1.__spread(claimMedications.activeList);
                    claimMedications.activeList = [];
                }
                // e.target.value = !e.target.value;
                claimMedications.status = state;
                claimMedications.date = new Date();
                return [2 /*return*/];
            });
        });
    };
    ClaimantMedicationsComponent.prototype.addMedication = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var dialogRef;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                document.querySelectorAll('.mat-drawer-content').forEach(function (el) {
                    el.style.overflow = 'hidden';
                });
                dialogRef = this.dialog.open(ClaimantMedicationsNewComponent, {
                    data: {
                        sslgId: this.sslgId
                    },
                    panelClass: ['mobile-popup', 'with-shadow', 'popup-scrollable'],
                    // panelClass: ['mobile-popup', 'with-shadow'],
                    backdropClass: 'mobile-popup__overlay',
                    disableClose: true
                });
                dialogRef.afterClosed().subscribe(function (result) {
                    if (result) {
                        if (result.cmd) {
                        }
                        else if (result == 'new') {
                            _this.getMedicationsData();
                        }
                    }
                });
                return [2 /*return*/];
            });
        });
    };
    return ClaimantMedicationsComponent;
}());
export { ClaimantMedicationsComponent };
