import { AfterViewInit, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import Cropper from 'cropperjs';
import { DialogService } from "../../dialog/dialog.service";
import { EditorOptions, NGX_DEFAULT_RATIOS } from "./editor-options";
var ImageEditorComponent = /** @class */ (function () {
    function ImageEditorComponent(dialog) {
        this.dialog = dialog;
        this.file = new EventEmitter();
        this.zoomIn = 0;
        this.sliderValue = 0;
        this.loading = true;
        this.canvasFillColor = '#fff';
        this.state = new EditorOptions();
    }
    Object.defineProperty(ImageEditorComponent.prototype, "config", {
        set: function (config) {
            this.state = config;
        },
        enumerable: true,
        configurable: true
    });
    ImageEditorComponent.prototype.ngOnInit = function () {
        this.handleStateConfig();
    };
    ImageEditorComponent.prototype.ngOnDestroy = function () {
        this.cropper.destroy();
    };
    ImageEditorComponent.prototype.ngAfterViewInit = function () {
        // NOTE if we don't have a file meaning that loading the image will happen synchronously we can safely
        // call initializeCropper in ngAfterViewInit. otherwise if we are using the FileReader to load a base64 image
        // we need to call onloadend asynchronously..
        if (!this.state.File && this.state.ImageUrl) {
            this.initializeCropper();
        }
    };
    ImageEditorComponent.prototype.handleStateConfig = function () {
        this.state.ImageType = this.state.ImageType ? this.state.ImageType : 'image/jpeg';
        if (this.state.ImageUrl) {
            this.state.File = null;
            this.previewImageURL = this.state.ImageUrl;
        }
        if (this.state.File) {
            this.state.ImageUrl = null;
            this.convertFileToBase64(this.state.File);
        }
        if (this.state.AspectRatios) {
            this.addRatios(this.state.AspectRatios);
        }
        else {
            this.ratios = NGX_DEFAULT_RATIOS;
        }
        if (!this.state.ImageUrl && !this.state.File) {
            console.error('Property ImageUrl or File is missing, Please provide an url or file in the config options.');
        }
        if (!this.state.ImageName) {
            console.error('Property ImageName is missing, Please provide a name for the image.');
        }
    };
    ImageEditorComponent.prototype.convertFileToBase64 = function (file) {
        var _this = this;
        var reader = new FileReader();
        reader.addEventListener('load', function (e) {
            _this.previewImageURL = e.target['result'];
        }, false);
        reader.readAsDataURL(file);
        reader.onloadend = (function () {
            // NOTE since getting the base64 image url happens asynchronously we need to initializeCropper after
            // the image has been loaded.
            _this.initializeCropper();
            setTimeout(function () {
                // ////console.log(this.cropper.getContainerData())
                var cropBoxLeft = (_this.cropper.getContainerData().width - _this.cropper.getCropBoxData().width) / 2;
                var cropBoxTop = (_this.cropper.getContainerData().height - _this.cropper.getCropBoxData().height) / 2;
                var canvasLeft = (_this.cropper.getContainerData().width - _this.cropper.getCanvasData().width) / 2;
                var canvasTop = (_this.cropper.getContainerData().height - _this.cropper.getCanvasData().height) / 2;
                _this.cropper.setCropBoxData({
                    left: cropBoxLeft,
                    top: cropBoxTop,
                    width: _this.cropper.getCropBoxData().width,
                    height: _this.cropper.getCropBoxData().height
                });
                _this.cropper.setCanvasData({
                    left: canvasLeft,
                    top: canvasTop,
                    width: _this.cropper.getCanvasData().width,
                    height: _this.cropper.getCanvasData().height
                });
                _this.cropper.setCropBoxData({
                    left: canvasLeft,
                    top: canvasTop,
                    width: _this.cropper.getCanvasData().width,
                    height: _this.cropper.getCanvasData().height
                });
                // ////console.log(this.cropper.setCropBoxData());
                // ////console.log(this.cropper.setCanvasData());
                _this.centerCanvas();
                _this.handleCrop();
            });
        });
    };
    ImageEditorComponent.prototype.addRatios = function (ratios) {
        var _this = this;
        this.ratios = [];
        ratios.forEach(function (ratioType) {
            var addedRation = NGX_DEFAULT_RATIOS.find(function (ratio) {
                return ratio.text === ratioType;
            });
            _this.ratios.push(addedRation);
        });
    };
    ImageEditorComponent.prototype.handleCrop = function () {
        var _this = this;
        this.loading = true;
        setTimeout(function () {
            _this.croppedImage = _this.cropper.getCroppedCanvas({ fillColor: _this.canvasFillColor })
                .toDataURL(_this.state.ImageType);
            setTimeout(function () {
                _this.imageWidth = _this.croppedImg.nativeElement.width;
                _this.imageHeight = _this.croppedImg.nativeElement.height;
                if (_this.imageWidth < _this.state.minWidth) {
                    _this.imageWidth = _this.state.minWidth;
                }
                if (_this.imageHeight < _this.state.minHeight) {
                    _this.imageWidth = _this.state.minHeight;
                }
                // if (this.imageWidth < this.state.minWidth || this.imageHeight < this.state.minHeight) {
                //   this.dialog
                //     .show(`Minimum Width And Height 100x100, Now Image Size ${this.imageWidth}x ${this.imageHeight}`, ['OK']);
                //   this.undoCrop();
                // }
            });
            _this.cropper.getCroppedCanvas({ fillColor: _this.canvasFillColor }).toBlob(function (blob) {
                _this.blob = blob;
            });
            _this.zoomIn = 1;
            _this.loading = false;
        });
    };
    ImageEditorComponent.prototype.undoCrop = function () {
        // this.croppedImage = null;
        // this.blob = null;
        // setTimeout(() => {
        //   this.initializeCropper();
        // });
        this.file.next(null);
    };
    ImageEditorComponent.prototype.saveImage = function () {
        this.file.emit(new File([this.blob], this.state.ImageName, { type: this.state.ImageType }));
    };
    ImageEditorComponent.prototype.initializeCropper = function () {
        var _this = this;
        this.cropper = new Cropper(this.previewImage.nativeElement, {
            zoomOnWheel: false,
            zoomable: false,
            zoomOnTouch: false,
            viewMode: 1,
            center: true,
            scalable: false,
            minCropBoxHeight: 100,
            minCropBoxWidth: 100,
            ready: function () { return _this.loading = false; },
            dragMode: 'move',
            crop: function (e) {
                // if (!(Math.round(e.detail.height) < this.state.minHeight || Math.round(e.detail.width) < this.state.minWidth)) {
                _this.imageHeight = Math.round(e.detail.height);
                _this.imageWidth = Math.round(e.detail.width);
                _this.cropBoxWidth = Math.round(_this.cropper.getCropBoxData().width);
                _this.cropBoxHeight = Math.round(_this.cropper.getCropBoxData().height);
                _this.canvasWidth = Math.round(_this.cropper.getCanvasData().width);
                _this.canvasHeight = Math.round(_this.cropper.getCanvasData().height);
                // }
            }
        });
        this.setRatio(this.ratios[0].value);
    };
    ImageEditorComponent.prototype.setRatio = function (value) {
        this.cropper.setAspectRatio(value);
    };
    ImageEditorComponent.prototype.zoomChange = function (input, zoom) {
        if (this.croppedImage) {
            if (zoom) {
                zoom === 'zoomIn' ? this.zoomIn += 0.1 : this.zoomIn -= 0.1;
            }
            else {
                if (input < this.sliderValue) {
                    this.zoomIn = -Math.abs(input / 100);
                }
                else {
                    this.zoomIn = Math.abs(input / 100);
                }
            }
            if (this.zoomIn <= 0.1) {
                this.zoomIn = 0.1;
            }
        }
        else {
            if (zoom) {
                this.cropper.zoom(input);
                this.zoomIn = input;
            }
            else {
                if (input < this.sliderValue) {
                    this.cropper.zoom(-Math.abs(input / 100));
                }
                else {
                    this.cropper.zoom(Math.abs(input / 100));
                }
                if (input === 0) {
                    this.cropper.zoom(-1);
                }
            }
        }
        if (!zoom) {
            this.sliderValue = input;
        }
        else {
            input > 0 ? this.sliderValue += Math.abs(input * 100) : this.sliderValue -= Math.abs(input * 100);
        }
        if (this.sliderValue < 0) {
            this.sliderValue = 0;
        }
    };
    ImageEditorComponent.prototype.setImageWidth = function (canvasWidth) {
        if (canvasWidth) {
            this.cropper.setCanvasData({
                left: this.cropper.getCanvasData().left,
                top: this.cropper.getCanvasData().top,
                width: Math.round(canvasWidth),
                height: this.cropper.getCanvasData().height
            });
        }
    };
    ImageEditorComponent.prototype.setImageHeight = function (canvasHeight) {
        if (canvasHeight) {
            this.cropper.setCanvasData({
                left: this.cropper.getCanvasData().left,
                top: this.cropper.getCanvasData().top,
                width: this.cropper.getCanvasData().width,
                height: Math.round(canvasHeight)
            });
        }
    };
    ImageEditorComponent.prototype.setCropBoxWidth = function (cropBoxWidth) {
        if (cropBoxWidth && this.cropper.getCropBoxData().width >= cropBoxWidth) {
            this.cropper.setCropBoxData({
                left: this.cropper.getCropBoxData().left,
                top: this.cropper.getCropBoxData().top,
                width: Math.round(cropBoxWidth),
                height: this.cropper.getCropBoxData().height
            });
        }
        else {
            this.setCropBoxWidth(cropBoxWidth - 1);
        }
    };
    ImageEditorComponent.prototype.setCropBoxHeight = function (cropBoxHeight) {
        if (cropBoxHeight && this.cropper.getCropBoxData().height >= cropBoxHeight) {
            this.cropper.setCropBoxData({
                left: this.cropper.getCropBoxData().left,
                top: this.cropper.getCropBoxData().top,
                width: this.cropper.getCropBoxData().width,
                height: Math.round(cropBoxHeight)
            });
        }
        else {
            this.setCropBoxHeight(cropBoxHeight - 1);
        }
    };
    ImageEditorComponent.prototype.centerCanvas = function () {
        var cropBoxLeft = (this.cropper.getContainerData().width - this.cropper.getCropBoxData().width) / 2;
        var cropBoxTop = (this.cropper.getContainerData().height - this.cropper.getCropBoxData().height) / 2;
        var canvasLeft = (this.cropper.getContainerData().width - this.cropper.getCanvasData().width) / 2;
        var canvasTop = (this.cropper.getContainerData().height - this.cropper.getCanvasData().height) / 2;
        this.cropper.setCropBoxData({
            left: cropBoxLeft,
            top: cropBoxTop,
            width: this.cropper.getCropBoxData().width,
            height: this.cropper.getCropBoxData().height
        });
        this.cropper.setCanvasData({
            left: canvasLeft,
            top: canvasTop,
            width: this.cropper.getCanvasData().width,
            height: this.cropper.getCanvasData().height
        });
    };
    return ImageEditorComponent;
}());
export { ImageEditorComponent };
