import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { FormControl, FormGroup } from "@angular/forms";
import { DynamicFormControlComponent, DynamicFormControlCustomEvent, DynamicFormLayout, DynamicFormLayoutService, DynamicFormValidationService } from "@ng-dynamic-forms/core";
import { LabelOptions } from "@angular/material/core";
import { MatDatepicker } from "@angular/material/datepicker";
import { MatInput } from "@angular/material/input";
import { DatepickerCustomModel } from "./datepicker-custom-model";
import { MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import { default as _rollupMoment } from 'moment';
var moment = _rollupMoment || _moment;
var ɵ0 = MAT_MOMENT_DATE_FORMATS;
var DatepickerCustomComponent = /** @class */ (function (_super) {
    tslib_1.__extends(DatepickerCustomComponent, _super);
    function DatepickerCustomComponent(layoutService, validationService, LABEL_OPTIONS) {
        var _this = _super.call(this, layoutService, validationService) || this;
        _this.layoutService = layoutService;
        _this.validationService = validationService;
        _this.LABEL_OPTIONS = LABEL_OPTIONS;
        _this.blur = new EventEmitter();
        _this.change = new EventEmitter();
        _this.customEvent = new EventEmitter();
        _this.focus = new EventEmitter();
        _this.date = null;
        _this.minDate = null;
        return _this;
    }
    DatepickerCustomComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.minDate = this.model.min;
        this.model.valueUpdates.subscribe(function (j) {
            if (j) {
                _this.selectedValue = j;
                var dateValue = new Date(j);
                var year = dateValue.getFullYear();
                var month = dateValue.getMonth();
                var day = dateValue.getDate();
                _this.date = new FormControl(moment([year, month, day]));
            }
        });
    };
    DatepickerCustomComponent.prototype.datePick = function (event) {
        this.model.valueUpdates.next(event);
    };
    DatepickerCustomComponent.prototype.onChange = function (event) {
        _super.prototype.onChange.call(this, event);
    };
    DatepickerCustomComponent.prototype.clearInput = function () {
        this.matInput.value = '';
        _super.prototype.onChange.call(this, {});
    };
    DatepickerCustomComponent.prototype.test = function (r) {
        //alert(r)
    };
    return DatepickerCustomComponent;
}(DynamicFormControlComponent));
export { DatepickerCustomComponent };
export { ɵ0 };
