import * as tslib_1 from "tslib";
import { DynamicInputModel } from "@ng-dynamic-forms/core";
var PADDING = "000000";
var DynamicMoneyInputModel = /** @class */ (function (_super) {
    tslib_1.__extends(DynamicMoneyInputModel, _super);
    function DynamicMoneyInputModel(config, layout) {
        var _this = _super.call(this, config, layout) || this;
        _this.type = 'MONEY';
        _this.inputMaxValue = config.inputMaxValue;
        _this.fractionDigit = config.fractionDigit;
        _this.showFractionZero = config.showFractionZero;
        _this.type = 'MONEY';
        _this.PREFIX = '$ ';
        _this.DECIMAL_SEPARATOR = ".";
        _this.THOUSANDS_SEPARATOR = ",";
        _this.SUFFIX = '';
        _this.valueUpdates.subscribe(function (val) {
            if (!val || val === '')
                return;
            var newValue = _this.checkIsNaN(val, _this.inputMaxValue, _this.fractionDigit, _this.showFractionZero);
            if (newValue == val)
                return;
            setTimeout(function () {
                _this.valueUpdates.next(newValue);
            });
        });
        return _this;
    }
    DynamicMoneyInputModel.prototype.focusChange = function () {
        this.inputElement.onFocus(null);
        this.inputElement.onBlur(null);
    };
    DynamicMoneyInputModel.prototype.checkIsNaN = function (val, maxValue, fractionDigit, showFractionZero) {
        var curValue = val;
        if (!isNaN(+val)) {
            if (maxValue && +val > maxValue) {
                curValue = maxValue;
            }
            if (curValue && curValue.indexOf && curValue.indexOf('.') > -1) {
                var dij = curValue.substr(curValue.indexOf('.'));
                if (fractionDigit && dij.length > fractionDigit) {
                    dij = dij.substr(0, fractionDigit + 1);
                    curValue = curValue.substr(0, curValue.indexOf('.')) + dij;
                }
                ////console.log(+dij);
                if (+dij <= 0 && fractionDigit && (dij.length == fractionDigit + 1) && !showFractionZero) {
                    curValue = curValue.substr(0, curValue.indexOf('.'));
                }
            }
        }
        else {
            if (val.length > 1) {
                return this.checkIsNaN(val.substr(0, val.length - 1), maxValue, fractionDigit, showFractionZero);
            }
            else if (val.length === 1) {
                curValue = '';
            }
            else {
                return Error('Value length = 0, not implemented');
            }
        }
        return curValue;
    };
    DynamicMoneyInputModel.prototype.transform = function (value, fractionSize) {
        if (fractionSize === void 0) { fractionSize = 2; }
        var _a = tslib_1.__read((value || "").toString()
            .split("."), 2), integer = _a[0], _b = _a[1], fraction = _b === void 0 ? "" : _b;
        fraction = fractionSize > 0
            ? this.DECIMAL_SEPARATOR + (fraction + PADDING).substring(0, fractionSize)
            : "";
        integer = integer.replace(/\B(?=(\d{3})+(?!\d))/g, this.THOUSANDS_SEPARATOR);
        if (integer.length > 0)
            return this.PREFIX + integer + fraction + this.SUFFIX;
        else
            return null;
    };
    DynamicMoneyInputModel.prototype.parse = function (value, fractionSize) {
        if (fractionSize === void 0) { fractionSize = 2; }
        var _a = tslib_1.__read((value || "").replace(this.PREFIX, "")
            .replace(this.SUFFIX, "")
            .split(this.DECIMAL_SEPARATOR), 2), integer = _a[0], _b = _a[1], fraction = _b === void 0 ? "" : _b;
        integer = integer.replace(new RegExp(this.THOUSANDS_SEPARATOR, "g"), "");
        fraction = parseInt(fraction, 10) > 0 && fractionSize > 0
            ? this.DECIMAL_SEPARATOR + (fraction + PADDING).substring(0, fractionSize)
            : "";
        return integer + fraction;
    };
    return DynamicMoneyInputModel;
}(DynamicInputModel));
export { DynamicMoneyInputModel };
