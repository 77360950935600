import { HttpClient } from '@angular/common/http';
import { POST_AWARD_CONFIG } from '../@config/api';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var PostAwardService = /** @class */ (function () {
    function PostAwardService(http) {
        this.http = http;
        this.config = POST_AWARD_CONFIG;
    }
    PostAwardService.prototype.getAwardDetails = function (sslgId) {
        return this.http.get(this.config.detail.getAwardDetail(sslgId));
    };
    PostAwardService.prototype.setAwardDetails = function (sslgId, details) {
        return this.http.post(this.config.detail.setAwardDetail(sslgId), details);
    };
    PostAwardService.prototype.setCoraDetails = function (sslgId, data) {
        return this.http.post(this.config.detail.setCoraDetail(sslgId), data);
    };
    PostAwardService.prototype.getCoraDetails = function (sslgId) {
        return this.http.get(this.config.detail.getCoraDetail(sslgId));
    };
    PostAwardService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PostAwardService_Factory() { return new PostAwardService(i0.ɵɵinject(i1.HttpClient)); }, token: PostAwardService, providedIn: "root" });
    return PostAwardService;
}());
export { PostAwardService };
