import { HttpClient } from '@angular/common/http';
import { REFERRAL_CONFIG } from '../@config/api';
import { Subject, BehaviorSubject } from 'rxjs';
import { map } from "rxjs/operators";
import { UserInfoService } from "../@core/user-info.service";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./user-info.service";
var StatusService = /** @class */ (function () {
    function StatusService(http, userInfoService) {
        this.http = http;
        this.userInfoService = userInfoService;
        this.config = REFERRAL_CONFIG.STATUS;
        this.updateStatus = new Subject();
        //formsChanged = false;
        this.formsChanged = new BehaviorSubject(null);
        this.statusChanged = new BehaviorSubject(null);
    }
    StatusService.prototype.checkStatus = function (sslgId) {
        var _this = this;
        this.getClaimantStatusDetails(sslgId).subscribe(function (j) {
            if (j.status == "true") {
                try {
                    var status_1 = JSON.parse(j.statusData);
                    _this.setStatusChanged(status_1);
                }
                catch (e) { }
            }
        });
    };
    StatusService.prototype.setStatusChanged = function (status) {
        this.statusChanged.next({ key: Math.round(+new Date() / 1000) + Math.random(), status: status });
    };
    StatusService.prototype.setFormsUnchanged = function () {
        //this.formsChanged = false;
    };
    StatusService.prototype.setFormsChanged = function () {
        //this.formsChanged = true;
        this.formsChanged.next({ key: Math.round(+new Date() / 1000) + Math.random() });
    };
    StatusService.prototype.getFormsChanged = function () {
        return this.formsChanged;
    };
    StatusService.prototype.get = function (sslgId) {
        return this.http.get(this.config.getById(sslgId));
    };
    /*setIntake(sslgId: string, $event: Intake): Observable<StatusModel> {
        return this.http.post<StatusModel>(this.config.setIntake(sslgId), $event)
            .pipe(map(x => {
                this.updateStatus.next();
                return x;
            }));
    }*/
    StatusService.prototype.getClaimantStatusDetails = function (sslgId) {
        var userName = this.userInfoService.getCurrentUserName();
        return this.http.post(this.config.getClaimantStatusDetails(sslgId), { userName: userName });
    };
    StatusService.prototype.saveIntake = function (sslgId, $event) {
        return this.http.post(this.config.setIntake(sslgId), $event);
    };
    StatusService.prototype.setLevelDetails = function (sslgId, $event) {
        var _this = this;
        return this.http.post(this.config.setLevelDetails(sslgId), $event)
            .pipe(map(function (x) {
            _this.updateStatus.next();
            return x;
        }));
    };
    StatusService.prototype.setHearingDetails = function (sslgId, $event) {
        return this.http.post(this.config.setHearingDetails(sslgId), $event);
    };
    StatusService.prototype.setHearingSchedule = function (sslgId, $event) {
        return this.http.post(this.config.setHearingSchedule(sslgId), $event);
    };
    StatusService.prototype.setTerminated = function (sslgId, $event) {
        var _this = this;
        return this.http.post(this.config.setTerminated(sslgId), $event)
            .pipe(map(function (x) {
            _this.updateStatus.next();
            return x;
        }));
    };
    StatusService.prototype.getStatusDetails = function (sslgId) {
        //console.log(sslgId);
        return this.http.get(this.config.getStatusDetails(sslgId));
    };
    StatusService.prototype.changeStatusEvent = function () {
        return this.updateStatus;
    };
    StatusService.prototype.setRemanded = function (sslgId, value) {
        return this.http.post(this.config.setRemanded(sslgId, value.id), value.levelDetails);
    };
    StatusService.prototype.setCeExam = function (sslgId, value) {
        return this.http.post(this.config.setCeExam(sslgId), value);
    };
    StatusService.prototype.getCeExam = function (sslgId) {
        return this.http.get(this.config.getCeExam(sslgId));
    };
    StatusService.prototype.getAvailableClosedCodes = function (sslgId) {
        return this.http.get(this.config.getClosedCodes(sslgId));
    };
    StatusService.prototype.isCanTerminate = function (sslgId, rez) {
        return this.http.post(this.config.isCanTerminate(sslgId), rez);
    };
    StatusService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function StatusService_Factory() { return new StatusService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.UserInfoService)); }, token: StatusService, providedIn: "root" });
    return StatusService;
}());
export { StatusService };
