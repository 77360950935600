import * as tslib_1 from "tslib";
var FormValueBuilder = /** @class */ (function () {
    function FormValueBuilder() {
    }
    FormValueBuilder.setData = function (data, formModel) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                if (!data) {
                    return [2 /*return*/];
                }
                return [2 /*return*/, setTimeout(function () {
                        Object.getOwnPropertyNames(data).forEach(function (prop) {
                            if (formModel[prop] && formModel[prop].constructor.name !== 'Object' && formModel[prop].valueUpdates !== undefined) {
                                formModel[prop].setValue(data[prop] === undefined ? null : data[prop]);
                                // formModel[prop].valueUpdates.next(data[prop] === undefined ? null : data[prop]);
                                // formModel[prop].foo();
                            }
                            else if (formModel[prop] && formModel[prop].constructor.name === 'Object') {
                                FormValueBuilder.setData(data[prop], formModel[prop]);
                            }
                        });
                    })];
            });
        });
    };
    FormValueBuilder.buildFormModel = function (model) {
        var rez = [];
        Object.getOwnPropertyNames(model).forEach(function (j) { return rez.push(model[j]); });
        FormValueBuilder.resetToDefault(model);
        return rez;
    };
    FormValueBuilder.getData = function (formModel) {
        var rez = Object.create({});
        Object.getOwnPropertyNames(formModel).forEach(function (prop) {
            if (formModel[prop].constructor.name !== 'Object' && formModel[prop].valueUpdates !== undefined) {
                rez[prop] = formModel[prop].value === undefined ? null : formModel[prop].value;
            }
            else if (formModel[prop].constructor.name === 'Object') {
                rez[prop] = FormValueBuilder.getData(formModel[prop]);
            }
        });
        return rez;
    };
    FormValueBuilder.focus = function (formModel) {
        return setTimeout(function () {
            if (formModel.constructor.name !== 'Object' && formModel.valueUpdates !== undefined) {
                if (formModel.inputElement)
                    formModel.focusChange();
                return;
            }
            var names = Object.getOwnPropertyNames(formModel);
            var cnt = names.length;
            for (var i = 0; i < cnt; i++) {
                var prop = names[i];
                if (formModel[prop] && formModel[prop].constructor.name !== 'Object' && formModel[prop].valueUpdates !== undefined) {
                    if (formModel[prop].inputElement) {
                        formModel[prop].focusChange();
                    }
                }
                else if (formModel[prop] && formModel[prop].constructor.name === 'Object') {
                    FormValueBuilder.focus(formModel[prop]);
                }
            }
        });
    };
    FormValueBuilder.setControlsToched = function (form) {
        for (var inner in form.controls) {
            form.get(inner).markAsTouched({ onlySelf: true });
            form.get(inner).updateValueAndValidity();
            if (form.get(inner).controls && form.get(inner)) {
                FormValueBuilder.setControlsToched(form.controls[inner]);
            }
        }
    };
    FormValueBuilder.resetToDefault = function (formModel) {
        if (formModel.constructor.name !== 'Object' && formModel.valueUpdates !== undefined) {
            return formModel.resetToDefault();
        }
        Object.getOwnPropertyNames(formModel).forEach(function (prop) {
            if (formModel[prop] && formModel[prop].constructor.name !== 'Object') {
                if (formModel[prop].valueUpdates !== undefined)
                    formModel[prop].resetToDefault();
                else if (formModel[prop].groups) {
                    var contr = formModel[prop];
                    FormValueBuilder.resetToDefault(contr.groups);
                }
                else if (formModel[prop].group) {
                    FormValueBuilder.resetToDefault(formModel[prop].group);
                }
                // carAccident
            }
            if (formModel[prop] && formModel[prop].constructor.name === 'Object') {
                FormValueBuilder.resetToDefault(formModel[prop]);
            }
        });
    };
    FormValueBuilder.isDirty = function (formModel) {
        if (formModel.constructor.name !== 'Object' && formModel.valueUpdates !== undefined) {
            return formModel.isDirty();
        }
        var keys = Object.getOwnPropertyNames(formModel);
        var size = keys.length;
        var state = false;
        for (var i = 0; i < size; i++) {
            if (formModel[keys[i]] && formModel[keys[i]].constructor.name !== 'Object') {
                if (formModel[keys[i]].valueUpdates !== undefined) {
                    if (formModel[keys[i]].isDirty()) {
                        state = true;
                    }
                }
                else if (formModel[keys[i]].groups) {
                    var contr = formModel[keys[i]];
                    state = FormValueBuilder.isDirty(contr.groups);
                }
                else if (formModel[keys[i]].group) {
                    state = FormValueBuilder.isDirty(formModel[keys[i]].group);
                }
            }
            if (formModel[keys[i]] && formModel[keys[i]].constructor.name === 'Object') {
                state = FormValueBuilder.isDirty(formModel[keys[i]]);
            }
            if (state) {
                // console.log(state, formModel[keys[i]]);
                return state;
            }
        }
        return state;
    };
    return FormValueBuilder;
}());
export { FormValueBuilder };
