import * as tslib_1 from "tslib";
import { ApplicationRef, ComponentFactoryResolver, ComponentRef, EmbeddedViewRef, Injector } from '@angular/core';
import { NotificationConfig } from "./notification-config";
import { NotificationsContainerComponent } from "./notifications-container/notifications-container/notifications-container.component";
var CustomIrisNotificationService = /** @class */ (function () {
    function CustomIrisNotificationService(componentFactoryResolver, appRef, injector) {
        var _this = this;
        this.componentFactoryResolver = componentFactoryResolver;
        this.appRef = appRef;
        this.injector = injector;
        setTimeout(function () { return _this.addNotifContainerToDom(); });
    }
    CustomIrisNotificationService.prototype.show = function (parameters) {
        var _a;
        var param = parameters;
        var foo = new NotificationConfig();
        foo.title = 'Hello world';
        foo.message = 'Test notification message';
        foo.type = 'info';
        foo.imagePosition = 'right';
        foo.progressBar = 'linear';
        foo.autoHide = false;
        foo.timeOut = 3000;
        foo.position = 'rb';
        Object.getOwnPropertyNames(foo).forEach(function (j) {
            if (param[j])
                foo[j] = param[j];
        });
        foo.genId();
        (_a = CustomIrisNotificationService.allNotifications).unshift.apply(_a, tslib_1.__spread([foo]));
        // if (param.autoHide) {
        //     setTimeout(() => {
        //         // componentRef.instance.isOpen = false;
        //         setTimeout(() => {
        //             this.destroy(this.getComponentRef(NotificationsContainerComponent));
        //         }, 500);
        //     }, param.timeOut);
        // }
        //
        // // Create a component reference from the component
        // const componentRef: any = this.getComponentRef(CustomIrisNotificationComponent);
        // // ////console.log(componentRef);
        //
        // componentRef.instance.title = param.title;
        // componentRef.instance.message = param.message;
        // componentRef.instance.type = param.type;
        // componentRef.instance.timeOut = param.timeOut;
        // componentRef.instance.autoHide = param.autoHide;
        // componentRef.instance.imagePosition = param.imagePosition;
        // componentRef.instance.progressBar = param.progressBar;
        // componentRef.instance.position = param.position;
        //
        // // this.addComponentInDOM(componentRef);
        // componentRef.instance.isOpen = true;
        // // Wait some time and remove it from the component tree and from the DOM
    };
    CustomIrisNotificationService.prototype.getComponentRef = function (component) {
        var compRef = this.componentFactoryResolver
            .resolveComponentFactory(component)
            .create(this.injector);
        // compRef.instance.onCloseDialog.subscribe(j => {
        //     compRef.instance.isOpen = false;
        //     setTimeout(() => {
        //         this.destroy(compRef);
        //     }, 500);
        // });
        return compRef;
    };
    CustomIrisNotificationService.prototype.destroy = function (componentRef) {
        this.appRef.detachView(componentRef.hostView);
        componentRef.destroy();
    };
    CustomIrisNotificationService.prototype.addComponentInDOM = function (componentRef) {
        // Attach component to the appRef so that it's inside the ng component tree
        this.appRef.attachView(componentRef.hostView);
        // Get DOM element from component
        var domElem = componentRef.hostView
            .rootNodes[0];
        // Append DOM element to the body
        document.body.appendChild(domElem);
    };
    CustomIrisNotificationService.prototype.addNotifContainerToDom = function () {
        this.addComponentInDOM(this.getComponentRef(NotificationsContainerComponent));
    };
    CustomIrisNotificationService.close = function (id) {
        var _a;
        var tmp = CustomIrisNotificationService.allNotifications.filter(function (j) { return j.id !== id; });
        while (CustomIrisNotificationService.allNotifications.length !== 0) {
            CustomIrisNotificationService.allNotifications.pop();
        }
        (_a = CustomIrisNotificationService.allNotifications).push.apply(_a, tslib_1.__spread(tmp));
    };
    CustomIrisNotificationService.allNotifications = [];
    return CustomIrisNotificationService;
}());
export { CustomIrisNotificationService };
