import { SSA_LEVELS } from '../@config/ssa-levels';
import * as i0 from "@angular/core";
var SSALevelService = /** @class */ (function () {
    function SSALevelService() {
        this.config = SSA_LEVELS;
    }
    SSALevelService.prototype.getLevelName = function (id) {
        var name;
        this.config.find(function (el) {
            if (+el.id === +id) {
                name = el.name;
            }
        });
        return name;
    };
    SSALevelService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SSALevelService_Factory() { return new SSALevelService(); }, token: SSALevelService, providedIn: "root" });
    return SSALevelService;
}());
export { SSALevelService };
