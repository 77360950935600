import * as tslib_1 from "tslib";
import { EventEmitter, QueryList } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { DynamicFormArrayComponent, DynamicFormArrayModel, DynamicFormLayoutService, DynamicFormValidationService } from "@ng-dynamic-forms/core";
var DynamicMaterialFormArrayComponent = /** @class */ (function (_super) {
    tslib_1.__extends(DynamicMaterialFormArrayComponent, _super);
    function DynamicMaterialFormArrayComponent(layoutService, validationService) {
        var _this = _super.call(this, layoutService, validationService) || this;
        _this.layoutService = layoutService;
        _this.validationService = validationService;
        _this.blur = new EventEmitter();
        _this.change = new EventEmitter();
        _this.customEvent = new EventEmitter();
        _this.focus = new EventEmitter();
        return _this;
    }
    DynamicMaterialFormArrayComponent.prototype.getFormControl = function () {
        return this.control;
    };
    DynamicMaterialFormArrayComponent.prototype.isDirty = function (group) {
        if (!group && this.model.isDirty != undefined)
            return this.model.isDirty();
        if (group) {
            for (var i = 0; i < group.group.length; i++) {
                if (group.group[i].isDirty != undefined
                    && group.group[i].isDirty()) {
                    return true;
                }
            }
        }
    };
    return DynamicMaterialFormArrayComponent;
}(DynamicFormArrayComponent));
export { DynamicMaterialFormArrayComponent };
