import { HttpClient } from '@angular/common/http';
import * as conf from '../@config/api';
import { Social } from "../@models/system-managment/social";
import { Functions } from "../../@util/functions";
import { FileUploadingService } from "../../@util/file-uploading-progress/file-uploading.service";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../@util/file-uploading-progress/file-uploading.service";
var AdjustersService = /** @class */ (function () {
    function AdjustersService(http, fileUploadingService) {
        this.http = http;
        this.fileUploadingService = fileUploadingService;
        this.config = conf.MANAGER_USER_CONFIG;
    }
    AdjustersService.prototype.addUser = function (data) {
        return this.http.post(this.config.add, data);
    };
    AdjustersService.prototype.getUsersList = function () {
        return this.http.get(this.config.getAll('ADJUSTER'));
    };
    // setCompanyAndOfficeDisplayName(value: { companyId?: number, officeId?: number, displayName: string }, userName) {
    //   const param = new HttpParams().formset('userId', userName);
    //   return this.http.post(this.config.setCompany, value, {params: param});
    // }
    AdjustersService.prototype.getCurrentUser = function (userId) {
        return this.http.get(this.config.getById(userId));
    };
    AdjustersService.prototype.updatePasswordAndActive = function (value) {
        return this.http.post(this.config.updateLoginInfo(value.username), value);
    };
    AdjustersService.prototype.setContact = function (userName, value) {
        value.press = Functions.checkPhone(value.press);
        value.fax = Functions.checkPhone(value.fax);
        value.phone = Functions.checkPhone(value.phone);
        return this.http.post(this.config.setContact(userName), value);
    };
    AdjustersService.prototype.setPersonal = function (userName, value) {
        return this.http.post(this.config.setPersonal(userName), value);
    };
    AdjustersService.prototype.setSocial = function (userName, value) {
        Social.checkLinck(value);
        return this.http.post(this.config.setSocial(userName), value);
    };
    AdjustersService.prototype.setCareer = function (username, $event) {
        ////console.log($event);
        return this.http.post(this.config.setCareer(username), $event);
    };
    AdjustersService.prototype.uploadAvatar = function (username, avatar) {
        return this.fileUploadingService.uploadFile('/api/user/avatar/' + username, { file: avatar, fileInfo: null });
    };
    AdjustersService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AdjustersService_Factory() { return new AdjustersService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.FileUploadingService)); }, token: AdjustersService, providedIn: "root" });
    return AdjustersService;
}());
export { AdjustersService };
