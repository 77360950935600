import { ElementRef, OnInit, QueryList } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { AuthenticationService } from '../_services/authentication.service';
import { ClientsService } from '../../@core/clients.service';
import { first } from 'rxjs/operators';
import { UserIdleConfig } from '../user-idle/user-idle-config';
import { DialogService } from "../../../@util/dialog/dialog.service";
import { AppStorage } from "../../storage";
import { BreakpointObserver } from "@angular/cdk/layout";
import { AuthConfig } from "../_services/auth-config";
import { DevicesService } from "../../@core/devices.service";
import { PhoneService } from "../../@core/phone.service";
import { UserInfoService } from "../../@core/user-info.service";
import { PageLoaderService } from "../../@core/page-loader.service";
import { LoginService } from "../../@core/login.service";
import { UserTempService } from "../../@core/userTemp.service";
var LoginComponent = /** @class */ (function () {
    // @ViewChild('loader') el: ElementRef;
    //
    function LoginComponent(authConfig, formBuilder, route, router, breakpointObserver, authenticationService, loginService, clientsService, devicesService, phoneService, pageLoaderService, userTempService, userInfoService, dialog) {
        // Image
        var _this = this;
        this.authConfig = authConfig;
        this.formBuilder = formBuilder;
        this.route = route;
        this.router = router;
        this.breakpointObserver = breakpointObserver;
        this.authenticationService = authenticationService;
        this.loginService = loginService;
        this.clientsService = clientsService;
        this.devicesService = devicesService;
        this.phoneService = phoneService;
        this.pageLoaderService = pageLoaderService;
        this.userTempService = userTempService;
        this.userInfoService = userInfoService;
        this.dialog = dialog;
        // rememberMe: boolean = REMEMBER_ME;
        this.showLoginForm = true;
        this.showForgotPasswordForm = false;
        this.showPasswordInput = false;
        this.showPinInput = false;
        this.showLoginStepTwo = false;
        this.numOfDigits = 4;
        this.loading = false;
        this.submitted = false;
        this.error = '';
        this.loginService.changePassword.subscribe(function (value) {
            if (value) {
                _this.showLoginForm = false;
                _this.showForgotPasswordForm = true;
                _this.forgotPasswordForm.controls.username.setValue(_this.loginForm.controls.username.value);
                _this.loginService.changePassword.next(false);
            }
        });
        this.loginService.ignorePasswordChanging.subscribe(function (value) {
            if (value) {
                _this.onSubmit(true);
            }
        });
    }
    LoginComponent.prototype.ngOnInit = function () {
        // reset login status
        this.authenticationService.logout();
        this.loginForm = this.formBuilder.group({
            username: [null, Validators.required],
            password: [null, Validators.required],
            digits: this.formBuilder.array([]),
            rememberMe: [false]
        });
        for (var i = 0; i < this.numOfDigits; i++) {
            this.loginForm.get('digits').push(this.formBuilder.control(null, Validators.required));
        }
        this.forgotPasswordForm = this.formBuilder.group({
            username: [null, Validators.required],
        });
        try {
            var userName = AppStorage.getStorage().getItem('user');
            if (userName) {
                this.loginForm.controls['username'].setValue(userName);
                this.loginForm.controls['rememberMe'].setValue(true);
            }
        }
        catch (e) {
            this.dialog.show(JSON.stringify(e), ['OK']);
        }
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
        this.pageLoaderService.hide();
    };
    Object.defineProperty(LoginComponent.prototype, "f", {
        //
        // convenience getter for easy access to form fields
        get: function () {
            return this.loginForm.controls;
        },
        enumerable: true,
        configurable: true
    });
    LoginComponent.prototype.onSubmit = function (ignore) {
        var _this = this;
        if (ignore === void 0) { ignore = false; }
        this.submitted = true;
        var pin;
        var password = '';
        // stop here if form is invalid
        if (!this.canLoginWithPassword() && !this.canLoginWithPin()) {
            return;
        }
        if (this.canLoginWithPassword()) {
            password = this.loginForm.controls.password.value;
            pin = '';
        }
        if (this.canLoginWithPin()) {
            pin = this.loginForm.controls.digits.value;
            pin = pin.join('');
        }
        this.loading = true;
        this.pageLoaderService.show();
        UserIdleConfig.REMEMBER_ME = this.loginForm.controls.rememberMe.value;
        // this.dialog.show(window.navigator.userAgent, ['OK']);
        this.userTempService.resetUserTempState();
        this.authenticationService.login(this.loginForm.controls.username.value, password, pin, ignore)
            .pipe(first())
            .subscribe(function (data) {
            if (window.navigator.userAgent === 'SSLG_Browser') { // TODO: !
                if (_this.authenticationService.roles.indexOf('CLIENT') === -1) {
                    _this.dialog.show('Only for clients', ['OK']);
                    _this.loading = false;
                    _this.pageLoaderService.hide();
                    return;
                }
                else {
                    _this.router.navigate([_this.returnUrl]);
                }
            }
            else {
                if (_this.userInfoService.getDeviceUuid()) {
                    if (_this.authenticationService.roles.indexOf('CLIENT') === -1) {
                        _this.dialog.show('Only for clients', ['OK']);
                        _this.loading = false;
                        _this.pageLoaderService.hide();
                        return;
                    }
                    else {
                        _this.devicesService.add(_this.userInfoService.getDeviceUuid(), _this.userInfoService.getDeviceType()).subscribe(function (data) {
                            _this.phoneService.setNotificationToken();
                            _this.router.navigate([_this.returnUrl]);
                        });
                    }
                }
                else {
                    _this.router.navigate([_this.returnUrl]);
                }
            }
            _this.loading = false;
            _this.pageLoaderService.hide();
        }, function (error1) {
            _this.loading = false;
            _this.pageLoaderService.hide();
            //console.log(error1);
            ////console.log(error1);
        });
    };
    LoginComponent.prototype.onForgotPasswordSubmit = function () {
        var _this = this;
        this.submitted = true;
        // stop here if form is invalid
        if (this.forgotPasswordForm.invalid) {
            return;
        }
        this.loading = true;
        this.pageLoaderService.show();
        /*return this.clientsService.forgotPassword(this.forgotPasswordForm.controls.username.value)
            .pipe(map((data: User[]) => {
            return data.map(j => {
                let name = 'Fill Client Name';
                if (j.clientBasic && j.clientBasic.firstName && j.clientBasic.lastName) {
                    name = j.clientBasic.firstName + ' ' + j.clientBasic.lastName;
                }
                return {
                    value: j.username,
                    label: name
                };
            })*/
        this.clientsService.forgotPassword(this.forgotPasswordForm.controls.username.value)
            .pipe(first())
            .subscribe(function (data) {
            //console.log(data)
            _this.dialog.show(data.message, ['OK']);
            /*if (window.navigator.userAgent === 'SSLG_Browser') { // TODO: !
                if (this.authenticationService.roles.indexOf('CLIENT') === -1) {
                    this.dialog.show('Only for clients', ['OK']);
                    this.loading = false;
                    return;
                } else {
                    this.router.navigate([this.loginForm.controls.username.value]);
                }

            } else {
                this.router.navigate([this.returnUrl]);
            }*/
            _this.loading = false;
            _this.pageLoaderService.hide();
        }, function (error1) {
            _this.loading = false;
            _this.pageLoaderService.hide();
            //console.log(error1);
            ////console.log(error1);
        });
    };
    LoginComponent.prototype.checkValid = function (reg) {
        return this.loginForm.controls[reg].touched && this.loginForm.controls[reg].hasError('required');
    };
    LoginComponent.prototype.showForgotPassword = function () {
        this.showLoginForm = false;
        this.showForgotPasswordForm = true;
    };
    LoginComponent.prototype.showLogin = function () {
        this.showLoginForm = true;
        this.showForgotPasswordForm = false;
    };
    LoginComponent.prototype.isPhone = function () {
        return this.userInfoService.isApp();
    };
    LoginComponent.prototype.sendUserName = function () {
        var _this = this;
        // this.showLoginStepTwo = true;
        // this.showPasswordInput = true;
        this.authenticationService.sendUserName(this.loginForm.controls.username.value).subscribe(function (data) {
            //alert(data.code)
            if (data.status == 'true') {
                if (data.code === '0') {
                    _this.showPasswordInput = true;
                }
                else if (data.code === '1') {
                    _this.showPinInput = true;
                    setTimeout(function () {
                        _this.inputs.toArray()[0].nativeElement.focus();
                    });
                }
                _this.showLoginStepTwo = true;
            }
            else {
                _this.dialog.show(data.message, ['OK']);
            }
        });
    };
    LoginComponent.prototype.goToFirstLoginStep = function () {
        this.showLoginStepTwo = false;
        this.showPasswordInput = false;
        this.showPinInput = false;
    };
    LoginComponent.prototype.check = function (index, field, event) {
        if (isNaN(parseInt(event.key, 10)) && event.key !== 'Backspace') {
            event.preventDefault();
        }
        if (field.value && event.key !== 'Backspace') {
            if (index < this.inputs.toArray().length - 1) {
                this.inputs.toArray()[index + 1].nativeElement.focus();
            }
        }
        else if (event.key === 'Backspace') {
            if (index > 0) {
                field.setValue(null);
                this.inputs.toArray()[index - 1].nativeElement.focus();
            }
            else {
                console.log('first field');
            }
        }
    };
    LoginComponent.prototype.canLoginWithPin = function () {
        return this.loginForm.controls.username.valid && this.showPinInput && this.loginForm.controls.digits.valid;
    };
    LoginComponent.prototype.canLoginWithPassword = function () {
        return this.loginForm.controls.username.valid && this.showPasswordInput && this.loginForm.controls.password.valid;
    };
    return LoginComponent;
}());
export { LoginComponent };
