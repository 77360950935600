import { OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { NgIfContext } from '@angular/common';
import { AuthenticationService } from '../_services/authentication.service';
var CanAccessDirective = /** @class */ (function () {
    function CanAccessDirective(viewContainer, templateRef, principalService) {
        this.viewContainer = viewContainer;
        this.templateRef = templateRef;
        this.principalService = principalService;
        this.context = new NgIfContext();
    }
    Object.defineProperty(CanAccessDirective.prototype, "appCanAccess", {
        set: function (roles) {
            this.setRoles(roles);
        },
        enumerable: true,
        configurable: true
    });
    CanAccessDirective.prototype.setRoles = function (roles) {
        this.roles = roles;
        this.updateView(this.principalService.roles);
    };
    CanAccessDirective.prototype.ngOnInit = function () {
        var _this = this;
        this.principalSubscription = this.principalService.rolesObservable
            .subscribe(function (principal) { return _this.updateView(principal); });
    };
    CanAccessDirective.prototype.ngOnDestroy = function () {
        if (this.principalSubscription) {
            this.principalSubscription.unsubscribe();
            this.principalSubscription = null;
        }
    };
    CanAccessDirective.prototype.updateView = function (roles) {
        if (!roles) {
            return;
        }
        this.viewContainer.clear();
        this.context.$implicit = this.context.ngIf = this.isAllowed(roles);
        if (this.context.$implicit && this.templateRef) {
            this.viewContainer.createEmbeddedView(this.templateRef, this.context);
        }
    };
    CanAccessDirective.prototype.isAllowed = function (roles) {
        var _this = this;
        return roles.some(function (role) { return _this.hasRole(role); });
    };
    CanAccessDirective.prototype.hasRole = function (role) {
        return this.roles.some(function (r) { return r === role; });
    };
    return CanAccessDirective;
}());
export { CanAccessDirective };
