import * as tslib_1 from "tslib";
import { AfterViewInit, OnInit, QueryList } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { LoadingService } from "../../../@util/loading/loading.service";
import { MedicalService } from "../../@core/medical.service";
import { MEDICAL_TREATING_REPORT_TYPE } from "../pages/claimants/medical-treating-sources/medical-treating-reports/medical-treating-reports.config";
var MedicalTreatingReportPageComponent = /** @class */ (function () {
    function MedicalTreatingReportPageComponent(route, router, loading, medicalService) {
        this.route = route;
        this.router = router;
        this.loading = loading;
        this.medicalService = medicalService;
        this.scale = 0.95;
        this.showContent = true;
        this.headerInfo = {
            claimant: '',
            sslgNumber: '',
            date: null,
            type: null
        };
        this.MEDICAL_TREATING_REPORT_TYPE = MEDICAL_TREATING_REPORT_TYPE;
        // this.route.queryParams.subscribe(val => {
        // 	this.type = val.type;
        // 	this.sslgId = val.sslgId;
        // });
    }
    MedicalTreatingReportPageComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.type = localStorage.getItem('reportType');
        this.sslgId = localStorage.getItem('reportSslgId');
        // let data = localStorage.getItem('reportData');
        // if (!data) {
        // 	this.router.navigate(['/']);
        // }
        // this.pdfSrc = JSON.parse(data);
        // if(this.pdfSrc.type == "DOWNLOAD"){
        // 	this.showContent = false;
        // }
        // var ids = this.getVisitsIds();
        // this.medicalService.getVisitSummariesById(this.pdfSrc.sslgId, ids).subscribe((data:any) => {
        // 	this.pdfData = data.data;
        // });
        setTimeout(function () {
            _this.getReportData();
        });
    };
    MedicalTreatingReportPageComponent.prototype.getReportData = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var result;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.medicalService.getMedicalTreatmentSourcesReport(this.sslgId, this.type)];
                    case 1:
                        result = _a.sent();
                        this.data = result;
                        this.setHeaderInfo(this.data);
                        if (this.isMedicationsReport() || this.isBothReport()) {
                            this.getMedicationsData(this.data.medications);
                        }
                        if (this.isMTSReport() || this.isBothReport()) {
                            this.getMTSData(this.data);
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    MedicalTreatingReportPageComponent.prototype.isMedicationsReport = function () {
        return this.type === this.MEDICAL_TREATING_REPORT_TYPE.medications;
    };
    MedicalTreatingReportPageComponent.prototype.isBothReport = function () {
        return this.type === this.MEDICAL_TREATING_REPORT_TYPE.both;
    };
    MedicalTreatingReportPageComponent.prototype.isMTSReport = function () {
        return this.type === this.MEDICAL_TREATING_REPORT_TYPE.mts;
    };
    MedicalTreatingReportPageComponent.prototype.setHeaderInfo = function (data) {
        this.headerInfo.claimant = data.name;
        this.headerInfo.sslgNumber = data.sslgNumber;
        this.headerInfo.type = this.type;
        this.headerInfo.date = new Date();
    };
    MedicalTreatingReportPageComponent.prototype.getMedicationsData = function (data) {
        var _this = this;
        var medicationsData = [];
        Object.keys(data).forEach(function (type) {
            Object.keys(data[type]).forEach(function (claim) {
                var discontinuedMedList = [];
                var activeMedList = [];
                data[type][claim].forEach(function (med) {
                    if (med.status) {
                        activeMedList.push(med);
                    }
                    else {
                        discontinuedMedList.push(med);
                    }
                });
                medicationsData.push({
                    name: data[type][claim][0].catalogItemName,
                    type: type,
                    discontinuedMedList: discontinuedMedList,
                    activeMedList: activeMedList
                });
            });
            _this.medicationsData = medicationsData.reverse();
        });
    };
    MedicalTreatingReportPageComponent.prototype.getMTSData = function (data) {
        var _this = this;
        console.log(data);
        var MTSData = [];
        var sources = data.sources;
        var medications = data.medications;
        sources.forEach(function (source) {
            var sourceItem = source;
            var sourceItemMedications = medications && medications[source.type] && medications[source.type][source.id] ? medications[source.type][source.id] : null;
            var discontinuedMedList = [];
            var activeMedList = [];
            if (sourceItemMedications) {
                sourceItemMedications.forEach(function (med) {
                    if (med.status) {
                        activeMedList.push(med);
                    }
                    else {
                        discontinuedMedList.push(med);
                    }
                });
            }
            MTSData.push({
                item: sourceItem,
                type: source.type,
                discontinuedMedList: discontinuedMedList,
                activeMedList: activeMedList
            });
            _this.MTSData = MTSData.reverse().sort(function (a, b) {
                if (a.type < b.type)
                    return -1;
                if (a.type > b.type)
                    return 1;
                return 0;
            });
        });
    };
    MedicalTreatingReportPageComponent.prototype.getVisitsIds = function () {
        var ids = [];
        for (var i = 0; i < this.pdfSrc.data.length; i++) {
            ids.push(this.pdfSrc.data[i].id);
        }
        return ids;
    };
    MedicalTreatingReportPageComponent.prototype.ngAfterViewInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/];
            });
        });
    };
    MedicalTreatingReportPageComponent.prototype.saveAndSendEmail = function (doc, loader) {
        // // this.loading.loaded(loader);
        // this.loading.loaded();
        // if (this.pdfSrc.type == "EMAIL") {
        // 	let data: String = doc.output();
        //
        // 	this.medicalService.sentVisitSummaries(this.pdfSrc.sslgId, {
        // 		content: data,
        // 		email: this.pdfSrc.email
        // 	}).subscribe();
        // }
        // doc.save();
    };
    MedicalTreatingReportPageComponent.prototype.sendEmail = function () {
        // let {data, report} =  <any>{...this.pdfSrc};
        /*let rez = {...this.pdfSrc};
        rez.data = rez.data.map(j=>j.id);
        (<any>rez).individual = rez.isIndividual;
        this.medicalService.sentVisitSummariesByEmail(rez).subscribe(rez=>{
            if (rez.type == 4)
                this.downloadFile(rez, "report.pdf");
        });*/
        var _this = this;
        var ids = this.getVisitsIds();
        this.medicalService.sendVisitSummariesByEmail(this.pdfSrc.sslgId, this.pdfSrc.email, this.pdfSrc.isIndividual, ids).subscribe(function (rez) {
            if (rez.type == 4)
                _this.downloadFile(rez, "report.pdf");
        });
    };
    // private download(){
    // 	let rez = {...this.pdfSrc};
    // 	rez.data = rez.data.map(j=>j.id);
    // 	(<any>rez).individual = rez.isIndividual;
    // 	this.medicalService.saveVisitSummariesReport(rez).subscribe(rez=>{
    // 		if (rez.type == 4){
    // 			this.downloadFile(rez, "report.pdf");
    // 			window.close();
    // 		}
    // 	});
    // }
    MedicalTreatingReportPageComponent.prototype.save = function () {
        var _this = this;
        // let {data, report} =  <any>{...this.pdfSrc};
        //console.log(this.pdfSrc);
        var rez = tslib_1.__assign({}, this.pdfSrc);
        rez.data = rez.data.map(function (j) { return j.id; });
        rez.individual = rez.isIndividual;
        this.medicalService.saveVisitSummariesReport(rez).subscribe(function (rez) {
            if (rez.type == 4) {
                _this.downloadFile(rez, "report.pdf");
                window.close();
            }
        });
    };
    MedicalTreatingReportPageComponent.prototype.downloadFile = function (data, fileName) {
        var ieEDGE = navigator.userAgent.match(/Edge/g);
        var ie = navigator.userAgent.match(/.NET/g); // IE 11+
        var oldIE = navigator.userAgent.match(/MSIE/g);
        var blob = new Blob([data.body], { type: 'application/pdf' });
        if (ie || oldIE || ieEDGE) {
            window.navigator.msSaveBlob(blob, atob(fileName));
        }
        else {
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = fileName;
            link.click();
        }
        return blob;
    };
    MedicalTreatingReportPageComponent.prototype.print = function () {
        window.print();
    };
    return MedicalTreatingReportPageComponent;
}());
export { MedicalTreatingReportPageComponent };
