import * as tslib_1 from "tslib";
import { EventEmitter } from '@angular/core';
import { Validators } from '@angular/forms';
import { DynamicFormService, } from '@ng-dynamic-forms/core';
import { FormValueBuilder } from '../../../../partial/forms/form-tamplate';
import { FormMode } from '../../../../../@models/system-managment/form-mode';
import { RADIO_USER_STATE, STRING_FIELD } from '../../../../../../@util/dynamic-form/dynamic-controlls';
import { MedicalService } from '../../../../../@core/medical.service';
import { DynamicForm } from "../../../../../../@util/dynamic-form/dynamic-form";
import { AuthenticationService } from "../../../../../auth/_services/authentication.service";
import { DynamicFormArrayModel as DynamicFormArray, DynamicTextAreaModel } from "../../../../../../@util/ui-material/form-controls";
import { GlobalSaveService } from "../../../../../@core/global-save.service";
import { UserInfoService } from "../../../../../@core/user-info.service";
import { DateService } from "../../../../../@core/functions/date.service";
import { DialogService } from "../../../../../../@util/dialog/dialog.service";
import { PERMISSIONS } from 'src/app/@config/permissions';
var MedicalTreatingSourcesMedicationsComponent = /** @class */ (function (_super) {
    tslib_1.__extends(MedicalTreatingSourcesMedicationsComponent, _super);
    function MedicalTreatingSourcesMedicationsComponent(formService, medicalService, globalSaveService, userInfoService, dialog, dateService, auth) {
        var _this = _super.call(this) || this;
        _this.formService = formService;
        _this.medicalService = medicalService;
        _this.globalSaveService = globalSaveService;
        _this.userInfoService = userInfoService;
        _this.dialog = dialog;
        _this.dateService = dateService;
        _this.auth = auth;
        _this.medications = [];
        _this.closeForm = new EventEmitter();
        _this.saveForm = new EventEmitter();
        _this.backForm = new EventEmitter();
        _this.title = 'Medications';
        _this.PERMISSIONS = PERMISSIONS;
        _this.formLayout = {
            'showMedications': {
                element: {
                    container: 'top-label'
                }
            },
            medications: {
                element: {
                    label: 'form-label form-label_full-width form-label_mb',
                    group: 'row-mb row row--padding'
                }
            },
            period: {
                element: {
                    control: 'form-row form-row_wrap',
                    container: 'row-mb',
                    children: 'form-element form-element_1-2 form_mb'
                }
            },
            medication: {
                element: {
                    container: 'row-mb',
                    host: 'col-12'
                }
            },
            reason: {
                element: {
                    container: 'row-mb',
                    host: 'col-12'
                }
            },
            sideEffects: {
                element: {
                    container: '',
                    host: 'col-12'
                }
            }
        };
        _this.form = {
            showMedications: RADIO_USER_STATE({ id: 'showMedications', options: [{ label: 'YES', value: true }, { label: 'NO', value: false }], editableFor: PERMISSIONS.LOWER_EXEC }),
            medications: DynamicFormArray({
                id: 'medications',
                initialCount: 2,
                groupFactory: function () {
                    return [
                        STRING_FIELD({ id: 'id', hidden: true, editableFor: PERMISSIONS.LOWER_EXEC, }),
                        STRING_FIELD({ id: 'medication', label: 'Medication', editableFor: PERMISSIONS.LOWER_EXEC, }),
                        DynamicTextAreaModel({ id: 'reason', label: 'Reason for Taking', editableFor: PERMISSIONS.LOWER_EXEC, }),
                        DynamicTextAreaModel({ id: 'sideEffects', label: 'Side Effects', editableFor: PERMISSIONS.LOWER_EXEC, }),
                    ];
                }, editableFor: PERMISSIONS.LOWER_EXEC,
            })
        };
        _this.isMedicationsVisible = false;
        _this.formModel = FormValueBuilder.buildFormModel(_this.form);
        _this.formGroup = formService.createFormGroup(_this.formModel);
        _this.arrayControl = _this.formGroup.controls['medications'];
        _this.arrayModel = _this.formService.findById('medications', _this.formModel);
        return _this;
    }
    Object.defineProperty(MedicalTreatingSourcesMedicationsComponent.prototype, "sourceIdValue", {
        set: function (id) {
            var _this = this;
            if (this.sourceId !== id) {
                this.sourceId = id;
                setTimeout(function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                    var catalogItem;
                    return tslib_1.__generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, this.medicalService.getCatalogItemById(this.sourceId, this.sslgId).toPromise()];
                            case 1:
                                catalogItem = _a.sent();
                                this.prescribedBy = catalogItem.name;
                                this.setPrescribedValue();
                                return [2 /*return*/];
                        }
                    });
                }); });
            }
            else {
                setTimeout(function () {
                    _this.setPrescribedValue();
                });
            }
        },
        enumerable: true,
        configurable: true
    });
    MedicalTreatingSourcesMedicationsComponent.prototype.insert = function () {
        var _this = this;
        console.log(this.arrayControl, this.arrayModel);
        this.formService.addFormArrayGroup(this.arrayControl, this.arrayModel);
        setTimeout(function () {
            console.log(_this.arrayModel, _this.arrayModel.groups, _this.arrayModel.groups[_this.arrayModel.groups.length - 1], _this.arrayModel.groups[_this.arrayModel.groups.length - 1].group[5]);
            _this.arrayModel.groups[_this.arrayModel.groups.length - 1].group[5].valueUpdates.next(_this.prescribedBy);
            FormValueBuilder.resetToDefault(_this.arrayModel.groups[_this.arrayModel.groups.length - 1].group);
        });
    };
    MedicalTreatingSourcesMedicationsComponent.prototype.backMedications = function () {
        this.backForm.emit();
    };
    MedicalTreatingSourcesMedicationsComponent.prototype.insertDouble = function () {
        this.insert();
        this.insert();
    };
    MedicalTreatingSourcesMedicationsComponent.prototype.remove = function (index) {
        if (this.canRemove()) {
            this.formService.removeFormArrayGroup(index, this.arrayControl, this.arrayModel);
        }
    };
    MedicalTreatingSourcesMedicationsComponent.prototype.removeDouble = function (index) {
        var _this = this;
        if (this.canRemove()) {
            this.dialog.show('Are you sure you want to delete this medications row?', ['OK', 'CANCEL'])
                .onCloseDialog.subscribe(function (j) {
                if (j === 'OK') {
                    _this.remove(index);
                    _this.remove(index - 1);
                }
            });
        }
    };
    MedicalTreatingSourcesMedicationsComponent.prototype.isRemoveHidden = function () {
        return this.arrayModel.groups.length < 3;
    };
    MedicalTreatingSourcesMedicationsComponent.prototype.canRemove = function () {
        return this.arrayModel.groups.length > 1;
    };
    MedicalTreatingSourcesMedicationsComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.form.showMedications.valueUpdates.subscribe(function (j) {
                    _this.isMedicationsVisible = !!j;
                    _this.isMedicationsVisible ? document.querySelector('#medications').classList.remove('is-hidden') : document.querySelector('#medications').classList.add('is-hidden');
                });
                this.setNewVisit();
                FormValueBuilder.resetToDefault(this.form);
                this.formGroup.valueChanges.subscribe(function (j) {
                    _this.formStateChanged.emit({
                        key: _this.formKey,
                        value: FormValueBuilder.isDirty(_this.form) || _this.formGroup.value.medications.length < _this.medications.length
                    });
                });
                setTimeout(function () {
                    _this.formGroup.controls.showMedications.setValue(false);
                });
                return [2 /*return*/];
            });
        });
    };
    MedicalTreatingSourcesMedicationsComponent.prototype.onBlur = function ($event) {
    };
    MedicalTreatingSourcesMedicationsComponent.prototype.onChange = function ($event) {
        console.log($event);
        if (($event.model.id === 'startDate' || $event.model.id === 'endDate') &&
            $event.group.controls['startDate'].value !== null && $event.group.controls['endDate'].value !== null &&
            Date.parse($event.group.controls['startDate'].value) > Date.parse($event.group.controls['endDate'].value)) {
            setTimeout(function () {
                $event.group.controls['endDate'].patchValue(new Date(Date.parse($event.group.controls['startDate'].value)));
            });
        }
        if ($event.model.id === 'regular') {
            if ($event.model.value) {
                setTimeout(function () {
                    $event.group.controls['endDate'].setValidators(null);
                    $event.context.group[8].hidden = true;
                    $event.context.group[8].required = false;
                    $event.context.group[8].valueUpdates.next(null);
                });
            }
            else {
                $event.context.group[8].hidden = false;
                $event.context.group[8].required = true;
                $event.context.group[8].validators = { required: null };
                setTimeout(function () {
                    $event.group.controls['endDate'].setValidators([Validators.required]);
                });
            }
        }
    };
    MedicalTreatingSourcesMedicationsComponent.prototype.onFocus = function ($event) {
    };
    MedicalTreatingSourcesMedicationsComponent.prototype.closeMedications = function () {
        this.closeForm.emit();
    };
    MedicalTreatingSourcesMedicationsComponent.prototype.saveMedication = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var medications, newFormGroupMedication, data;
            return tslib_1.__generator(this, function (_a) {
                medications = this.formGroup.value.medications ? this.formGroup.value.medications : [];
                medications = medications.filter(function (el) { return el.medication; });
                newFormGroupMedication = medications.map(function (j) {
                    //j.startDate =  this.dateService.getDateWithoutHours(j.startDate);
                    //j.endDate =  this.dateService.getDateWithoutHours(j.endDate);
                    if (j.medication) {
                        return {
                            name: j.medication,
                            reason: j.reason,
                            sideEffects: j.sideEffects,
                        };
                    }
                });
                FormValueBuilder.setControlsToched(this.formGroup);
                if (!this.formGroup.valid) {
                    return [2 /*return*/];
                }
                data = { showMedications: !!this.formGroup.value.showMedications };
                if (this.formGroup.value.showMedications) {
                    data.medications = newFormGroupMedication;
                }
                this.saveForm.emit(data);
                return [2 /*return*/];
            });
        });
    };
    MedicalTreatingSourcesMedicationsComponent.prototype.getButtonType = function () {
        return this.canRemove() ? 'warning' : 'disabled';
    };
    MedicalTreatingSourcesMedicationsComponent.prototype.setPrescribedValue = function () {
        var _this = this;
        this.arrayModel.groups.forEach(function (item) {
            item.group[5].valueUpdates.next(_this.prescribedBy);
            FormValueBuilder.resetToDefault(item.group[5]);
        });
    };
    MedicalTreatingSourcesMedicationsComponent.prototype.setNewVisit = function () {
        var _this = this;
        if (this.mode === FormMode.EditMode && this.medications && this.medications.length > 0) {
            var newFormGroupMedication = this.medications.map(function (j) {
                return {
                    genericForm: j.genericForm,
                    id: j.id,
                    name: j.name,
                    reason: j.reason,
                    regular: j.regular,
                    sideEffects: j.sideEffects,
                    startDate: j.period.startDate,
                    endDate: j.period.endDate
                };
            });
            if (newFormGroupMedication.length !== this.form.medications.groups.length)
                newFormGroupMedication.forEach(function (j) { return _this.insert(); });
            newFormGroupMedication.filter(function (j) { return !j.period; }).forEach(function (j) { return j.period = { startDate: null, endDate: null }; });
            this.formGroup.controls.medications.patchValue(newFormGroupMedication);
            newFormGroupMedication.forEach(function (item, indx) {
                if (item.regular) {
                    setTimeout(function () {
                        _this.arrayModel.groups[indx].group[8].hidden = true;
                        _this.arrayModel.groups[indx].group[8].required = true;
                        _this.arrayModel.groups[indx].group[8].valueUpdates.next(null);
                        _this.arrayModel.groups[indx].group[8].hidden = true;
                        _this.formGroup.controls['medications'].controls[indx].controls['endDate'].setValidators(null);
                    });
                }
            });
        }
    };
    MedicalTreatingSourcesMedicationsComponent.prototype.ngOnDestroy = function () {
        this.formStateChanged.next({ value: false, key: this.formKey });
    };
    MedicalTreatingSourcesMedicationsComponent.prototype.isClaimant = function () {
        if (this.userInfoService.getCurrentUserRole() == 'CLIENT') {
            return true;
        }
        return false;
    };
    return MedicalTreatingSourcesMedicationsComponent;
}(DynamicForm));
export { MedicalTreatingSourcesMedicationsComponent };
