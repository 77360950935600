import { EventEmitter } from '@angular/core';
import { HttpClient, HttpParams, HttpRequest } from '@angular/common/http';
import { CustomIrisNotificationService } from "../custom-iris-notification/custom-iris-notification.service";
import { Subject } from "rxjs";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../custom-iris-notification/custom-iris-notification.service";
var FileUploadingService = /** @class */ (function () {
    function FileUploadingService(http, _notifications) {
        this.http = http;
        this._notifications = _notifications;
        this.uploadEnded = new EventEmitter();
    }
    FileUploadingService.prototype.uploadFile = function (url, data) {
        var _this = this;
        var formData = new FormData();
        if (data.file === undefined || data.file === null)
            return;
        formData.append('file', data.file);
        if (data.fileInfo) {
            Object.keys(data.fileInfo).forEach(function (key) {
                if (data.fileInfo[key])
                    formData.append(key, data.fileInfo[key]);
            });
        }
        var req = new HttpRequest('POST', url, formData, {
            reportProgress: true,
            responseType: 'text'
        });
        var rez = new Subject();
        this.http.request(req).subscribe(function (j) {
            if (j.type == 0)
                _this.show(j.type, data.file.name);
            else if (j.type === 4) {
                _this.uploadEnded.emit();
                rez.next();
            }
        });
        return rez;
    };
    FileUploadingService.prototype.getTitles = function (url) {
        return this.http.get(url);
    };
    FileUploadingService.prototype.show = function (type, fileName) {
        var title = '';
        var actionType = 'info';
        switch (type) {
            case 0:
                title = 'Uploading Started!';
                break;
            case 1:
                title = 'Uploading to Server...';
                break;
            case 2:
            case 3:
                title = 'Sending to Storage!';
                break;
            case 4:
                title = 'Upload Successfully!';
                actionType = 'success';
                this.uploadEnded.emit();
                break;
        }
        this._notifications.show(({
            title: title,
            message: '"' + fileName + '"',
            type: 'info',
            imagePosition: 'right',
            progressBar: 'circle',
            autoHide: true,
            timeout: 3000,
            position: 'rb' //lb or rb
        }));
    };
    FileUploadingService.prototype.getDocuments = function (url, title) {
        ////console.log(title);
        var params = new HttpParams();
        if (title && title.trim().length > 0 && title !== 'null') {
            params = params.append('title', title);
        }
        return this.http.get(url, { params: params });
    };
    FileUploadingService.prototype.isExist = function (url) {
        return this.http.get(url);
    };
    FileUploadingService.prototype.downloadDocument = function (url) {
        // this.show(-1, 'Downloading Started');
        var req = new HttpRequest('GET', url, null, { responseType: 'arraybuffer' });
        return this.http.request(req); // return this.http.get('/api/claimant/documents/' + id + '/download');
    };
    FileUploadingService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FileUploadingService_Factory() { return new FileUploadingService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.CustomIrisNotificationService)); }, token: FileUploadingService, providedIn: "root" });
    return FileUploadingService;
}());
export { FileUploadingService };
