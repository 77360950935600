import { HttpClient } from '@angular/common/http';
import * as conf from '../@config/api';
import { Social } from '../@models/system-managment/social';
import { Functions } from '../../@util/functions';
import { FileUploadingService } from "../../@util/file-uploading-progress/file-uploading.service";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../@util/file-uploading-progress/file-uploading.service";
var AdvisorsService = /** @class */ (function () {
    function AdvisorsService(http, fileUploadingService) {
        this.http = http;
        this.fileUploadingService = fileUploadingService;
        this.config = conf.MANAGER_USER_CONFIG;
    }
    AdvisorsService.prototype.addUser = function (data) {
        return this.http.post(this.config.add, data);
    };
    AdvisorsService.prototype.getUsersList = function () {
        return this.http.get(this.config.getAll('ADVISOR'));
    };
    AdvisorsService.prototype.getCurrentUser = function (userId) {
        return this.http.get(this.config.getById(userId));
    };
    AdvisorsService.prototype.updatePasswordAndActive = function (value) {
        return this.http.post(this.config.updateLoginInfo(value.username), value);
    };
    AdvisorsService.prototype.setContact = function (userName, value) {
        value.press = Functions.checkPhone(value.press);
        value.fax = Functions.checkPhone(value.fax);
        value.phone = Functions.checkPhone(value.phone);
        return this.http.post(this.config.setContact(userName), value);
    };
    AdvisorsService.prototype.setPersonal = function (userName, value) {
        value.phone = Functions.checkPhone(value.phone);
        return this.http.post(this.config.setPersonal(userName), value);
    };
    AdvisorsService.prototype.setSocial = function (userName, value) {
        Social.checkLinck(value);
        return this.http.post(this.config.setSocial(userName), value);
    };
    AdvisorsService.prototype.setCareer = function (username, $event) {
        return this.http.post(this.config.setCareer(username), $event);
    };
    AdvisorsService.prototype.uploadAvatar = function (username, avatar) {
        return this.fileUploadingService.uploadFile('/api/user/avatar/' + username, { file: avatar, fileInfo: null });
    };
    AdvisorsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AdvisorsService_Factory() { return new AdvisorsService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.FileUploadingService)); }, token: AdvisorsService, providedIn: "root" });
    return AdvisorsService;
}());
export { AdvisorsService };
