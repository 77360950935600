import { HttpClient } from "@angular/common/http";
import { FIELD_OFFICES_CONFIG } from "../@config/api";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var FieldOfficeService = /** @class */ (function () {
    function FieldOfficeService(http) {
        this.http = http;
    }
    FieldOfficeService.prototype.getOffices = function () {
        return this.http.get(FIELD_OFFICES_CONFIG.getAll);
    };
    FieldOfficeService.prototype.getOffice = function (officeId) {
        return this.http.get(FIELD_OFFICES_CONFIG.get(officeId));
    };
    FieldOfficeService.prototype.saveOffice = function (office) {
        return this.http.post(FIELD_OFFICES_CONFIG.saveOffice, office);
    };
    FieldOfficeService.prototype.getFieldData = function (claimantId) {
        return this.http.post(FIELD_OFFICES_CONFIG.getFieldData(claimantId), { claimantId: claimantId });
    };
    FieldOfficeService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FieldOfficeService_Factory() { return new FieldOfficeService(i0.ɵɵinject(i1.HttpClient)); }, token: FieldOfficeService, providedIn: "root" });
    return FieldOfficeService;
}());
export { FieldOfficeService };
