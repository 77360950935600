import * as tslib_1 from "tslib";
import { EventEmitter, OnDestroy, OnInit } from "@angular/core";
import { State } from "../../app/test-new-stepper-form/test-form/on-changes";
var DynamicForm = /** @class */ (function () {
    function DynamicForm() {
        this.formStateChanged = new EventEmitter();
        this.allFormStateChanged = new EventEmitter();
    }
    return DynamicForm;
}());
export { DynamicForm };
var DynamicFormParent = /** @class */ (function (_super) {
    tslib_1.__extends(DynamicFormParent, _super);
    function DynamicFormParent(matDialog) {
        var _this = _super.call(this) || this;
        _this.matDialog = matDialog;
        _this.formsState = new Map();
        return _this;
    }
    DynamicFormParent.prototype.onFormStateChanged = function (state) {
        this.formsState.set(state.key, state.value);
    };
    DynamicFormParent.prototype.onAllFormStateChanged = function (state) {
        this.allFormState = state;
    };
    Object.defineProperty(DynamicFormParent.prototype, "state", {
        get: function () {
            var changed = State.before;
            this.formsState.forEach(function (value) {
                if (value) {
                    changed = State.after;
                }
            });
            return changed;
        },
        enumerable: true,
        configurable: true
    });
    DynamicFormParent.prototype.onStateChanges = function () {
        throw Error("Not Implemented");
    };
    DynamicFormParent.prototype.canCloseForm = function (formName) {
        return !this.formsState.has(formName) || !this.formsState.get(formName);
    };
    DynamicFormParent.prototype.showDataChangedDialog = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                // const dialogRef = this.matDialog.open(FormChangesAttentionComponent, {
                // 	data: {
                // 		closeBtnText: 'Ignore changes',
                // 		infoText: 'You have unsaved data. Please confirm before proceeding.'
                // 	},
                // 	panelClass: 'attention-form-changes',
                // 	disableClose: true
                // });
                // return (await dialogRef.afterClosed().toPromise()) == 'leave';
                return [2 /*return*/, true];
            });
        });
    };
    return DynamicFormParent;
}(DynamicForm));
export { DynamicFormParent };
