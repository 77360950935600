import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { DynamicFormService, } from '@ng-dynamic-forms/core';
import { DATE_PICKER } from '../../../../../../../@util/dynamic-form/dynamic-controlls';
import { ReferralService } from '../../../../../../@core/referral.service';
import { FormValueBuilder } from '../../../../../partial/forms/form-tamplate';
import { MatDialogRef } from '@angular/material/dialog';
import { SystemUsersService } from '../../../../../../@core/system-users.service';
import { FollowUp } from '../../../../../../@models/follow-up/follow-up';
import { map } from "rxjs/operators";
import { DynamicSelectModel } from "../../../../../../../@util/ui-material/form-controls";
import { UserInfoService } from "../../../../../../@core/user-info.service";
import { GlobalSaveService } from "../../../../../../@core/global-save.service";
var CompleteFollowUpComponent = /** @class */ (function () {
    function CompleteFollowUpComponent(referralService, formService, dialogRef, data, userInfoService, globalSaveService, systemUsers) {
        this.referralService = referralService;
        this.formService = formService;
        this.dialogRef = dialogRef;
        this.data = data;
        this.userInfoService = userInfoService;
        this.globalSaveService = globalSaveService;
        this.systemUsers = systemUsers;
        this.closeForm = new EventEmitter();
        this.title = 'Edit';
        this.form = {
            completedBy: DynamicSelectModel({
                id: 'completedBy',
                label: 'Completed By',
                value: null,
                required: true,
                errorMessages: {
                    required: 'Required'
                },
            }),
            completeDate: DATE_PICKER({ id: 'completeDate', label: 'Complete Date', required: true }),
        };
        this.form.completeDate.disabled = true;
        this.form.completedBy.disabled = true;
        this.form.completeDate.hiddenClearIco = true;
        this.formModel = FormValueBuilder.buildFormModel(this.form);
        this.formGroup = this.formService.createFormGroup(this.formModel);
    }
    CompleteFollowUpComponent.prototype.ngOnInit = function () {
        var _this = this;
        setTimeout(function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.fillUsers()];
                    case 1:
                        _a.sent();
                        this.form.completeDate.valueUpdates.next(new Date(Date.now()));
                        return [2 /*return*/];
                }
            });
        }); }, 0);
    };
    CompleteFollowUpComponent.prototype.closeFormFollowUp = function () {
        this.dialogRef.close();
    };
    CompleteFollowUpComponent.prototype.fillUsers = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var users;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.systemUsers.getUsersList('EXECUTIVE_STAFF')
                            .pipe(map(function (data) { return _this.systemUsers.mapUserStaff(data); })).toPromise()];
                    case 1:
                        users = _a.sent();
                        this.form.completedBy.options = users;
                        //this.form.completedBy.valueUpdates.next(this.data.assignedTo);
                        try {
                            this.form.completedBy.valueUpdates.next(this.userInfoService.getCurrentUserName());
                        }
                        catch (e) {
                            this.form.completedBy.valueUpdates.next(this.data.assignedTo);
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    CompleteFollowUpComponent.prototype.saveFollowUp = function () {
        FormValueBuilder.setControlsToched(this.formGroup);
        //if (this.formGroup.valid) {
        this.dialogRef.close(this.formGroup.getRawValue());
        //}
    };
    return CompleteFollowUpComponent;
}());
export { CompleteFollowUpComponent };
